




























import { isMobile } from "@/lib/utility/isMobile";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class MDetailViewGrid extends Vue {
  @Prop({ default: true })
  bottomSheet!: boolean;

  isBottomSheetOpen = false;

  swipeDirection = "";
  swipe(swipe: string) {
    this.swipeDirection = swipe;
  }

  get isMobile() {
    return isMobile() || this.$vuetify.breakpoint.smAndDown;
  }

  get showDetails() {
    if (!this.bottomSheet) {
      return true;
    }

    return !this.isMobile;
  }

  get closeOnSwipe() {
    return {
      down: () => this.closeBottomSheet()
    };
  }

  closeBottomSheet() {
    this.isBottomSheetOpen = false;
  }
}
