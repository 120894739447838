/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { OperationStatusEnum } from "@/lib/enum/OperationStatus.enum";
import { CancelToken } from "@/lib/utility/cancelToken";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { $t } from "@/lib/utility/t";
import { IOperation, Operation } from "@/models/operation.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";
import operationService from "@/services/mrfiktiv/services/operationService";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { TYPE } from "vue-toastification";
import { ToastID } from "vue-toastification/dist/types/src/types";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import {
  MrfiktivOperationControllerFindAllParamsGen,
  MrfiktivPageViewModelGen
} from "./../../services/mrfiktiv/v1/data-contracts";
import { OperationDataAccessLayer } from "./access-layers/operation.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

export class OperationPageDataProvider extends AbstractPageDataProvider<
  IOperation,
  MrfiktivOperationControllerFindAllParamsGen
> {
  constructor() {
    super();
  }

  async getPage(
    query: MrfiktivOperationControllerFindAllParamsGen & { partnerId?: string }
  ): Promise<IPageViewModel<IOperation>> {
    let response;
    if (query.partnerId) {
      response = (await operationService.getOperationsByPartner({
        ...query,
        partnerId: query.partnerId
      })) as MrfiktivPageViewModelGen & {
        data: IOperation[] | undefined;
      };
    } else {
      response = ((await operationService.getOperations({ ...query })) as MrfiktivPageViewModelGen & {
        data: IOperation[] | undefined;
      }) as MrfiktivPageViewModelGen & {
        data: IOperation[] | undefined;
      };
    }

    if (!response.data) {
      response.data = [];
    }
    response.data = response.data.map(operation => new Operation(operation));

    return response;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "operation",
  store
})
export class OperationStore extends PaginatedBaseStore<IOperation, MrfiktivOperationControllerFindAllParamsGen> {
  _data = OperationDataAccessLayer;
  _pageProvider = new OperationPageDataProvider();
  _pager: PageDataHandler<IOperation, MrfiktivOperationControllerFindAllParamsGen> = new PageDataHandler(
    this._data,
    this._pageProvider
  );

  filterOptions: PaginationFilterListElement[] = Operation.filterables;
  hiddenFilter: IPageFilterElement[] = [];

  private _operation: IOperation | undefined = undefined;

  get operation(): IOperation | undefined {
    return this._operation;
  }

  @Mutation
  mutateOperation(val: IOperation) {
    this._operation = val;
  }

  @Action
  async getOperationByPartner(data: { operationId: string; partnerId: string }): Promise<void> {
    const operation = await operationService.getOperationByPartner(data.partnerId, data.operationId);

    this.context.commit("mutateOperation", operation);
  }

  @Action
  async getOperation(operationId: string): Promise<IOperation> {
    const operation = await operationService.getOperation(operationId).then(r => new Operation(r));

    this.context.commit("mutateOperation", operation);

    return operation;
  }

  @Action
  async dispatchToast(data: { operationId: string; partnerId?: string; onClick?: () => void }) {
    const { operationId, partnerId, onClick } = data;
    const onClose = () => cancelToken.requestCancellation();
    const cancelToken = new CancelToken();
    const toastId = Vue.$toast($t("components.operation.workinOnIt"), {
      type: TYPE.DEFAULT,
      timeout: false,
      showCloseButtonOnHover: true,
      onClose,
      onClick: (closeToast: Function) => {
        if (onClick) onClick();
        closeToast();
      }
    });

    setTimeout(
      () =>
        this.updateOperationToast({
          toastId,
          cancelToken,
          operation: new Operation({ partnerId: partnerId, id: operationId })
        }),
      5000
    );
  }

  @Action
  async updateOperationToast({
    toastId,
    operation,
    cancelToken
  }: {
    toastId: ToastID;
    cancelToken: CancelToken;
    operation: IOperation;
  }) {
    if (cancelToken.isCancellationRequested()) {
      return;
    }

    let updatingText = $t("components.operation.workinOnIt");
    if (operation?.type !== "default") {
      updatingText = `[${$t(`enums.CronJobEnum.${operation.type}`)}] ${$t(
        `enums.OperationStatusEnum.${operation.status}`
      )}`;
    }

    Vue.$toast.update(toastId, { content: updatingText + "..." });

    await operation.fetch();

    let progressText = $t("components.operation.workinOnIt");
    if (operation?.type !== "default") {
      progressText = `[${$t(`enums.CronJobEnum.${operation.type}`)}] ${$t(
        `enums.OperationStatusEnum.${operation.status}`
      )}`;
    }

    if (
      !([
        OperationStatusEnum.CANCELLED,
        OperationStatusEnum.FAILED,
        OperationStatusEnum.SUCCEEDED
      ] as string[]).includes(operation.status)
    ) {
      Vue.$toast.update(toastId, { content: progressText });
      setTimeout(
        () =>
          this.updateOperationToast({
            toastId,
            operation,
            cancelToken
          }),
        5000
      );
    } else {
      Vue.$toast.update(toastId, {
        content: progressText,
        options: {
          timeout: 5000
        }
      });
    }
  }
}

export const OperationModule = getModule(OperationStore);
