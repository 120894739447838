











































import { AssetEnum } from "@/lib/AssetRepository";
import { handleError } from "@/lib/utility/handleError";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { PartnerEntity } from "@/models/partnerEntity";
import { MrfiktivCreateActivityLogDtoGen, MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerImageModule } from "@/store/modules/partner-image.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import FileUploadPreview from "../utility/FileUploadPreview.vue";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    FileUploadPreview
  }
})
export default class ReportActivityBoxImageComponent extends Vue {
  @Prop()
  source!: MrfiktivReferenceGen | ThgReferenceGen;

  @Prop()
  partner!: PartnerEntity;

  /**
   * The image type that should be used for uploaded images
   */
  @Prop({ default: ReportImageType.others })
  imageType!: ReportImageType;

  /**
   * Local image store
   */
  displayImages: IImageUploaded[] = [];

  /**
   * Loading state of the activity
   */
  isLoading = false;

  get AssetEnum() {
    return AssetEnum;
  }

  /**
   * Uploads a file to the report endpoint
   * @param file the file to be uploaded, provided by the file upload preview component
   */
  async handleUpload(file: File) {
    const image = {
      file,
      type: this.imageType
    };

    const imageId = await PartnerImageModule.create({ partnerId: this.partner.id, ...image });
    this.displayImages.push({ isLoading: false, isUploaded: false, uploadId: imageId.id, ...image });
  }

  /**
   * Splices an image from the local image store
   * @param file the file to be removed
   */
  handleDelete(file: File) {
    const index = this.displayImages.findIndex(f => f.file.name === file.name);
    this.displayImages.splice(index, 1);
  }

  /**
   * Clears all local images
   */
  clear() {
    this.displayImages = [];
  }

  /**
   * Adds an activity with the images as target refs
   */
  async submit() {
    this.isLoading = true;
    try {
      const refType = this.source.refType;
      const refId = this.source.refId;
      const partnerId = this.partner.id;

      const target = this.displayImages.map(image => ({
        refId: image.uploadId,
        refType: "image" as any
      }));

      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType,
          refId
        },
        target: target,
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_IMAGE
      };

      const activity = await new ActivityLog({
        partnerId,
        ...data
      }).create();

      this.clear();
      this.$toast.success("👍");
      this.$emit("save", activity);
    } catch (error) {
      handleError(error);
    }
    this.isLoading = false;
  }
}
