








































































































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate, simpleDate } from "@/lib/utility/date-helper";
import { SharingLinkFactory } from "@/lib/utility/sharingLinkFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import SignInitializeDialog from "@/views/sign/SignInitializeDialog.vue";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { ISharedContent, SharedContent } from "@/models/shared-content.entity";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";
import { SharedContentModule } from "@/store/modules/shared-content.store";
import { AttachmentRequestModule } from "@/store/modules/attachment-request.store";
import SharedContentSideCard from "./SharedContentSideCard.vue";
import AttachmentRequestSideCard from "./AttachmentRequestSideCard.vue";
import { $t } from "@/lib/utility/t";
import { mixins } from "vue-class-component";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    TimelineCard,
    PartnerDetailAttributeWithToolTip,
    SignInitializeDialog,
    ActivityTimeLineItemDelete,
    ContextMenu,
    SharedContentSideCard,
    AttachmentRequestSideCard
  }
})
export default class ActivityTimeLineItemShareLink extends mixins(DarkModeHighlightMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  @Prop({ default: false })
  isEditable!: boolean;

  /**
   * property to overwrite time line event with custom title
   */
  @Prop()
  title?: string;

  loading = false;
  loadingDeactivate = false;
  error = false;
  showMenu = false;
  showSideCard = false;

  sharedContent: ISharedContent | IAttachmentRequest | null = null;

  get errorState() {
    return this.error;
  }

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get headers() {
    return [{ value: "key" }, { value: "value" }];
  }

  get shareTitle() {
    return !this.isActive ? $t("timeLine.ActivityTimeLineItemShare.linkDeactivated") : this.validBy;
  }

  async mounted() {
    try {
      this.loading = true;
      if (this.activityLogEntry.target) {
        for (const sharedContent of this.activityLogEntry.target) {
          let found: ISharedContent | IAttachmentRequest | null = null;
          if (!this.isEditable) {
            found = SharedContentModule._data.get(sharedContent.refId) ?? null;
            if (!found) {
              found = new SharedContent({
                partnerId: this.activityLogEntry.partnerId,
                id: sharedContent.refId
              });
            }
          } else {
            found = AttachmentRequestModule._data.get(sharedContent.refId) ?? null;
            if (!found) {
              found = new AttachmentRequest({
                partnerId: this.activityLogEntry.partnerId,
                id: sharedContent.refId
              });
            }
          }

          this.sharedContent = await found.fetch(true);
        }
      }
    } catch (error) {
      this.error = true;
      this.$log.error(error);
      this.error = true;
    }

    this.loading = false;
  }

  toDetail(partnerId: string, id: string) {
    this.$router.push({ name: "SignRequestDetailView", params: { partnerId: partnerId, signRequestId: id } });
  }

  async deactivate() {
    this.loadingDeactivate = true;
    try {
      if (this.sharedContent instanceof SharedContent) {
        await (this.sharedContent as ISharedContent).deactivate();
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error deactivating Link.");
    }
    this.loadingDeactivate = false;
  }

  get validBy(): string {
    if (this.sharedContent?.isLoading) {
      return $t("...");
    }

    if (!this.sharedContent || this.sharedContent.isNotFound) {
      return $t("sign.Sign.error.404");
    }

    if (!this.isActive) {
      return $t("views.PartnerSharedReport.expired");
    }

    if (this.sharedContent?.validBy) {
      return $t("timeLine.ActivityTimeLineItemShare.validBy", { date: simpleDate(this.sharedContent.validBy) });
    }

    return $t("timeLine.ActivityTimeLineItemShare.validIndefinitely");
  }

  get isActive() {
    if (!this.sharedContent) return false;

    return !this.sharedContent.isExpired;
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  copyLinkToClipboard() {
    if (!this.sharedContent) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.error"));
      return;
    }
    const sharingLinkFactory = new SharingLinkFactory(this.sharedContent, this.isEditable);
    try {
      sharingLinkFactory.copyLinkToClipboard();
      this.$toast.info(this.$t("components.partner.PartnerReportInitializeCard.linkCopied"));
    } catch (error) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.error"));
    }
  }
}
