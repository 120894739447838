




























































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { IReference } from "@/models/reference.entity";
import { Component, Prop } from "vue-property-decorator";
import TemplateCard from "../template/TemplateCard.vue";
import { IReport } from "@/models/report.entity";
import { IThg } from "@/models/thg.entity";
import { MrfiktivUserViewmodelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    TemplateCard
  }
})
export default class ReportActivityBoxMailComponent extends DarkModeHighlightMixin {
  @Prop()
  partner!: PartnerEntity;

  @Prop()
  source?: IReference;

  @Prop()
  report?: IReport;

  @Prop()
  thg?: IThg;

  @Prop()
  user?: MrfiktivUserViewmodelGen;

  @Prop()
  mail?: string;

  @Prop()
  isEditorMobile?: boolean;

  get refs(): IReference[] {
    const refs: IReference[] = [];

    if (this.report?.id) {
      refs.push({ refId: this.report.id, refType: ResourceEnum.REPORT });
    }
    if (this.thg?.id) {
      refs.push({ refId: this.thg.id, refType: ResourceEnum.THG });
    }
    if (this.source?.refId && !refs.find(r => r.refId === this.source?.refId)) {
      refs.push({ refId: this.source.refId, refType: this.source.refType });
    }

    return refs;
  }

  get mailRefs() {
    const refs = this.refs;

    if (this.source && !this.refs.find(r => r.refId === this.source?.refId)) {
      refs.push(this.source);
    }

    return refs;
  }

  isValid = false;

  get from() {
    return this.partner;
  }

  get to() {
    if (this.mail) {
      return [this.mail];
    }

    if (this.report?.customerContact?.email) {
      return [this.report.customerContact.email];
    }

    if (this.user?.contact?.email) {
      return [this.user.contact.email];
    }

    return [];
  }

  get context(): ITemplateContext {
    return { report: this.report, thg: this.thg, partner: this.partner, user: this.user };
  }

  isSent = false;
  isLoading = false;
  async send() {
    this.isLoading = true;
    const mails = await (this.$refs.templateInput as TemplateCard)?.send().catch(handleError);

    if (mails) {
      this.isSent = true;
    }
    this.isLoading = false;
  }
}
