





































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog, { ReportUpdateTabEnum } from "./PartnerReportUpdateReportDialog.vue";

@Component({
  components: {
    TimelineCard,
    Card,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerInsuranceDetailsTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  readonly ReportUpdateTabEnum = ReportUpdateTabEnum;

  @Prop()
  report!: MrfiktivReportViewModelGen;

  get insuranceInfo() {
    return this.report.insurances;
  }
}
