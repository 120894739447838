






























































import SendToDaDialog from "@/components/partner/PartnerReportDetailSendToDaDialog.vue";
import SendToKsrDialog from "@/components/partner/PartnerReportDetailSendToKsrDialog.vue";
import SendToPdrDialog from "@/components/partner/PartnerReportDetailSendToPdrDialog.vue";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { ExportModule } from "@/store/modules/export.store";
import { FeatureModule } from "@/store/modules/feature.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { SendToKsrDialog, SendToDaDialog, SendToPdrDialog } })
export default class SendToExternOption extends Vue {
  @Prop({ default: false })
  hideButton?: boolean;

  @Prop()
  thirdPartySystems?: IThirdPartySystem[];

  selected = "";
  isDialogActive = false;

  /*
   * States in dropdown list
   */
  get exportOptions() {
    const exportOptions = [];

    if (ExportModule.isKsrSetup) {
      exportOptions.push("KSR");
    }

    if (ExportModule.isDaSetup) {
      exportOptions.push("DA");
    }

    if (ExportModule.isPdrSetup) {
      exportOptions.push("PDR");
    }

    return exportOptions;
  }

  get thirdPartySystemDa() {
    if (!FeatureModule.isTpsActive) return undefined;

    const tpss = this.thirdPartySystems;

    return tpss?.find(tps => tps.system === TpsEnum.DA);
  }

  get thirdPartySystemKsr() {
    if (!FeatureModule.isTpsActive) return undefined;

    const tpss = this.thirdPartySystems;

    return tpss?.find(tps => tps.system === TpsEnum.KSR);
  }

  reset() {
    this.selected = "";
  }

  /**
   * Open the dialog to send to ksr or da
   */
  openSendDialog(dialog: string) {
    this.selected = dialog;

    if (dialog === "KSR") {
      (this.$refs.ksrDialog as SendToKsrDialog).open();
    }

    if (dialog === "DA") {
      (this.$refs.daDialog as SendToDaDialog).open();
    }

    if (dialog === "PDR") {
      (this.$refs.pdrDialog as SendToPdrDialog).open();
    }
  }

  closeDialog() {
    this.isDialogActive = false;
    this.selected = "";
  }

  /**
   * Check if the setup is loaded
   */
  async isSetupCheckLoaded() {
    if (this.selected === "KSR") {
      if (!ExportModule.isKsrSetupCheckLoaded) {
        await ExportModule.ksrSetupCheckAsync;
      }

      return ExportModule.isKsrSetupCheckLoaded;
    }
    if (this.selected === "DA") {
      if (!ExportModule.isDaSetupCheckLoaded) {
        await ExportModule.daSetupCheckAsync;
      }

      return ExportModule.isDaSetupCheckLoaded;
    }
    if (this.selected === "PDR") {
      if (!ExportModule.isPdrSetupCheckLoaded) {
        await ExportModule.pdrSetupCheckAsync;
      }

      return ExportModule.isPdrSetupCheckLoaded;
    }
  }

  /**
   * Is setup
   */
  isSetup() {
    let isSetup: boolean | undefined = false;
    if (this.selected === "KSR") {
      isSetup = ExportModule.isKsrSetup;
    }

    if (this.selected === "DA") {
      isSetup = ExportModule.isDaSetup;
    }

    if (this.selected === "PDR") {
      isSetup = ExportModule.isPdrSetup;
    }

    if (isSetup) {
      this.isDialogActive = false;
    }

    return Boolean(isSetup);
  }

  get isKsrSetup() {
    return ExportModule.isKsrSetup;
  }
  get isDaSetup() {
    return ExportModule.isDaSetup;
  }
  get isPdrSetup() {
    return ExportModule.isPdrSetup;
  }

  /**
   * opens the setup wizzard
   */
  goToSetup() {
    this.$router.push({ name: `PartnerManageStepper${this.selected}View` });
  }

  /**
   * checks if ksr is selected
   */
  get isKsrSelected() {
    return this.selected === "KSR";
  }

  /**
   * checks if da is selected
   */
  get isDaSelected() {
    return this.selected === "DA";
  }

  /**
   * checks if da is selected
   */
  get isPdrSelected() {
    return this.selected === "PDR";
  }
}
