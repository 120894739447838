import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import daService from "@/services/mrfiktiv/services/daService";
import {
  MrfiktivDaWebhookControllerGetByLicensePlateParamsGen,
  MrfiktivDaWebhookControllerGetParamsGen,
  MrfiktivDaWebhookViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";

export const DaWebhookPageDataProvider = new (class extends AbstractPageDataProvider<
  MrfiktivDaWebhookViewModelGen,
  MrfiktivDaWebhookControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(
    query: MrfiktivDaWebhookControllerGetParamsGen
  ): Promise<IPageViewModel<MrfiktivDaWebhookViewModelGen>> {
    const res = await daService.getDaWebhooks(query);

    const meta = res.meta;
    const data = res.data;

    return { meta, data };
  }
})();

export const DaWebhookDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<MrfiktivDaWebhookViewModelGen> {
  getIdentifier(entity: MrfiktivDaWebhookViewModelGen): string {
    return entity.id;
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "da-webhook",
  store
})
class DaWebhookStore extends PaginatedBaseStore<
  MrfiktivDaWebhookViewModelGen,
  MrfiktivDaWebhookControllerGetParamsGen
> {
  protected _data = DaWebhookDataAccessLayer;
  protected _pageProvider = DaWebhookPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  _dossier: MrfiktivDaWebhookViewModelGen | null = null;

  get dossier() {
    return this._dossier;
  }

  @Action
  async findOne(data: { partnerId: string; id: number }) {
    const res = await daService.getDaWebhook(data.partnerId, data.id);

    this.context.commit("setDossier", res);

    return res;
  }

  @Action
  async findByNumberplate(data: MrfiktivDaWebhookControllerGetByLicensePlateParamsGen) {
    const res = await daService.getDaWebhookByLicensePlate(data);

    this.context.commit("setDossier", res);

    return res;
  }

  @Mutation
  setDossier(dossier: MrfiktivDaWebhookViewModelGen) {
    this._dossier = dossier;
  }
}

export const DaWebhookModule = getModule(DaWebhookStore);
