


















































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheLayoutPortal from "../../layouts/TheLayoutPortal.vue";
import { InboxModule } from "@/store/modules/inbox-message.store";
import MHeader, { IBreadcrumb, IChips } from "../utility/mmmint/MHeader.vue";
import { $t } from "@/lib/utility/t";
import { AllMessagesModule } from "@/store/modules/all-messages.store";

@Component({
  components: {
    MHeader,
    TheLayoutPortal
  }
})
export default class PartnerMessageInboxList extends mixins(
  DarkModeHighlightMixin,
  PartnerFallbackMixin,
  PermissionMixin
) {
  readonly AllMessagesInbox = AllMessagesModule;

  isLoading = false;

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];

    breadCrumbs.push({
      text: $t("common.nouns.inboxes"),
      to: { name: "PartnerMessageInboxList", params: { partnerId: this.partner.id } },
      exact: true
    });

    return breadCrumbs;
  }

  get chips(): IChips[] {
    const chips: IChips[] = [];

    chips.push({
      text: this.inboxes.length + " Inboxes",
      color: "primary",
      icon: "mdi-inbox",
      key: "inboxcount"
    });

    return chips;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get loading() {
    return InboxModule.loading;
  }

  get InboxModule() {
    return InboxModule;
  }

  get inboxes() {
    const inboxes = InboxModule.inboxes;

    return inboxes;
  }

  goToInboxList(inboxIdentifier: string) {
    this.$router.push({
      name: "PartnerMessageInbox",
      params: {
        partnerId: this.partner._id,
        inboxId: inboxIdentifier
      }
    });
  }

  beforeMount() {
    for (const inbox of this.inboxes) {
      inbox.module.setFilters([]);
    }

    AllMessagesModule.setFilters([]);
  }

  async mounted() {
    this.isLoading = true;
    await this.trySetByRouteOrDefault();
    this.isLoading = false;
  }
}
