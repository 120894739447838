





























import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import AttachmentRequestSideCard from "./AttachmentRequestSideCard.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { handleError } from "@/lib/utility/handleError";
import { AttachmentRequestModule } from "@/store/modules/attachment-request.store";
import { AttachmentRequest, IAttachmentRequest } from "@/models/attachment-request.entity";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    AttachmentRequestSideCard
  }
})
export default class ActivityTimeLineItemAttachmentResponse extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: IActivityLog;

  attachmentRequestLoading = false;

  attachmentRequest: IAttachmentRequest | null = null;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  get attachmentRequestId() {
    return this.activityLogEntry.target?.find(t => t.refType === BackendResourceEnum.ATTACHMENT_REQUEST)?.refId;
  }

  async openAttachmentRequestSideCard() {
    const attachmentRequestId = this.attachmentRequestId;
    if (!attachmentRequestId) return;

    this.attachmentRequestLoading = true;

    try {
      let found = AttachmentRequestModule.maps.id.get(attachmentRequestId)[0];
      if (!found) {
        found = new AttachmentRequest({
          partnerId: this.activityLogEntry.partnerId,
          id: attachmentRequestId
        });
      }
      await found.fetch();
      this.attachmentRequest = found;
    } catch (e) {
      handleError(e);
    } finally {
      this.attachmentRequestLoading = false;
    }
  }
}
