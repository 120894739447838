






















































































































































































import CostCardDocuments from "@/components/cost/CostCardDocuments.vue";
import PartnerReportTimeLineCardScrollMixin from "@/components/partner/PartnerReportTimeLineCardScrollMixin.vue";
import FileInputMixin from "@/components/utility/FileInputMixin.vue";
import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { MrfiktivDAServiceEventDetailViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import ReportDocumentSignRequestStepperChoice from "./ReportDocumentSignRequestStepperChoice.vue";
import ReportDocumentSignRequestStepperDa from "./ReportDocumentSignRequestStepperDa.vue";
import ReportDocumentSignRequestStepperDaExport from "./ReportDocumentSignRequestStepperDaExport.vue";
import ReportDocumentSignRequestStepperFileSelection from "./ReportDocumentSignRequestStepperFileSelection.vue";
import ReportDocumentSignRequestStepperForm from "./ReportDocumentSignRequestStepperForm.vue";
import ReportDocumentSignRequestStepperInputs from "./ReportDocumentSignRequestStepperInputs.vue";
import ReportDocumentSignRequestStepperMailSuccess from "./ReportDocumentSignRequestStepperMailSuccess.vue";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import ReportDocumentSignRequestStepperSendMail from "./ReportDocumentSignRequestStepperSendMail.vue";
import ReportDocumentSignRequestStepperSuccess from "./ReportDocumentSignRequestStepperSuccess.vue";
import ReportDocumentSignRequestStepperUpload from "./ReportDocumentSignRequestStepperUpload.vue";

export enum SignRequestStepperPageEnum {
  CHOICE = "choice",
  UPLOAD = "upload",
  SIGNATURE_SELECTION = "signatureSelection",
  FILE_SELECTION = "fileSelection",
  INPUTS = "inputs",
  SUCCESS = "success",
  MAIL = "mail",
  MAIL_SUCCESS = "mailSuccess",
  DA_IMPORT = "daImport",
  DA_EXPORT = "daExport"
}
export interface IStepper {
  next: (() => Promise<void>) | (() => void);
  previous: (() => Promise<void>) | (() => void);
}

@Component({
  components: {
    ReportDocumentSignRequestStepperChoice,
    ReportDocumentSignRequestStepperUpload,
    ReportDocumentSignRequestStepperFileSelection,
    ReportDocumentSignRequestStepperForm,
    ReportDocumentSignRequestStepperInputs,
    ReportDocumentSignRequestStepperSuccess,
    ReportDocumentSignRequestStepperSendMail,
    ReportDocumentSignRequestStepperMailSuccess,
    ReportDocumentSignRequestStepperDa,
    ReportDocumentSignRequestStepperDaExport,
    CostCardDocuments
  }
})
export default class ReportDocumentSignRequestSteps extends mixins(
  PartnerReportTimeLineCardScrollMixin,
  ReportDocumentSignRequestStepperMixin,
  FileInputMixin,
  PermissionMixin
) {
  @Prop({ default: false })
  fullscreen?: boolean;
  @Prop()
  private report?: IReport;

  signDocument: ISignDocument | null = null;
  reportContext: IReport | null = null;
  signRequest: ISignRequest | null = null;

  url = "";

  daServiceEvent: MrfiktivDAServiceEventDetailViewModelGen | null = null;

  get daServiceEventId() {
    return this.daServiceEvent?.serviceEventId;
  }

  get SignRequestStepperPageEnum() {
    return SignRequestStepperPageEnum;
  }

  @Ref("costCardDocuments")
  costCardDocuments!: CostCardDocuments;

  startDrag() {
    this.isDrag = true;
  }

  endDrag() {
    this.isDrag = false;
  }

  setReport(r: IReport | null) {
    this.$log.debug("setReport", r);
    this.reportContext = r;
    this.$log.debug("setReport", this.reportContext);
  }

  setDaServiceEvent(daServiceEvent: MrfiktivDAServiceEventDetailViewModelGen) {
    this.$log.debug("setDaServiceEvent", daServiceEvent);
    this.daServiceEvent = daServiceEvent;
    this.$log.debug("setDaServiceEvent", this.daServiceEvent);
  }

  setSignDocument(v: ISignDocument) {
    this.signDocument = v;

    this.currentStep = SignRequestStepperPageEnum.SIGNATURE_SELECTION;
  }

  setSignRequest(signRequest: ISignRequest) {
    this.signRequest = signRequest;
  }

  setUrl(url: string) {
    this.url = url;
  }

  previous() {
    (this.$refs[this.currentStep] as any).previous();
    if (this.currentStep === SignRequestStepperPageEnum.CHOICE) {
      this.reset();
    }
  }

  async next() {
    await (this.$refs[this.currentStep] as any).next();
    if (this.currentStep === SignRequestStepperPageEnum.CHOICE) {
      this.reset();
    }
  }

  reset() {
    this.signDocument = null;
    this.signRequest = null;
    this.url = "";
    this.setReport(this.report ?? null);
  }

  close() {
    this.currentStep = SignRequestStepperPageEnum.CHOICE;
    this.$emit("close");
    this.reset();
  }

  /**
   * When the signature request has been saved
   */
  save() {
    this.$emit("save");
  }

  mounted() {
    this.setReport(this.report ?? null);
  }

  async handleFileHook(file: File) {
    const signDocument = new SignDocument({
      name: file.name,
      title: file.name,
      isTemplate: false,
      refs: [],
      file: file,
      partnerId: this.$route.params.partnerId
    });

    this.costCardDocuments.isLoadingCreation = true;
    await signDocument.create().catch(handleError);
    this.costCardDocuments.isLoadingCreation = false;
    this.costCardDocuments.onCreated(signDocument);
  }

  @Watch("currentStep")
  onCurrentStepChange() {
    this.debounceScrollToBottom();
  }
}
