import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { ISignRequest, SignRequest } from "@/models/sign-request.entity";
import store from "@/store/VuexPlugin";
import { getModule, Module } from "vuex-module-decorators";
import { SignSignRequestControllerFindAllParamsGen } from "../../services/sign/v1/data-contracts";
import { PaginatedBaseStore } from "../paginated-base.store";
import { SignRequestDataAccessLayer } from "./access-layers/sign-request.access-layer";
import { SignRequestPageDataProvider } from "./page-data-provider/sign-request.page-data-provider";

@Module({
  dynamic: true,
  namespaced: true,
  name: "sign-request",
  store
})
export class SignRequestStore extends PaginatedBaseStore<ISignRequest, SignSignRequestControllerFindAllParamsGen> {
  protected _data = SignRequestDataAccessLayer;
  protected _pageProvider = SignRequestPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);
  filterOptions = SignRequest.filterables;

  get requests() {
    return this.filteredAndSorted;
  }
}

export const SignRequestModule = getModule(SignRequestStore);
