















import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { TicketModule } from "@/store/modules/ticket.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import RefsTicket from "../utility/RefsTicket.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { ITicket, Ticket } from "@/models/ticket.entity";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: { TimelineCard, ActivityTimeLineItemDelete, RefsTicket }
})
export default class ActivityTimeLineItemTicket extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  isLoading = false;

  ticket: ITicket | null = null;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  async mounted() {
    const targets = this.activityLogEntry.target;
    if (!targets) {
      return;
    }
    const target = targets[0];
    if (!target?.refId) {
      return;
    }

    try {
      this.isLoading = true;
      this.ticket = await (
        TicketModule.maps.id.get(target.refId)[0] ??
        new Ticket({
          id: target.refId,
          partnerId: this.activityLogEntry.partnerId
        })
      ).fetch();
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  goToDetail() {
    (this.$refs.refCard as RefsTicket).goToDetail();
  }
}
