







import { Component, Prop } from "vue-property-decorator";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import Tooltip from "@/components/utility/tooltip.vue";
import { IByAt } from "@/models/by-at.entity";
import { UserModule } from "@/store/modules/me-user.store";
import { $t } from "@/lib/utility/t";
import { ddmmyyyhhmm } from "@/lib/utility/date-helper";

@Component({
  components: {
    Tooltip
  },
  filters: {}
})
export default class ViewedPreview extends PermissionMixin {
  @Prop({ default: () => [] })
  value!: IByAt[];

  @Prop({ default: false })
  small!: boolean;

  get isReadByMe() {
    return this.value.some(v => v.by === UserModule.userId);
  }

  get isReadByAnyone() {
    return this.value.length > 0;
  }

  get readText() {
    const firstReadAt = this.value.map(v => v.at).sort()[0];

    return `${$t("objects.message.isRead")} ${ddmmyyyhhmm(firstReadAt)}`;
  }
}
