var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("paginated-table", {
    attrs: {
      store: _vm.store,
      headers: _vm.headers
    },
    scopedSlots: _vm._u([
      {
        key: "item.timestamp.created",
        fn: function fn(_ref) {
          var item = _ref.item
          return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }