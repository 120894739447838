import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { ISignRequest } from "@/models/sign-request.entity";
import signRequestService from "@/services/sign/services/sign-request.service";
import {
  SignDocumentControllerFindAllParamsGen,
  SignSignRequestControllerFindAllParamsGen
} from "@/services/sign/v1/data-contracts";

export const SignRequestPageDataProvider = new (class extends AbstractPageDataProvider<
  ISignRequest,
  SignDocumentControllerFindAllParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: SignSignRequestControllerFindAllParamsGen): Promise<IPageViewModel<ISignRequest>> {
    const res = await signRequestService.getAllForPartner(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ISignRequest[];

    return { meta, data };
  }
})();
