












































import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { MrfiktivUpdateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog,
    TemplateEditor
  }
})
export default class ActivityTimeLineItemUpdate extends mixins(PermissionMixin) {
  @Prop()
  activityLogEntry!: IActivityLog;

  @Prop({ default: false })
  text!: boolean;

  isEditDialogActive = false;

  isLoading = false;

  updateData: MrfiktivUpdateActivityLogDtoGen = {};

  startDialog() {
    this.isEditDialogActive = true;
    this.updateData = { comment: this.activityLogEntry.comment };
  }

  async deleteActivity() {
    try {
      this.isLoading = true;

      const data = this.updateData;

      await this.activityLogEntry.updatePartial(data);

      this.$toast.success("👍");

      this.isEditDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
