/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

/**
 * Options on who covers the costs.
 */
export enum CoverageEnum {
  INSURANCE_OPPONENT = "INSURANCE_OPPONENT",
  INSURANCE = "INSURANCE",
  SELF_PAYER = "SELF_PAYER"
}
