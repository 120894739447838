import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { IActivityLog } from "@/models/activity-log.entity";
import { MrfiktivEventControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import sharedActivityLogService from "@/services/shared/sharedActivityLogService";

export const ActivityLogPageDataProvider = new (class extends AbstractPageDataProvider<
  IActivityLog,
  MrfiktivEventControllerGetParamsGen
> {
  itemsPerPage = 250;

  async getPage(query: MrfiktivEventControllerGetParamsGen): Promise<IPageViewModel<IActivityLog>> {
    const res = await sharedActivityLogService.getAllForPartner({ ...query });

    const meta = res.meta;
    const data = (res.data || []) as IActivityLog[];

    return { meta, data };
  }
})();
