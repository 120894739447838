













import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import Card from "../utility/Card.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import Tooltip from "../utility/tooltip.vue";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard,
    Card,
    Tooltip,
    ConfirmActionDialog
  }
})
export default class TemplateDetailDeleteDialog extends mixins(PermissionMixin, DarkModeHighlightMixin)
  implements IDialog {
  @Prop()
  partnerId!: string;

  dialog = false;
  loading = false;

  get template(): MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen | undefined {
    if (this.partnerId) {
      return PartnerTemplateModule.partnerTemplate;
    } else {
      return AdminTemplateModule.adminTemplate;
    }
  }

  open() {
    this.dialog = true;
  }

  close() {
    this.dialog = false;
  }

  async deleteTemplate() {
    this.loading = true;
    try {
      if (this.partnerId && this.template) {
        await PartnerTemplateModule.deleteTemplate({
          partnerId: this.partnerId,
          key: this.template.key,
          language: this.template.meta.language as LanguageCodeEnum
        });
        PartnerTemplateModule.clearPartnerTemplate();
      } else if (this.template) {
        await AdminTemplateModule.deleteTemplate({
          key: this.template.key,
          language: this.template.meta.language as LanguageCodeEnum
        });
        AdminTemplateModule.clearAdminTemplate();
      }
      this.dialog = false;
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loading = false;
      this.$emit("onDelete");
    }
  }
}
