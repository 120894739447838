var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c("v-card-text", [
        _vm._v(
          " Deine Daten wurden erfolgreich an uns übermittelt. Wir halten dich über den aktuellen Bearbeitungsstatus auf dem Laufenden. "
        )
      ]),
      _vm.askForFeedback
        ? _c(
            "div",
            [
              _c("thg-rating-form", {
                attrs: {
                  title: _vm.$t("report.thgRating.title"),
                  description: _vm.$t("report.thgRating.description"),
                  ratingKey: "quickcheckout"
                }
              }),
              _c("v-divider", {
                staticClass: "my-4"
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                block: "",
                color: "info",
                dark: ""
              },
              on: {
                click: _vm.close
              }
            },
            [_vm._v(" Zur Fahrzeugübersicht ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }