var render = function() {
  var _vm$selectedUser,
    _vm$selectedUser$cont,
    _vm$selectedUser2,
    _vm$selectedUser2$con,
    _vm$selectedUser3,
    _vm$selectedUser4,
    _vm$selectedUser5,
    _vm$selectedUser6

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.loading
    ? _c(
        "v-card",
        {
          staticClass: "virtual-scroller",
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-container",
            [
              _c("m-header", {
                staticClass: "mt-1",
                attrs: {
                  title: ""
                    .concat(_vm.selectedUser.userName, " ")
                    .concat(_vm.getFlag(_vm.selectedUser.countryCode)),
                  actions: _vm.actions,
                  chips: _vm.chips
                },
                on: {
                  actionClicked: _vm.processAction
                }
              }),
              _c("edit-profile-dialog", {
                ref: "editProfileDialog",
                attrs: {
                  userToUpdate: _vm.selectedUser
                },
                on: {
                  updateUser: _vm.updateUser
                },
                model: {
                  value: _vm.isEditProfileDialog,
                  callback: function callback($$v) {
                    _vm.isEditProfileDialog = $$v
                  },
                  expression: "isEditProfileDialog"
                }
              }),
              _c(
                "v-tabs",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("common.nouns.details")))]),
                  _c("v-tab", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "timeLine.ThgQuoteDetailCardActivityTimeLineSideCard.title"
                        )
                      )
                    )
                  ]),
                  _vm.isGhgPortal
                    ? _c("v-tab", [
                        _vm._v(
                          _vm._s(_vm.$t("components.thg.BatchDetailCard.tab1"))
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab-item", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex"
                        },
                        style: _vm.$vuetify.breakpoint.smAndDown
                          ? "flex-direction: column-reverse;"
                          : "flex-direction: row;"
                      },
                      [
                        _c(
                          "div",
                          {
                            style: _vm.$vuetify.breakpoint.smAndDown
                              ? "width: 100%;"
                              : "width: 60%;"
                          },
                          [
                            _vm.ghgContext
                              ? _c("user-adminsitration-ghg-card", {
                                  attrs: {
                                    selectedUser: _vm.selectedUser
                                  },
                                  on: {
                                    goToGhg: _vm.goToGhg,
                                    goToChargingStation:
                                      _vm.goToChargingStation,
                                    goToMeterReading: _vm.goToMeterReading,
                                    goToBilling: _vm.goToBilling
                                  }
                                })
                              : _vm._e(),
                            _c("user-administration-detail-roles", {
                              attrs: {
                                roles: _vm.selectedUser.roles,
                                selectedUser: _vm.selectedUser
                              }
                            }),
                            _c("user-administration-detail-permissions", {
                              attrs: {
                                selectedUser: _vm.selectedUser,
                                permissions: _vm.selectedUser.permission
                              }
                            }),
                            _c("user-adminsitration-danger-zone-card", {
                              attrs: {
                                selectedUser: _vm.selectedUser
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            style: _vm.$vuetify.breakpoint.smAndDown
                              ? "width: 100%;"
                              : "width: 40%;"
                          },
                          [
                            _c("customer-contact-card", {
                              attrs: {
                                customerName: _vm.fullname,
                                address: _vm.selectedUser.address,
                                email:
                                  (_vm$selectedUser = _vm.selectedUser) ===
                                    null || _vm$selectedUser === void 0
                                    ? void 0
                                    : (_vm$selectedUser$cont =
                                        _vm$selectedUser.contact) === null ||
                                      _vm$selectedUser$cont === void 0
                                    ? void 0
                                    : _vm$selectedUser$cont.email,
                                phone:
                                  (_vm$selectedUser2 = _vm.selectedUser) ===
                                    null || _vm$selectedUser2 === void 0
                                    ? void 0
                                    : (_vm$selectedUser2$con =
                                        _vm$selectedUser2.contact) === null ||
                                      _vm$selectedUser2$con === void 0
                                    ? void 0
                                    : _vm$selectedUser2$con.phone,
                                isCompany:
                                  (_vm$selectedUser3 = _vm.selectedUser) ===
                                    null || _vm$selectedUser3 === void 0
                                    ? void 0
                                    : _vm$selectedUser3.isCompany,
                                companyName:
                                  (_vm$selectedUser4 = _vm.selectedUser) ===
                                    null || _vm$selectedUser4 === void 0
                                    ? void 0
                                    : _vm$selectedUser4.company,
                                isTaxDeductible:
                                  (_vm$selectedUser5 = _vm.selectedUser) ===
                                    null || _vm$selectedUser5 === void 0
                                    ? void 0
                                    : _vm$selectedUser5.isTaxDeductible,
                                taxnumber:
                                  (_vm$selectedUser6 = _vm.selectedUser) ===
                                    null || _vm$selectedUser6 === void 0
                                    ? void 0
                                    : _vm$selectedUser6.taxnumber
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function fn() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: ""
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.processAction({
                                                  key: _vm.EDIT
                                                })
                                              }
                                            }
                                          },
                                          [_c("v-icon", [_vm._v("mdi-pen")])],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1493762958
                              )
                            }),
                            _vm.showBanking
                              ? _c("portal-detail-banking", {
                                  key: _vm.selectedUser._id,
                                  attrs: {
                                    userId: _vm.selectedUser._id
                                  },
                                  on: {
                                    bankingLoading: _vm.bankingLoading
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c(
                    "v-tab-item",
                    [
                      _c("v-autocomplete", {
                        staticClass: "px-2 mt-2",
                        attrs: {
                          dense: "",
                          flat: "",
                          required: "",
                          outlined: "",
                          "data-test-partner": "",
                          label: _vm.$t(
                            "components.thg.PartnerSelectionForm.partner"
                          ),
                          loading: _vm.loading,
                          items: _vm.from,
                          "cache-items": "",
                          "hide-no-data": "",
                          "hide-details": "",
                          "item-text": "companyUsername",
                          "item-value": "_id",
                          solo: ""
                        },
                        model: {
                          value: _vm.selectedPartnerId,
                          callback: function callback($$v) {
                            _vm.selectedPartnerId = $$v
                          },
                          expression: "selectedPartnerId"
                        }
                      }),
                      _vm.selectedPartnerId
                        ? _c("activity-card", {
                            key: "activity-card" + _vm.selectedPartnerId,
                            attrs: {
                              partner: _vm.partner,
                              user: _vm.selectedUser,
                              source: _vm.activitySource,
                              config: {
                                comment: true,
                                mail: true
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.isGhgPortal
                    ? _c(
                        "v-tab-item",
                        [
                          _c(
                            "card",
                            {
                              staticClass: "ma-2 mb-4",
                              attrs: {
                                outlined: "",
                                "min-width": "250px",
                                flat: "",
                                title: _vm.$t(
                                  "components.partner.UserAdminsitrationDangerZoneCard.delete.ghgTitle"
                                )
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function fn() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              elevation: 0,
                                              small: "",
                                              color: "primary"
                                            },
                                            on: {
                                              click: function click($event) {
                                                return _vm.$emit("goToGhg")
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "components.partner.UserAdministrationGhgCard.ghg.action"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1694787491
                              )
                            },
                            [
                              _c("thg-user-table", {
                                attrs: {
                                  selectedUser: _vm.selectedUser
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("template-dialog", {
            ref: "templateDialog",
            attrs: {
              hideButton: true,
              from: _vm.adminTemplateSender,
              to: _vm.adminTemplateReceiver,
              context: {
                user: _vm.selectedUser
              }
            }
          })
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type:
            "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }