















































































































































































import { ConfigModule } from "@/store/modules/config";
import { Component, Prop, Vue } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import Tooltip from "../tooltip.vue";

export interface IChips extends IAction {
  /**
   * A tooltip displayed
   */
  tooltip?: string;
}

/**
 * Action configuration which is displayed as buttons in the `MHeader` or `MActionList`
 */
export interface IAction {
  /**
   * Is the string to be rendered on the button
   */
  text: string;

  /**
   * The description of the action
   */
  description?: string;

  /**
   * Key to identify that an action is clicked, needs to be implemented via `processAction`
   */
  key: string;

  /**
   * Disables the action
   */
  disabled?: boolean;

  /**
   * Overwrites the button color, defaults to `info`
   */
  color?: string;

  /**
   * Displays an icon, is displayed per default on mobile and in `MActionList`
   */
  icon?: string;

  /**
   * Amount of items
   */
  count?: number;

  /**
   * A function to execute the action
   */
  exec?: (...args: any[]) => any;

  multiple?: IAction[];
}

export interface IAlert {
  text: string;
  type: "error" | "warning" | "info";
  action?: IAction;
}

export interface IBreadcrumb {
  text: string | number | TranslateResult;
  exact: boolean;
  disabled?: boolean;
  to: { name: string; params?: Record<string, string>; query?: Record<string, string> } | undefined;
}

export interface IProperty extends IAction {
  value: string | number | boolean;
}

@Component({
  components: { Tooltip }
})
export default class MHeader extends Vue {
  @Prop()
  breadCrumbs!: IBreadcrumb[];

  @Prop()
  title!: string;

  @Prop()
  subtitle!: string;

  @Prop()
  alerts!: IAlert[];

  @Prop()
  chips!: IChips[];

  @Prop()
  actions!: IAction[];

  @Prop({ default: false })
  loading!: false;

  isMobile = this.$vuetify.breakpoint.smAndDown;

  measureIsMobile() {
    const container = this.$refs.mHeaderContainer as Element;

    const containerWidth = container?.clientWidth || 0;
    // this.$log.debug("measureIsMobile", containerWidth);

    // Breakpoint between xs and sm feels best on detail side of list view. e.g. report detail.
    const breakpoint =
      this.$vuetify.breakpoint.thresholds.xs +
      (this.$vuetify.breakpoint.thresholds.sm - this.$vuetify.breakpoint.thresholds.xs) / 2;

    if (!containerWidth) this.isMobile = this.$vuetify.breakpoint.smAndDown;
    else this.isMobile = containerWidth <= breakpoint;
  }

  get firstAction() {
    if (this.actions && this.actions.length) {
      return this.actions[0];
    }
    return undefined;
  }

  get secondAction() {
    if (this.actions && this.actions.length > 1) {
      return this.actions[1];
    }
    return undefined;
  }

  chipColor(chip: IAction, idx: number) {
    if (!chip.color) {
      return ConfigModule.color.analyticsColors[idx];
    } else {
      return chip.color;
    }
  }

  get otherActions() {
    if (this.actions && this.actions.length > 2) {
      return this.actions.slice(2);
    }
    return undefined;
  }

  get mobileActions() {
    if (this.actions && this.actions.length) {
      return this.actions;
    }
    return undefined;
  }

  get shouldActionsBeOnTop() {
    /** Don't alter styling if the slot is used */
    if (this.$slots.title) {
      return false;
    }

    /**
     * Mobile actions always be on the right
     * Slot for actions should not be affected
     * */
    if (this.isMobile || this.$slots.actions) {
      return false;
    }

    /**
     * Move the actions on top if the title if title is long enough(100 chars)
     * and there are more than 1 actions.
     */
    if (this.secondAction && this.title?.length > 100) {
      return true;
    }

    return false;
  }

  get titleContainerHtmlClasses() {
    return this.shouldActionsBeOnTop ? ["title-container", "flex-column"] : ["title-container"];
  }

  get titleHtmlClasses() {
    return ["title-wrapper"];
  }

  get actionsContainerHtmlClasses() {
    return this.shouldActionsBeOnTop ? ["order-first", "mx-3", "mb-2"] : ["actions-container"];
  }

  alertClicked(action: IAction | undefined) {
    if (action) {
      this.$emit("alertClicked", action);
    }
  }

  chipClicked(action: IAction | undefined) {
    if (action) {
      this.$emit("chipClicked", action);
    }
  }

  actionClicked(action: IAction | undefined) {
    if (action) {
      this.$emit("actionClicked", action);
    }
  }

  colorStyle(color: string | undefined) {
    if (color) {
      return `color: ${color}`;
    }
  }
}
