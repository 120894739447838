






















































































import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { AssetEnum } from "@/lib/AssetRepository";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { emailRuleOptional, phoneRule } from "@/lib/rules/contactRule";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { MrfiktivCreateReportWittnesDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import ReportImageUpload from "@/views/report/ReportImageUpload.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import NumberplateForm from "../utility/NumberplateForm.vue";
import InsuranceForm, { InsuranceTypeEnum } from "./InsuranceForm.vue";

@Component({
  components: {
    LayoutReportStep,
    ReportImageUpload,
    NumberplateForm,
    InsuranceForm
  }
})
export default class WitnessForm extends Vue {
  @Prop({ default: true })
  required!: boolean;

  @Prop({ default: false })
  numberplate!: boolean;

  @Prop()
  role?: ReportRoleEnum;

  @Prop({ default: false })
  showInsurance!: boolean;

  @Prop({ default: true })
  showInjury!: boolean;

  @Prop({ default: true })
  showLicense!: boolean;

  @Prop()
  value!: MrfiktivCreateReportWittnesDtoGen;

  assetFront = AssetEnum.driversLicenseExampleFrontOutlined;
  exampleFront = AssetEnum.driversLicenseExampleFront;

  instruction: IReportInstructionData = {
    title: "",
    text: "",
    pictures: [AssetEnum.registration],
    example: AssetEnum.registration,
    display: false
  };

  witness: MrfiktivCreateReportWittnesDtoGen = this.value ?? {
    firstName: "",
    lastName: "",
    contact: {
      email: "",
      phone: ""
    },
    imageIds: [],
    role: this.role as ReportRoleEnum,
    isInjured: false,
    note: "",
    numberplate: "",
    insurances: []
  };

  ReportRoleEnum = ReportRoleEnum;
  InsuranceTypeEnum = InsuranceTypeEnum;

  valid = false;
  hasInsurance = false;

  @Watch("valid", { deep: true })
  emitValid() {
    this.$emit("valid", this.valid);
  }

  @Watch("witness", { deep: true })
  emitUpdated() {
    this.$emit("input", this.witness);
  }

  @Watch("hasInsurance")
  toggleInsurance() {
    if (!this.hasInsurance) {
      this.witness.insurances = [];
    } else {
      this.witness.insurances = [
        { name: "", number: "", type: InsuranceTypeEnum.MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE }
      ];
    }
  }

  get isDone() {
    return this.valid;
  }

  get insuranceTypes() {
    return [InsuranceTypeEnum.MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE];
  }

  get type() {
    return InsuranceTypeEnum.MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE;
  }

  get witnessRolesItems() {
    return Object.values(ReportRoleEnum);
  }

  get typeFront() {
    return ReportImageType.driverslicensefront;
  }

  get numberplateRules() {
    const rules = [];

    rules.push(numberplateRule());

    return rules;
  }

  get emailRules() {
    const rules = [];
    if (this.required) {
      rules.push(requiredRule());
    }
    rules.push(emailRuleOptional());

    return rules;
  }

  get nameRule() {
    const rules = [];
    rules.push(requiredRule());

    return rules;
  }

  get noteRule() {
    const rules = [];
    if (this.required) {
      rules.push(requiredRule());
    }

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    if (this.required) {
      rules.push(requiredRule());
    }
    rules.push(phoneRule());

    return rules;
  }

  get roleRules() {
    const rules = [];

    if (this.required) {
      rules.push(requiredRule());
    }
    return rules;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  onImageUpload(image: IImageUploaded) {
    this.witness.imageIds.push(image.uploadId);
  }

  onImageDeleted(images: IImageUploaded[]) {
    const removed = images.map(i => i.uploadId);
    const filtered = this.witness.imageIds.filter((i: string) => {
      return !removed.includes(i);
    });

    this.witness.imageIds.splice(0);
    this.witness.imageIds.push(...filtered);
  }
}
