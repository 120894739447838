





























import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimeLineItemText from "../utility/TimeLineItemText.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: { TimeLineItemText, ActivityTimeLineItemDelete, ContextMenu }
})
export default class ActivityTimeLineItemReportUpdate extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }
}
