
























import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { handleError } from "@/lib/utility/handleError";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThirdPartySystemCard from "./ThirdPartySystemCard.vue";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
@Component({
  components: {
    MHeader,
    SideCard,
    ThirdPartySystemCard,
    LatestEntriesCardEmpty
  }
})
export default class ThirdPartySystemSideCard extends mixins(Vue) {
  @Prop()
  value!: IThirdPartySystem;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  async mounted() {
    try {
      this.isLoading = true;

      await this.value.fetch();

      if (this.value.system === TpsEnum.DA) {
        await this.value.fetchDa();
      }
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.$emit("close");
  }
}
