import { IEntity } from "@/lib/utility/data/entity.interface";
import {
  SignDocumentTokenCoordinatesGen,
  SignDocumentTokenDtoGen,
  SignDocumentViewModelGen
} from "@/services/sign/v1/data-contracts";
import { ITimestampDocument, TimestampDocument } from "./timestamp.entity";
import { IReference, Reference } from "./reference.entity";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import documentService from "@/services/sign/services/document.service";
import { handleError } from "@/lib/utility/handleError";
import { SignDocumentDataAccessLayer } from "@/store/modules/access-layers/sign-document.access-layer";

class DocumentTokenCoordinatesBase implements SignDocumentTokenCoordinatesGen {
  x: number;
  y: number;
  h: number;
  w: number;
  fontSize: number;

  constructor(coordinates?: Partial<SignDocumentTokenCoordinatesGen>) {
    this.x = coordinates?.x ?? 0;
    this.y = coordinates?.y ?? 0;
    this.h = coordinates?.h ?? 0;
    this.w = coordinates?.w ?? 0;
    this.fontSize = coordinates?.fontSize ?? 0;
  }
}

type IDocumentTokenCoordinates = DocumentTokenCoordinatesBase;
const DocumentTokenCoordinates = DocumentTokenCoordinatesBase;

export { IDocumentTokenCoordinates, DocumentTokenCoordinates };

class DocumentTokenDtoBase implements SignDocumentTokenDtoGen {
  /** The position and dimensions of the tokens */
  coordinates: IDocumentTokenCoordinates;

  /** The token */
  token: string;

  /** Required before a user can sign */
  isRequired?: boolean;

  /** If checked, user can edit the field */
  isEditable?: boolean;

  /** A short sentence explaining what this token is for */
  title?: string;

  /** A short sentence explaining what this token is for */
  description?: string;

  /** The token value */
  value?: string;

  constructor(token?: Partial<SignDocumentTokenDtoGen>) {
    this.coordinates = new DocumentTokenCoordinates(token?.coordinates);
    this.token = token?.token ?? "";
    this.isRequired = token?.isRequired;
    this.isEditable = token?.isEditable;
    this.title = token?.title;
    this.description = token?.description;
    this.value = token?.value;
  }
}

type IDocumentTokenDto = DocumentTokenDtoBase;
const DocumentTokenDto = DocumentTokenDtoBase;

export { IDocumentTokenDto, DocumentTokenDto };

@IsFilterable
class SignDocumentBase implements IEntity<SignDocumentViewModelGen> {
  /** The partner id of the document */
  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.document.partnerId" })
  partnerId: string;

  /** The id of the document */
  id: string;

  @FilterConfig({ type: FilterTypes.OBJECT_ID, displayName: "objects.document.id" })
  get _id() {
    return this.id;
  }

  /** The name of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.name" })
  name: string;

  /** The folder of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.folder" })
  folder: string;

  /** The url of the document */
  url: string;

  /** The type of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.type" })
  type: string;

  /** The title of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.title" })
  title: string;

  /** The text of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.text" })
  text: string;

  /** Tags assigned to the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.tags" })
  tags: string[];

  /** Is the document a template */
  @FilterConfig({ type: FilterTypes.BOOLEAN, displayName: "objects.document.isTemplate" })
  isTemplate: boolean;

  /** Is the document signed */
  @FilterConfig({ type: FilterTypes.BOOLEAN, displayName: "objects.document.isSigned" })
  isSigned: boolean;

  /**
   * The timestamps of the document
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  @FilterConfig({ type: TimestampDocument })
  timestamp: ITimestampDocument;

  /** The description of the document */
  @FilterConfig({ type: FilterTypes.STRING, displayName: "objects.document.description" })
  description?: string;

  /** The refs of the document */
  @FilterConfig({ type: Reference })
  refs: IReference[];

  /** The tokens of the document */
  tokens: IDocumentTokenDto[][];

  file?: File;

  constructor(document?: Partial<SignDocumentViewModelGen & { file: File }>) {
    this.partnerId = document?.partnerId ?? "";
    this.id = document?.id ?? "";
    this.name = document?.name ?? "";
    this.folder = document?.folder ?? "";
    this.url = document?.url ?? "";
    this.type = document?.type ?? "";
    this.title = document?.title ?? "";
    this.tags = document?.tags ?? [];
    this.isTemplate = document?.isTemplate ?? false;
    this.isSigned = document?.isSigned ?? false;
    this.timestamp = document?.timestamp as ITimestampDocument;
    this.description = document?.description;
    this.refs = document?.refs?.map(ref => new Reference(ref)) ?? [];
    this.tokens = document?.tokens?.map(token => token.map(t => new DocumentTokenDto(t))) ?? [];
    this.file = document?.file;
    this.text = document?.text ?? "";
  }

  map(document?: SignDocumentViewModelGen) {
    if (!document) return;

    this.partnerId = document.partnerId;
    this.id = document.id;
    this.name = document.name;
    this.folder = document.folder;
    this.url = document.url;
    this.type = document.type;
    this.title = document.title;
    this.tags = document.tags;
    this.isTemplate = document.isTemplate;
    this.isSigned = document.isSigned;
    this.timestamp = document.timestamp as ITimestampDocument;
    this.description = document.description;
    this.refs = document.refs?.map(ref => new Reference(ref)) ?? [];
    this.tokens = document.tokens?.map(token => token.map(t => new DocumentTokenDto(t))) ?? [];
    this.text = document.text ?? "";
  }

  async fetch(): Promise<this> {
    const res = await documentService.getOneForPartner(this.partnerId, this.id);

    this.map(res);

    SignDocumentDataAccessLayer.set(this);

    return this;
  }

  async update() {
    try {
      const res = await documentService.updateForPartner(this.partnerId, this.id, {
        file: this.file,
        description: this.description,
        title: this.title,
        tags: this.tags,
        refs: this.refs,
        folder: this.folder,
        isTemplate: this.isTemplate,
        name: this.name,
        tokens: this.tokens
      });

      this.map(res);

      SignDocumentDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async updateTokens() {
    try {
      const res = await documentService.updateDocumentTokens(this.partnerId, this.id, {
        tokens: this.tokens
      });

      this.map(res);

      SignDocumentDataAccessLayer.set(this);
    } catch (e) {
      handleError(e);
    }

    return this;
  }

  async delete(): Promise<void> {
    try {
      const res = await documentService.removeForPartner(this.partnerId, this.id);

      this.map(res);

      SignDocumentDataAccessLayer.delete(this);
    } catch (e) {
      handleError(e);
    }
  }

  async create(): Promise<this> {
    if (!this.file) {
      throw new Error("File is required");
    }

    const res = await documentService.create(this.partnerId, {
      file: this.file,
      description: this.description ?? "",
      name: this.name,
      title: this.title,
      folder: this.folder,
      isTemplate: this.isTemplate,
      refs: this.refs,
      tags: this.tags,
      tokens: this.tokens
    });
    this.map(res);

    SignDocumentDataAccessLayer.set(this);

    return this;
  }
}

type ISignDocument = SignDocumentBase;
const SignDocument = Filter.createForClass(SignDocumentBase);

export { ISignDocument, SignDocument };
