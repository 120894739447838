



































































































































































import GoToReferenceButton from "@/components/partner/GoToReferenceButton.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { detailedDate, formatYearsMonthDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { IReport } from "@/models/report.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { SignDigitalSignatureViewModelGen } from "@/services/sign/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import DocumentTemplateDetailSignatureTemplateDialog from "./DocumentTemplateDetailSignatureTemplateDialog.vue";
import SignInitializeDialog from "./SignInitializeDialog.vue";
import SignRequestForm from "./SignRequestForm.vue";
import { ISignDocument } from "@/models/sign-document.entity";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IReference } from "@/models/reference.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: {
    SideCard,
    SignRequestForm,
    GoToReferenceButton,
    SignInitializeDialog,
    DocumentTemplateDetailSignatureTemplateDialog
  },
  filters: {
    detailedDate
  }
})
export default class DocumentTemplateDetailSignature extends mixins(DocumentDetailMixin) {
  @Prop()
  loading!: boolean;

  @Prop()
  signature?: SignDigitalSignatureViewModelGen;

  @Prop()
  signatureRequest?: ISignRequest;

  @Prop()
  report?: IReport;

  @Prop()
  document!: ISignDocument;

  @Prop({ default: false })
  disabled!: boolean;

  get mailRefs() {
    const refs: IReference[] = [];

    if (this.document?.id) {
      refs.push({
        refId: this.document.id,
        refType: BackendResourceEnum.DOCUMENT
      });
    }

    if (this.report?.id) {
      refs.push({
        refId: this.report.id,
        refType: BackendResourceEnum.REPORT
      });
    }

    if (this.signatureRequest?.id) {
      refs.push({
        refId: this.signatureRequest.id,
        refType: BackendResourceEnum.SIGN
      });
    }

    return refs;
  }

  isValid = false;

  isEditSignatureRequest = false;

  updateDate(newDate: string) {
    const date = new Date(newDate);

    if (!this.signatureRequest) {
      return;
    }

    this.signatureRequest.validBy = date.toISOString();
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  getValidByText() {
    if (!this.signatureRequest?.validBy) {
      return "";
    }

    return simpleDoubleDigitDate(this.signatureRequest?.validBy);
  }

  getValidBy() {
    const date = new Date(this.signatureRequest?.validBy ?? "");

    return formatYearsMonthDay(date);
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get signatureUrl() {
    return this.signature?.signature?.url;
  }

  get authenticationType() {
    const type = this.signature?.authentication?.type;
    const i18nPath = "sign.DocumentTemplateDetailSignature";
    const translation = String(this.$t(`${i18nPath}.${type}`));

    if (translation.includes(i18nPath)) {
      return type;
    }

    return translation;
  }

  get partner() {
    return PartnerModule.partner;
  }

  get signatureCreatedDateFormatted() {
    if (this.signature) {
      return detailedDate(this.signature.timestamp.created);
    }
    return "";
  }

  get requiredRule() {
    return [requiredRule()];
  }

  save() {
    this.isEditSignatureRequest = false;

    this.$emit("save");
  }

  startEdit() {
    if (!this.signatureRequest) {
      return;
    }
    this.isEditSignatureRequest = true;
  }
}
