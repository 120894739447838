import { AppContextEnum } from "@/lib/enum/appContext.enum";
import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { Credential as MrfiktivCredential } from "../mrfiktiv/v1/Credential";
import { Credential as ThgCredential } from "../thg/v1/Credential";
import {
  MrfiktivCreateCredentialDtoGen,
  MrfiktivCredentialControllerGetParamsGen
} from "../mrfiktiv/v1/data-contracts";
import { ThgCreateCredentialDtoGen, ThgCredentialControllerGetParamsGen } from "../thg/v1/data-contracts";
import { ConfigModule } from "@/store/modules/config";

/**
 * Communicate with the credential API, switching based on app context.
 */
export class CredentialService {
  mrfiktivProxy: MrfiktivCredential;
  thgProxy: ThgCredential;

  /**
   * Construct the service.
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider, thgHttpClientProvider: ThgHttpClientProvider) {
    this.mrfiktivProxy = new MrfiktivCredential(mrfiktivHttpClientProvider.client());
    this.thgProxy = new ThgCredential(thgHttpClientProvider.client());
  }

  /**
   * Create a credential.
   */
  async create(partnerId: string, data: MrfiktivCreateCredentialDtoGen | ThgCreateCredentialDtoGen): Promise<any> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL || ConfigModule.appContext === AppContextEnum.THG) {
      return (await this.thgProxy.credentialControllerCreate(partnerId, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL || ConfigModule.appContext === AppContextEnum.REPORT) {
      return (await this.mrfiktivProxy.credentialControllerCreate(partnerId, data)).data;
    }
  }

  /**
   * Get all credentials.
   */
  async get(query: MrfiktivCredentialControllerGetParamsGen | ThgCredentialControllerGetParamsGen): Promise<any> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.credentialControllerGet(query)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.credentialControllerGet(query)).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }

  /**
   * Get a single credential by partner ID and credential ID.
   */
  async getOne(partnerId: string, id: string): Promise<any> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.credentialControllerGetOne(partnerId, id)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.credentialControllerGetOne(partnerId, id)).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }

  /**
   * Update a credential.
   */
  async update(
    partnerId: string,
    id: string,
    data: MrfiktivCreateCredentialDtoGen | ThgCreateCredentialDtoGen
  ): Promise<any> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.credentialControllerUpdate(partnerId, id, data)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.credentialControllerUpdate(partnerId, id, data)).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }

  /**
   * Delete a credential.
   */
  async delete(partnerId: string, id: string): Promise<any> {
    if (ConfigModule.appContext === AppContextEnum.THG_PORTAL) {
      return (await this.thgProxy.credentialControllerRemove(partnerId, id)).data;
    }

    if (ConfigModule.appContext === AppContextEnum.REPORT_PORTAL) {
      return (await this.mrfiktivProxy.credentialControllerRemove(partnerId, id)).data;
    }

    throw Error("Appcontext for not implemented: " + ConfigModule.appContext);
  }
}

export default new CredentialService(new MrfiktivHttpClientProvider(), new ThgHttpClientProvider());
