var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "draggable",
    {
      attrs: {
        list: _vm.selected,
        handle: ".handle"
      },
      on: {
        change: _vm.onChange
      }
    },
    _vm._l(_vm.selected, function(item, index) {
      return _c(
        "div",
        {
          key: "draggable" + index + item.refId + item.refType
        },
        [
          _c("v-hover", {
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function fn(_ref) {
                    var hover = _ref.hover
                    return [
                      _c(
                        "v-card",
                        {
                          staticClass: "my-2 mb-1",
                          attrs: {
                            outlined: _vm.outlined,
                            elevation: 0
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c(
                                "v-list-item-action",
                                {
                                  staticClass: "pa-0 ma-0 ml-n2 mr-1"
                                },
                                [
                                  (hover || _vm.isMobile) && _vm.draggable
                                    ? _c(
                                        "v-icon",
                                        {
                                          staticClass: "handle"
                                        },
                                        [_vm._v(" mdi-drag-vertical ")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                [
                                  item.refType
                                    ? _c(
                                        "tooltip",
                                        {
                                          attrs: {
                                            text: _vm.getRefTypeName(
                                              item.refType
                                            )
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.RefTypeMap.get(
                                                    item.refType
                                                  ).icon
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                {
                                  staticClass: "pa-0"
                                },
                                [
                                  _c("refs-list", {
                                    ref: "reference" + item.refId,
                                    refInFor: true,
                                    attrs: {
                                      itemPromise: function itemPromise() {
                                        return _vm.resolveReference(item)
                                      },
                                      refType: item.refType,
                                      confirmable: _vm.confirmable,
                                      small: _vm.small
                                    },
                                    on: {
                                      openDetail: function openDetail($event) {
                                        return _vm.goToDetail(item)
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-action",
                                {
                                  staticClass: "mx-1 px-0"
                                },
                                [
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: _vm.$t("remove")
                                      }
                                    },
                                    [
                                      _c(
                                        "v-flex",
                                        [
                                          (hover || _vm.isMobile) &&
                                          _vm.removable
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    color: "grey",
                                                    type: ""
                                                  },
                                                  on: {
                                                    click: function click(
                                                      $event
                                                    ) {
                                                      return _vm.removeFromSuggested(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: ""
                                                      }
                                                    },
                                                    [_vm._v(" mdi-close ")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }