import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import {
  MrfiktivAiControllerGetParamsGen,
  MrfiktivCreateThirdPartySystemDtoGen,
  MrfiktivPageViewModelGen,
  MrfiktivThirdPartySystemViewModelGen
} from "../v1/data-contracts";
import { ThirdPartySystemIntegrationService as ThirdPartySystemIntegration } from "../v1/ThirdPartySystemIntegrationService";

/**
 * Communicate with Third Party System (TPS) API
 */
class ThirdPartySystemIntegrationService {
  mrfiktivProxy: ThirdPartySystemIntegration;

  /**
   * Construct service
   * @param mrfiktivHttpClientProvider
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new ThirdPartySystemIntegration(mrfiktivHttpClientProvider.client());
  }

  /**
   * Get all Third Party System (TPS) results
   * @param data
   * @returns
   */
  async get(
    data: MrfiktivAiControllerGetParamsGen
  ): Promise<
    MrfiktivPageViewModelGen & {
      data?: MrfiktivThirdPartySystemViewModelGen[];
    }
  > {
    return (await this.mrfiktivProxy.thirdPartySystemControllerGet(data)).data;
  }

  /**
   * Get one result
   * @param partnerId
   * @param id
   * @returns
   */
  async getOne(partnerId: string, id: string): Promise<MrfiktivThirdPartySystemViewModelGen> {
    return (await this.mrfiktivProxy.thirdPartySystemControllerGetOne(partnerId, id)).data;
  }

  /**
   * Creates a TPS
   * @param partnerId
   * @param data
   * @returns
   */
  async create(
    partnerId: string,
    data: MrfiktivCreateThirdPartySystemDtoGen
  ): Promise<MrfiktivThirdPartySystemViewModelGen> {
    return (await this.mrfiktivProxy.thirdPartySystemControllerCreate(partnerId, data)).data;
  }

  /**
   * Updates a TPS
   * @param partnerId
   * @param id
   * @param data
   * @returns
   */
  async update(
    partnerId: string,
    id: string,
    data: MrfiktivCreateThirdPartySystemDtoGen
  ): Promise<MrfiktivThirdPartySystemViewModelGen> {
    return (await this.mrfiktivProxy.thirdPartySystemControllerUpdate(partnerId, id, data)).data;
  }

  /**
   * Removes a TPS
   * @param partnerId
   * @param id
   * @returns
   */
  async remove(partnerId: string, id: string): Promise<MrfiktivThirdPartySystemViewModelGen> {
    return (await this.mrfiktivProxy.thirdPartySystemControllerRemove(partnerId, id)).data;
  }
}

/**
 * Communicate with Third Party System (TPS) API
 */
export default new ThirdPartySystemIntegrationService(new MrfiktivHttpClientProvider());
