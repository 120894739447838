
















































import { activityLogName } from "@/lib/utility/activityLogName";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IActivityLog } from "@/models/activity-log.entity";
import { PartnerMessage } from "@/models/partner-message.entity";
import { AllMessagesModule } from "@/store/modules/all-messages.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import PartnerMessageSideCard from "@/components/partner/PartnerMessageSideCard.vue";

@Component({
  components: { TemplateEditor, TimelineCard, ActivityTimeLineItemDelete, ContextMenu, PartnerMessageSideCard }
})
export default class ActivityTimeLineItemMessage extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop({})
  activityLogEntry!: IActivityLog;

  message: IPartnerMessage | null = null;

  loading = false;

  isMessageSideCardOpen = false;

  get title() {
    return this.$t("timeLine.ActivityTimeLineEmailComment.titleReceiver", {
      name: this.name,
      date: this.date,
      receiver: this.message?.to || ""
    });
  }

  get name() {
    // show from-mail as name for incoming messages
    if (this.message?.virusVerdict || this.message?.spamVerdict) {
      return this.message.from;
    }

    return activityLogName(this.activityLogEntry);
  }

  get date() {
    if (this.activityLogEntry.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(this.activityLogEntry.timestamp?.created, locale);
    }
    return this.$t("sign.DocumentDetail.noDate");
  }

  async mounted() {
    if (!this.activityLogEntry.target || !this.activityLogEntry.target[0]) {
      this.$log.error("no message target");
      this.$toast.error("No message");

      return;
    }

    this.loading = true;

    const partnerId = PartnerModule.partner._id;
    const messageId = this.activityLogEntry.target[0].refId;

    try {
      this.message =
        AllMessagesModule.maps.id?.get(messageId)[0] ??
        (await new PartnerMessage({ partnerId: partnerId, id: messageId }).fetch());
    } catch (e) {
      handleError(e);
      this.message = null;
    } finally {
      this.loading = false;
    }
  }
}
