import { render, staticRenderFns } from "./ThirdPartySystemSideCard.vue?vue&type=template&id=085ca170&"
import script from "./ThirdPartySystemSideCard.vue?vue&type=script&lang=ts&"
export * from "./ThirdPartySystemSideCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VContainer,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('085ca170')) {
      api.createRecord('085ca170', component.options)
    } else {
      api.reload('085ca170', component.options)
    }
    module.hot.accept("./ThirdPartySystemSideCard.vue?vue&type=template&id=085ca170&", function () {
      api.rerender('085ca170', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/third-party-system/ThirdPartySystemSideCard.vue"
export default component.exports