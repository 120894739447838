import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Credential, ICredential } from "@/models/credential.entity";
import credentialService from "@/services/shared/credentialService";
import { MrfiktivCredentialControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgCredentialControllerGetParamsGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { CredentialDataAccessLayer } from "./access-layers/credential.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const CredentialPageDataProvider = new (class extends AbstractPageDataProvider<
  ICredential,
  MrfiktivCredentialControllerGetParamsGen | ThgCredentialControllerGetParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: MrfiktivCredentialControllerGetParamsGen): Promise<IPageViewModel<ICredential>> {
    const res = await credentialService.get(query);

    const meta = res.meta;
    const data = (res.data ?? []) as ICredential[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "credential",
  store
})
export class CredentialPaginationStore extends PaginatedBaseStore<
  ICredential,
  MrfiktivCredentialControllerGetParamsGen | ThgCredentialControllerGetParamsGen
> {
  protected _data = CredentialDataAccessLayer;
  protected _pageProvider = CredentialPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Credential.filterables;
}

export const CredentialModule = getModule(CredentialPaginationStore);
