import VueRouter from "vue-router";
import { BaseGoToHelper } from "./goToHelper";

export enum ThirdPartySystemRouteNames {
  THIRD_PARTY_SYSTEM_TABLE = "ThirdPartySystemRouteNames",
  THIRD_PARTY_SYSTEM_DETAIL = "THIRD_PARTY_SYSTEM_DETAIL"
}

type Tab = { newTab?: boolean };
type Partner = { partnerId: string };
type ThirdPartySystem = { thirdPartySystemId: string };
type Query = { query?: Record<string, string> };

export class ThirdPartySystemGoToHelper extends BaseGoToHelper {
  constructor(protected readonly router: VueRouter) {
    super(router);
  }

  static locations = {
    thirdPartySystemTable: (d: Partner & Query) => {
      return {
        name: ThirdPartySystemRouteNames.THIRD_PARTY_SYSTEM_TABLE,
        params: { partnerId: d.partnerId },
        query: d.query
      };
    },
    thirdPartySystemDetail: (d: Partner & ThirdPartySystem & Query) => {
      return {
        name: ThirdPartySystemRouteNames.THIRD_PARTY_SYSTEM_DETAIL,
        params: { partnerId: d.partnerId, thirdPartySystemId: d.thirdPartySystemId },
        query: d.query
      };
    }
  };

  goToThirdPartySystemTable(d: Tab & Partner & Query) {
    this.go(ThirdPartySystemGoToHelper.locations.thirdPartySystemTable(d), d.newTab);
  }

  goToThirdPartySystemDetail(d: Tab & Partner & ThirdPartySystem & Query) {
    this.go(ThirdPartySystemGoToHelper.locations.thirdPartySystemDetail(d), d.newTab);
  }
}
