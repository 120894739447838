var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.item.loading
    ? _c(
        "v-list-item",
        {
          attrs: {
            "three-line": ""
          }
        },
        [
          _c(
            "v-list-item-content",
            [
              !_vm.small
                ? _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(_vm.date) + " ")
                  ])
                : _vm._e(),
              _c(
                "v-list-item-title",
                {
                  staticClass: "text-truncate "
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex"
                      }
                    },
                    [
                      _c("div", [
                        _vm.hasOpenDetailListener()
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                    return _vm.emitOpenDetail.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(_vm.item.title) + " ")
                            ])
                      ]),
                      _c("v-spacer")
                    ],
                    1
                  )
                ]
              ),
              _vm.item.description
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs"
                    },
                    [_vm._v(" " + _vm._s(_vm.item.description) + " ")]
                  )
                : _vm._e(),
              !_vm.small
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs text-truncate"
                    },
                    [
                      !_vm.small
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(_vm.item.recipient.firstName) +
                                " " +
                                _vm._s(_vm.item.recipient.lastName) +
                                " " +
                                _vm._s(_vm.item.recipient.companyName) +
                                " " +
                                _vm._s(_vm.item.recipient.email) +
                                " "
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.small
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs text-truncate"
                    },
                    [
                      !_vm.small
                        ? [
                            _c("report-document-card-status-menu", {
                              attrs: {
                                signRequest: _vm.item
                              }
                            })
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type: "list-item-three-line"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }