import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";

/**
 *
 */
@IsFilterable
class DaServiceEventSettingBase {
  /** serviceEventId */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thirdPartySystem.setting.serviceEventId"
  })
  serviceEventId: string;

  constructor(entity: DaServiceEventSettingBase) {
    this.serviceEventId = entity.serviceEventId;
  }
}

type IDaServiceEventSetting = DaServiceEventSettingBase;
const DaServiceEventSetting = Filter.createForClass(DaServiceEventSettingBase);

export { DaServiceEventSetting, IDaServiceEventSetting };

/**
 *
 */
@IsFilterable
class KsrJobSettingBase {
  /** jobId */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thirdPartySystem.setting.jobId"
  })
  jobId: string;

  constructor(entity: KsrJobSettingBase) {
    this.jobId = entity.jobId;
  }
}

type IKsrJobSetting = KsrJobSettingBase;
const KsrJobSetting = Filter.createForClass(KsrJobSettingBase);

export { KsrJobSetting, IKsrJobSetting };
