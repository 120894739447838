








import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { RatingModule } from "@/store/modules/rating.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";

@Component({
  components: {
    PaginatedTable
  }
})
export default class RatingTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = RatingModule;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: $t("objects.rating.created"),
      value: "timestamp.created",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.rating.key"),
      value: "key",
      align: "start"
    });

    headers.push({
      text: $t("objects.rating.rating"),
      value: "rating",
      align: "start"
    });
    headers.push({
      text: $t("objects.rating.message"),
      value: "message",
      align: "start"
    });

    headers.push({
      text: $t("objects.rating.userId"),
      value: "userId",
      align: "start"
    });

    return headers;
  }
}
