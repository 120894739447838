





































import { handleError } from "@/lib/utility/handleError";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ActivityTimeLineItemDelete extends mixins(PermissionMixin) {
  @Prop()
  partnerId!: string;

  @Prop()
  activityId!: string;

  @Prop({ default: false })
  text!: boolean;

  isDeleteDialogActive = false;

  isLoading = false;

  async deleteActivity() {
    try {
      this.isLoading = true;

      await new ActivityLog({
        partnerId: this.partnerId,
        id: this.activityId
      }).delete();

      this.$toast.success("🗑️ 👍");

      this.isDeleteDialogActive = false;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }
}
