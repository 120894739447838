import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { ActivityLog, IActivityLog } from "@/models/activity-log.entity";
import { ThgActivityLogControllerFindAllParamsGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ActivityLogDataAccessLayer } from "./access-layers/activity-log-service.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ActivityLogPageDataProvider } from "./page-data-provider/activity-log.page-data-provider";

export class ActivityLogBaseStore extends PaginatedBaseStore<IActivityLog, ThgActivityLogControllerFindAllParamsGen> {
  protected _data = ActivityLogDataAccessLayer;
  protected _pageProvider = ActivityLogPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ActivityLog.filterables;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "activity-log",
  store
})
export class ActivityLogStore extends ActivityLogBaseStore {}

export const ActivityLogModule = getModule(ActivityLogStore);

@Module({
  dynamic: true,
  namespaced: true,
  name: "vehicle-activity-log",
  store
})
export class VehicleActivityLogStore extends ActivityLogBaseStore {}

export const VehicleActivityLogModule = getModule(VehicleActivityLogStore);
