


























import { handleError } from "@/lib/utility/handleError";
import { IReport } from "@/models/report.entity";
import { ISignRequest } from "@/models/sign-request.entity";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ReportDocumentSignRequestStepperMixin from "./ReportDocumentSignRequestStepperMixin.vue";
import { IStepper, SignRequestStepperPageEnum } from "./ReportDocumentSignRequestSteps.vue";

@Component({
  components: {}
})
export default class ReportDocumentSignRequestStepperDaExport extends mixins(ReportDocumentSignRequestStepperMixin)
  implements IStepper {
  @Prop()
  signRequest!: ISignRequest;

  @Prop()
  report?: IReport;

  get tpsForReport() {
    if (this.report?._id) {
      return ThirdPartySystemModule.maps.report?.get(this.report?._id);
    }

    return [];
  }

  get tpsForDocument() {
    if (this.signRequest?.documents[0]) {
      return ThirdPartySystemModule.maps.document?.get(this.signRequest?.documents[0]);
    }

    return [];
  }

  get tpsForSignRequest() {
    if (this.signRequest?.id) {
      return ThirdPartySystemModule.maps.sign?.get(this.signRequest?.id);
    }

    return [];
  }

  get tpsses() {
    return [...this.tpsForReport, ...this.tpsForDocument, ...this.tpsForSignRequest];
  }

  async next() {
    try {
      this.isLoading = true;
      // await (this.$refs.templateCard as PreselectTemplateCard)?.send();
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  previous() {
    this.currentStep = SignRequestStepperPageEnum.SUCCESS;
  }
}
