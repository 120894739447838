var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "timeline-card",
    {
      attrs: {
        title: _vm.$t(
          "components.partner.PartnerReportDetail.PartnerReportUpdateReportDialog.report.title.insurance"
        ),
        divider: true
      },
      scopedSlots: _vm._u(
        [
          _vm.canUpdateReport
            ? {
                key: "menu",
                fn: function fn() {
                  return [
                    _c("partner-report-update-report-dialog", {
                      attrs: {
                        config: [_vm.ReportUpdateTabEnum.insurance],
                        activityTarget: "accident"
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-simple-table", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn() {
                  return _vm._l(_vm.insuranceInfo, function(insurance, index) {
                    return _c(
                      "tbody",
                      {
                        key: "insurance-block-" + index
                      },
                      [
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("report.insurance.name")))
                          ]),
                          _c("td", [_vm._v(_vm._s(insurance.name))])
                        ]),
                        _c("tr", [
                          _c("td", [
                            _vm._v(_vm._s(_vm.$t("report.insurance.number")))
                          ]),
                          _c("td", [_vm._v(_vm._s(insurance.number))])
                        ]),
                        insurance.type
                          ? _c("tr", [
                              _c("td", [
                                _vm._v(_vm._s(_vm.$t("report.insurance.type")))
                              ]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "report.insurance.types.".concat(
                                          insurance.type
                                        )
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ])
                          : _vm._e(),
                        index !== _vm.insuranceInfo.length - 1
                          ? _c("tr", [_c("td"), _c("td")])
                          : _vm._e()
                      ]
                    )
                  })
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }