














































import { ExportReportMetaTypeEnum } from "@/lib/enum/export-report-meta-type.enum";
import { IKsrExportReportDto } from "@/lib/interfaces/ksr/ksr-export-report.dto";
import { IExportReportMeta } from "@/lib/interfaces/Report/export-report-meta.interface";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import PartnerReportDetailSendToKsrDialogExisting from "./PartnerReportDetailSendToKsrDialogExisting.vue";
import PartnerReportDetailSendToKsrDialogExportRequests from "./PartnerReportDetailSendToKsrDialogExportRequests.vue";
import PartnerReportDetailSendToKsrDialogNew from "./PartnerReportDetailSendToKsrDialogNew.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import Debug from "../utility/Debug.vue";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { OperationModule } from "@/store/modules/operation.store";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    ConfirmActionDialog,
    PartnerReportDetailSendToKsrDialogExisting,
    PartnerReportDetailSendToKsrDialogNew,
    PartnerReportDetailSendToKsrDialogExportRequests,
    Debug
  }
})
export default class PartnerReportDetailSendToKsrDialog extends PermissionMixin implements IDialog {
  @Prop()
  thirdPartySystem?: IThirdPartySystem;

  /**
   * initial amount determines the amount of ksr reports to be loaded from the backend if existant
   */
  initialAmount = 3;

  /**
   * Currently selected tab
   */
  tab = 0;

  /**
   * dialog to start export and select which job to attach the images to or if a new job is created in the ksr system
   */
  isDialogActive = false;

  /**
   * dialog to confirm send
   */
  isConfirmationDialogActive = false;

  /**
   * The information that is exported to KSR
   */
  ksrExportReportDto: IKsrExportReportDto | null = null;

  isLoadingSend = false;

  get metas() {
    return ExportModule.exportReportMetas.get(this.reportId) || [];
  }

  get metaDataNew() {
    return this.metas.find(m => m.exportReportMetaType === ExportReportMetaTypeEnum.KSR_NEW);
  }

  get metaDataExisting(): IExportReportMeta | undefined {
    if (this.thirdPartySystem) {
      return {
        _id: this.thirdPartySystem.id,
        partnerId: this.thirdPartySystem.partnerId,
        reportId: this.thirdPartySystem.refs.find(r => r.refType === BackendResourceEnum.REPORT)?.refId ?? "",
        exportReportMetaType: ExportReportMetaTypeEnum.KSR_EXISTING,
        meta: {
          jobId: Number(this.thirdPartySystem.externalId)
        },
        timestamp: {
          created: new Date(this.thirdPartySystem.timestamp.created)
        }
      };
    }

    return this.metas.find(m => m.exportReportMetaType === ExportReportMetaTypeEnum.KSR_EXISTING);
  }

  get isMeta() {
    return this.metaDataNew || this.metaDataExisting;
  }

  /**
   * the id of the current report
   */
  get reportId() {
    return this.$route?.params.reportId ?? PartnerModule.report._id;
  }

  /**
   * the id of the partner that the report belongs to
   */
  get partnerId() {
    return this.$route?.params.partnerId ?? PartnerModule.report.partnerId;
  }

  /**
   * The keys of the dto with data that is exported to KSR
   */
  get dtoKeys() {
    return Object.keys(this.ksrExportReportDto || {});
  }

  open(): void {
    this.isDialogActive = true;
  }

  close(): void {
    this.isDialogActive = false;
  }

  /**
   * cancels the confirmation dialog and returns to initial dialog
   */
  cancelConfirmationDialog() {
    this.isConfirmationDialogActive = false;
    this.isDialogActive = true;
  }

  /**
   * get job id from export report meta
   *
   * @param exportReport
   */
  getJobId(exportReport: IExportReportMeta) {
    return exportReport.meta.jobId;
  }

  /**
   * closes the dialog and opens the confirmation dialog
   */
  confirm(ksrExportReportDto: IKsrExportReportDto) {
    this.ksrExportReportDto = ksrExportReportDto;
    this.isDialogActive = false;
    this.isConfirmationDialogActive = true;
  }

  /**
   * sends the document to ksr
   */
  async send() {
    this.isLoadingSend = true;
    try {
      if (!this.ksrExportReportDto) {
        return;
      }

      const op = await ExportModule.ksrExportReport(this.ksrExportReportDto);

      OperationModule.dispatchToast({
        operationId: op.operationId,
        partnerId: this.partnerId,
        onClick: () => this.onClickToast(op.operationId)
      });
    } catch {
      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToKsrDialog.error"));
    }

    this.isConfirmationDialogActive = false;
    this.isLoadingSend = false;
  }

  /**
   * opens send to ksr dialog
   */
  async mounted() {
    ExportModule.clearKsrDocuments();
    ExportModule.clearKsrDocument();

    if (!this.metas?.length && !this.thirdPartySystem) {
      await ExportModule.getExportReportMetaByPartnerIdAndReportId({
        partnerId: this.partnerId,
        reportId: this.reportId
      });
    }
  }

  private onClickToast(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, this.partnerId, false);
  }
}
