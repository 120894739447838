









































































































































































































































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import PdfViewer from "@/components/files/v2/PdvViewer.vue";
import TemplateCard from "@/components/template/TemplateCard.vue";
import TemplateDialog from "@/components/template/TemplateDialog.vue";
import TemplateEditor from "@/components/template/TemplateEditor.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MHeader, { IAction, IBreadcrumb } from "@/components/utility/mmmint/MHeader.vue";
import RefsSelect from "@/components/utility/RefsSelect.vue";
import RefsSelected from "@/components/utility/RefsSelected.vue";
import SelectAssignees from "@/components/utility/SelectAssignees.vue";
import TimelineCard from "@/components/utility/TimelineItem.vue";
import ViewedPreview from "@/components/utility/ViewedPreview.vue";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { MessageFolderEnum } from "@/lib/enum/message-folder.enum";
import { ITemplateContext } from "@/lib/interfaces/template/templateContext.interface";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import { downloadFileViaAnchor } from "@/lib/utility/downloadFileFunc";
import { handleError } from "@/lib/utility/handleError";
import { isMobile } from "@/lib/utility/isMobile";
import { avatar } from "@/lib/utility/mail-helper";
import { $t } from "@/lib/utility/t";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ActivityLog } from "@/models/activity-log.entity";
import { IBaseImage } from "@/models/caseEntity";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IPartnerMessage, messageFolderIconMap } from "@/models/partner-message.entity";
import { IReference } from "@/models/reference.entity";
import { IReport, Report } from "@/models/report.entity";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import { TimeStampEntity } from "@/models/timestampEntity";
import reportService from "@/services/mrfiktiv/services/reportService";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { DocumentModule } from "@/store/modules/document.store";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ReportPaginationModule } from "@/store/modules/report-pagination.store";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    MHeader,
    TemplateEditor,
    TimelineCard,
    TemplateCard,
    TemplateDialog,
    LatestEntriesCardEmpty,
    ConfirmActionDialog,
    MDetailViewGrid,
    SelectAssignees,
    ViewedPreview,
    RefsSelected,
    RefsSelect,
    PdfViewer
  }
})
export default class PartnerMessageDetail extends PermissionMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop()
  value?: IPartnerMessage;

  isMounting = false;

  isArchiveDialogOpen = false;

  isArchiveLoading = false;

  isMarkUnreadLoading = false;

  unMarkMenu = false;

  isLoadingRefs = false;

  isMoveLoading = false;

  isMoveDialogOpen = false;

  isAttachmentsLoaded = false;

  isAttachmentsLoading = false;

  isOverflowing = false;

  isExpanded = false;

  selectedAttachment: File | null = null;

  isAttachmentDetailOpen = false;

  selectedAttachmentIndexesForExport: number[] = [];

  selectedReportsForExport: string[] = [];

  isAttachmentReportDialog = false;

  isAttachDialogOpen = false;

  isAttachDialogLoading = false;

  get isTouch() {
    return isMobile();
  }

  get folderOptions() {
    return Object.values(MessageFolderEnum);
  }

  get messageFolderIconMap() {
    return messageFolderIconMap;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get actions(): IAction[] {
    const actions: IAction[] = [];

    if (!this.$route.params.messageId) {
      actions.push({
        text: $t("common.nouns.detail"),
        key: "detail",
        icon: "mdi-open-in-new",
        exec: () => this.value?.goToDetail(this.$router)
      });
    }

    actions.push({
      text: $t("common.verbs.reply"),
      key: "Reply",
      icon: "mdi-reply-outline",
      exec: () => (this.$refs.templateDialog as TemplateDialog)?.show()
    });

    if (this.value?.url) {
      actions.push({
        text: $t("common.verbs.download"),
        key: "Download",
        icon: "mdi-download",
        exec: () => this.value?.download()
      });
    }

    if (this.value?.folder !== MessageFolderEnum.ARCHIVE) {
      actions.push({
        text: $t("common.verbs.archive"),
        key: "Archive",
        icon: "mdi-inbox",
        exec: () => (this.isArchiveDialogOpen = true)
      });
    }

    actions.push({
      text: $t("common.verbs.move"),
      key: "move",
      icon: "mdi-inbox-multiple",
      exec: () => (this.isMoveDialogOpen = true)
    });

    if (this.value?.viewed.length) {
      actions.push({
        text: $t("components.assignees.markUnread"),
        key: "unread",
        icon: "mdi-check-all",
        exec: () => this.markUnread()
      });
    }

    return actions;
  }

  get breadCrumbs(): IBreadcrumb[] {
    const breadCrumbs: IBreadcrumb[] = [];
    const isDetailView = this.$route.params.messageId;

    breadCrumbs.push({
      text: $t("common.nouns.inboxes"),
      to: { name: "PartnerMessageInboxList", params: { partnerId: this.partner.id } },
      exact: true
    });

    const inbox = PartnerModule.partner.settings?.inboxes?.find(inbox => inbox.identifier === this.value?.to);
    if (inbox && isDetailView) {
      breadCrumbs.push({
        text: inbox.name,
        to: {
          name: "PartnerMessageInbox",
          params: { partnerId: this.value?.partnerId ?? "", inboxId: this.value?.to ?? "" },
          query: { folder: this.value?.folder.toString() ?? "" }
        },
        exact: true
      });
    }

    if (this.value?.folder) {
      breadCrumbs.push({
        text: $t("MessageFolderEnum." + this.value?.folder),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    } else {
      breadCrumbs.push({
        text: $t("common.nouns.detail"),
        to: { name: "PartnerMessageDetail" },
        exact: true,
        disabled: true
      });
    }
    return breadCrumbs;
  }

  get chips(): IAction[] | undefined {
    return undefined;
  }

  /**
   * E.g. "user xy from mrfiktiv"
   */
  get fromMail() {
    let fromMail = PartnerModule.partner.companyName;
    let fromUser = "";

    if (this.value?.folder === MessageFolderEnum.OUTBOX) {
      return this.value?.to;
    }

    if (this.value?.from) {
      fromMail = this.value.from;
    }

    if (this.userNameForId) {
      fromUser = this.$t("components.PartnerMessageDetail.toAlt", { mail: this.userNameForId }).toString();
    }

    return fromUser + fromMail;
  }

  get date() {
    if ((this.value?.timestamp as TimeStampEntity).created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";

      return detailedDate((this.value?.timestamp as TimeStampEntity).created, locale);
    }
    return "";
  }

  get partner() {
    return PartnerModule.partner;
  }

  get from() {
    return this.partner;
  }

  get newMessageTo() {
    if (this.value?.from) {
      return [this.value.from];
    }

    return this.to;
  }

  get to(): string[] {
    if (!this.value?.to) {
      return [];
    }
    return [this.value?.to];
  }

  get context(): ITemplateContext {
    return { partner: this.partner };
  }

  get userNameForId() {
    if (this.value?.userId === undefined) {
      return "";
    }

    const user = PartnerUserModule.maps.id.get(this.value.userId)[0];
    if (!user) {
      return "";
    }

    return user.firstName + " " + user.lastName;
  }

  mounted() {
    this.getAttachments();
  }

  getObjectUrl(file: File) {
    return URL.createObjectURL(file);
  }

  selectAttachment(attachment: File) {
    this.closeSelectAttachment();

    this.$nextTick(() => {
      this.selectedAttachment = attachment;
      this.isAttachmentDetailOpen = true;
    });
  }

  closeSelectAttachment() {
    this.selectedAttachment = null;
    this.isAttachmentDetailOpen = false;
  }

  getAvatar(mail: string) {
    return avatar(mail);
  }

  @Watch("value.attachments.length")
  checkOverflow() {
    const container = this.$refs.container as HTMLElement;
    this.isOverflowing = container.scrollHeight > container.clientHeight;
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  attachToReport() {
    this.isAttachmentReportDialog = true;
    this.isExpanded = true;
    this.selectedAttachmentIndexesForExport.splice(0);
    this.value?.attachments.forEach((f, index) => {
      if (this.isImage(f) || this.isPdf(f)) {
        this.selectedAttachmentIndexesForExport.push(index);
      }
    });
    this.selectedReportsForExport.splice(0);
    const possibleSelectedReports = this.value?.refs?.filter(r => r.refType === ResourceEnum.REPORT);
    if (possibleSelectedReports?.length === 1) {
      this.selectedReportsForExport.push(possibleSelectedReports[0].refId);
    }
  }

  abortReportAttachment() {
    this.isAttachmentReportDialog = false;
    this.selectedAttachmentIndexesForExport.splice(0);
    this.selectedReportsForExport.splice(0);
    this.isExpanded = false;
  }

  openReportAttachmentDialog() {
    this.isAttachDialogOpen = true;
  }

  closeReportAttachmentDialog() {
    this.isAttachDialogOpen = false;
  }

  getIconForImageType(file: File) {
    if (this.isImage(file)) {
      return "mdi-image";
    } else if (this.isPdf(file)) {
      return "mdi-file";
    } else {
      return "mdi-file-question-outline";
    }
  }

  isImage(file?: File) {
    return file && file.type && file.type.startsWith("image");
  }

  isPdf(file?: File) {
    return file && file.type && file.type.endsWith("pdf");
  }

  abortArchive() {
    this.isArchiveDialogOpen = false;
  }

  fileSizeString(fileSize: number) {
    return `${Math.round((fileSize / (1024 * 1024)) * 100) / 100}MB`;
  }

  async archive() {
    try {
      if (!this.value) {
        return;
      }
      this.isArchiveLoading = true;
      await this.value.archive();
    } catch (e) {
      handleError(e);
    } finally {
      this.isArchiveDialogOpen = false;
      this.isArchiveLoading = false;
    }
  }

  async markUnread() {
    if (!this.value || this.isMarkUnreadLoading) {
      return;
    }

    this.isMarkUnreadLoading = true;
    await this.value.markUnread().catch(handleError);
    this.isMarkUnreadLoading = false;
    this.unMarkMenu = false;
  }

  async onAssigneesUpdate(assignees: []) {
    if (!this.value) return;

    await this.value.updatePartial({ assignees });
  }

  async onAssigneesAdded(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.CREATE_ASSIGNEE, assignees);
  }

  async onAssigneesRemoved(assignees: string[]) {
    if (!this.value) return;

    await this.value.createAssigneeActivity(ActivityTypeEnum.DELETE_ASSIGNEE, assignees);
  }

  async saveRefs() {
    if (!this.value) return;

    this.isLoadingRefs = true;
    await this.value
      .updatePartial({
        refs: this.value?.refs
      })
      .catch(handleError);
    this.isLoadingRefs = false;
  }

  async setNewFolder(folder: MessageFolderEnum) {
    if (!this.value) return;

    this.isMoveLoading = true;
    await this.value
      .updatePartial({
        folder: folder
      })
      .catch(handleError);
    this.isMoveLoading = false;
  }

  async getAttachments() {
    this.isAttachmentsLoaded = false;
    this.isAttachmentsLoading = true;
    await this.value?.getAttachments().catch(e => this.$log.error(e));
    this.isAttachmentsLoading = false;
    this.isAttachmentsLoaded = true;
  }

  downloadFile(file: File) {
    downloadFileViaAnchor(file, file.name);
  }

  async confirmReportAttachments() {
    this.isAttachDialogLoading = true;

    const files = this.selectedAttachmentIndexesForExport.map(index => this.value?.attachments[index]) ?? [];
    const imageFiles = files.filter(file => file && this.isImage(file)) as File[];
    const pdfFiles = files.filter(file => file && this.isPdf(file)) as File[];
    const reportIds = this.selectedReportsForExport;

    const reportAsync = reportIds.map(async reportId => {
      return (
        ReportPaginationModule.maps.id?.get(reportId)[0] ??
        (await new Report({ partnerId: this.value?.partnerId, id: reportId }).fetch())
      );
    });
    const reports = await Promise.all(reportAsync);

    await Promise.all([this.attachImagesToReport(imageFiles, reports), this.attachPdfsToReport(pdfFiles, reports)])
      .then(() => this.$toast("👍"))
      .catch(handleError);

    this.isAttachDialogLoading = false;
    this.isAttachDialogOpen = false;
    this.selectedAttachmentIndexesForExport.splice(0);
    this.selectedReportsForExport.splice(0);
    this.isAttachmentReportDialog = false;
  }

  async attachImagesToReport(images: File[], reports: IReport[]) {
    if (!images.length) return;
    if (!reports.length) return;

    const imagesAsync = images.map(image =>
      reportService.addImage(image, ReportImageType.overview, PartnerModule.partner.companyUsername)
    );
    const uploaded = await Promise.all(imagesAsync).catch(e => {
      handleError(e);
      return [] as IImageUploaded[];
    });

    for (const report of reports) {
      const reportImageIds = Object.values(report.images)
        .map(i => i.map((ii: IBaseImage) => ii.id))
        .flat();

      const allImages = [...reportImageIds, ...uploaded.map(u => u.uploadId)];

      await report.updateReportImages({ imageIds: allImages });
    }
  }

  async attachPdfsToReport(files: File[], reports: IReport[]) {
    if (!files.length) return;
    if (!reports.length) return;
    if (!this.value) return;

    DocumentModule.setFilters([
      new PageFilterElement({
        key: "refs.refId",
        value: this.value.id,
        operation: PageFilterOperationEnum.EQUAL
      })
    ]);
    await DocumentModule.fetchFirstPage({ partnerId: this.value.partnerId }).catch(handleError);

    const documents: ISignDocument[] = [];
    const documentsAsync = files.map(file =>
      this.createDocumentOrUpdateRefs(file, reports)
        .then(d => {
          if (d) documents.push(d);
        })
        .catch(handleError)
    );
    await Promise.all(documentsAsync);

    // check if the message alraedy has a ref to the document, if not add it
    const newRefs: IReference[] = [];
    for (const document of documents) {
      const existingRef = this.value.refs?.find(ref => ref.refId === document.id);
      if (!existingRef) {
        newRefs.push({ refId: document.id, refType: ResourceEnum.DOCUMENT });
      }
    }

    if (!newRefs.length) return;

    await this.value.updatePartial({ refs: [...(this.value.refs ?? []), ...newRefs] });
  }

  async createDocumentOrUpdateRefs(file: File, reports: IReport[]) {
    if (!this.value) return;

    // check if there is already a document with the same name referencing this mail (also @see filter set in attachPdfsToReport)
    const existingDocument = DocumentModule.maps.name?.get(file.name)[0];
    if (existingDocument) {
      const activitiesAsync = [];
      // check if the document already has a ref to the reports, if not add it
      const newRefsForDocument: IReference[] = [];
      for (const report of reports) {
        const existingRef = existingDocument.refs.find(ref => ref.refId === report.id);
        if (!existingRef) {
          newRefsForDocument.push({ refId: report.id, refType: ResourceEnum.REPORT });
          activitiesAsync.push(this.addDocumentAttachedToReportTimeLineEntry(existingDocument, report));
        }
      }

      // check if the document already has a ref to the message, if not add it
      const existingRef = existingDocument.refs.find(ref => ref.refId === this.value?.id);
      if (!existingRef) {
        newRefsForDocument.push({ refId: this.value.id, refType: ResourceEnum.MESSAGE });
      }

      // update the document with the new refs if there are any
      if (newRefsForDocument.length) {
        existingDocument.refs.push(...newRefsForDocument);
        await existingDocument.update();
      }

      await Promise.all(activitiesAsync);

      return existingDocument;
    }

    // create a new document if it does not exist
    const newDocument = await new SignDocument({
      partnerId: this.value.partnerId,
      file: file,
      description: $t("message.sentByFrom", { from: this.value.from, subject: this.value.content.subject }),
      name: file.name,
      title: file.name,
      folder: "attachments",
      isTemplate: false,
      refs: [
        { refId: this.value.id, refType: ResourceEnum.MESSAGE },
        ...reports.map(r => ({ refId: r.id, refType: ResourceEnum.REPORT }))
      ],
      tags: ["mail", "attachment"],
      tokens: []
    }).create();

    await Promise.all(reports.map(r => this.addDocumentAttachedToReportTimeLineEntry(newDocument, r)));

    return newDocument;
  }

  async addDocumentAttachedToReportTimeLineEntry(document: ISignDocument, report: IReport) {
    try {
      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType: BackendResourceEnum.REPORT,
          refId: report?.id ?? ""
        },
        target: [{ refType: BackendResourceEnum.DOCUMENT, refId: document.id }],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_DOCUMENT,
        comment: document.title
      };
      await new ActivityLog({
        partnerId: document.partnerId,
        ...data
      }).create();
    } catch (error) {
      this.$log.error(error);
    }
  }

  downloadSelected() {
    this.selectedAttachmentIndexesForExport.forEach(index => {
      if (this.value?.attachments[index]) this.downloadFile(this.value?.attachments[index]);
    });
  }
}
