








import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import { activityLogName } from "@/lib/utility/activityLogName";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import SignInitializeDialog from "@/views/sign/SignInitializeDialog.vue";
import { Component, Prop } from "vue-property-decorator";
import ContextMenu from "../utility/ContextMenu.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import ActivityTimeLineItemDelete from "./ActivityTimeLineItemDelete.vue";
import { IActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    TimelineCard,
    PartnerDetailAttributeWithToolTip,
    SignInitializeDialog,
    ActivityTimeLineItemDelete,
    ContextMenu
  }
})
export default class ActivityTimeLineItemCreated extends DarkModeHighlightMixin {
  @Prop({})
  activityLogEntry!: IActivityLog;

  get name() {
    return activityLogName(this.activityLogEntry);
  }

  get date() {
    return simpleDate(this.activityLogEntry.timestamp.created);
  }
}
