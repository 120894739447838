import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Ai } from "../v1/Ai";
import {
  MrfiktivAiControllerGetParamsGen,
  MrfiktivImageAnalysisAltDtoGen,
  MrfiktivImageAnalysisCostDtoGen,
  MrfiktivOpenAiImageAnalysisAltViewModelGen,
  MrfiktivOpenAiImageAnalysisCostViewModelGen
} from "../v1/data-contracts";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { ITranslationInput } from "@/lib/interfaces/translation.interface";
import { Utils } from "@/lib/utility/utils";
import { HttpClient } from "../v1/http-client";

/**
 * Communicate with ai API
 */
class AiService {
  mrfiktivProxy: Ai;
  client: HttpClient;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.client = mrfiktivHttpClientProvider.client();
    this.mrfiktivProxy = new Ai(this.client);
  }

  /**
   * Get all ai results
   */
  async get(data: MrfiktivAiControllerGetParamsGen) {
    return (await this.mrfiktivProxy.aiControllerGet(data)).data;
  }

  /**
   * Get one result
   */
  async getOne(partnerId: string, id: string) {
    return (await this.mrfiktivProxy.aiControllerGetOne(partnerId, id)).data;
  }

  /**
   * Get the VIN from the registration image
   */
  async getRegistrationResult(partnerId: string, imageId: string) {
    return (await this.mrfiktivProxy.aiControllerReadRegistrationResult(partnerId, imageId)).data;
  }

  /**
   * Get the summary from the report
   */
  async getReportSummary(partnerId: string, reportId: string, force?: boolean) {
    return (await this.mrfiktivProxy.aiControllerSummarize({ partnerId, reportId, force })).data;
  }

  /**
   * Get the summary from the report
   */
  async getTranslationAsPartner<T extends ITranslationInput>(
    partnerId: string,
    language: LanguageCodeEnum,
    translation: T
  ): Promise<T> {
    const res = (await this.mrfiktivProxy.aiControllerTranslateAsPartner(partnerId, language, { translation })).data;

    return res.translation as T;
  }

  /**
   * Get the summary from the report
   */
  async getTranslationAsAdmin<T extends ITranslationInput>(language: LanguageCodeEnum, translation: T): Promise<T> {
    const res = (await this.mrfiktivProxy.aiControllerTranslate(language, { translation })).data;

    return res.translation as T;
  }

  /**
   * Get the summary from the report
   */
  async analyzeCost(
    partnerId: string,
    data: MrfiktivImageAnalysisCostDtoGen
  ): Promise<MrfiktivOpenAiImageAnalysisCostViewModelGen> {
    const form = new FormData();
    Utils.buildFormData(form, data);

    return (await this.client.instance.post(`/partner/${partnerId}/ai/image-analysis/cost`, form)).data;
  }

  /**
   * Get the summary from the report
   */
  async analyzeAlt(
    partnerId: string,
    data: MrfiktivImageAnalysisAltDtoGen
  ): Promise<MrfiktivOpenAiImageAnalysisAltViewModelGen> {
    const res = (await this.mrfiktivProxy.aiControllerGenerateAltTextForImages(partnerId, data)).data;

    return res;
  }
}

/**
 * Communicate with ai API
 */
export default new AiService(new MrfiktivHttpClientProvider());
