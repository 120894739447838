import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { ThirdPartySystem, IThirdPartySystem } from "@/models/third-party-system.entity";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";

export const ThirdPartySystemDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<
  IThirdPartySystem,
  "system" | "report" | "document" | "sign"
> {
  protected mapEntity(entity: IThirdPartySystem): IThirdPartySystem {
    return new ThirdPartySystem(entity);
  }

  getIdentifier(entity: IThirdPartySystem): string {
    return entity.id;
  }

  mapsConfig = {
    id: {
      getIdentifier: (entity: IThirdPartySystem) => [entity.id]
    },
    externalId: {
      getIdentifier: (entity: IThirdPartySystem) => [entity.externalId]
    },
    system: { getIdentifier: (entity: IThirdPartySystem) => [entity.system] },
    report: {
      getIdentifier: (entity: IThirdPartySystem) =>
        entity.refs.filter(r => r.refType === BackendResourceEnum.REPORT).map(r => r.refId)
    },
    document: {
      getIdentifier: (entity: IThirdPartySystem) =>
        entity.refs.filter(r => r.refType === BackendResourceEnum.DOCUMENT).map(r => r.refId)
    },
    sign: {
      getIdentifier: (entity: IThirdPartySystem) =>
        entity.refs.filter(r => r.refType === BackendResourceEnum.SIGN).map(r => r.refId)
    }
  };
})();
