






































import { ISignRequest } from "@/models/sign-request.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import ReportDocumentCardStatusMenu from "@/views/sign/ReportDocumentCardStatusMenu.vue";

@Component({
  components: { ReportDocumentCardStatusMenu },
  filters: {}
})
export default class RefsSignRequest extends mixins(Vue) implements IRefDetail<ISignRequest> {
  @Prop()
  item!: ISignRequest;

  @Prop()
  small!: boolean;

  confirmable = false;

  get date() {
    return simpleDoubleDigitDate(this.item.timestamp.created);
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async goToDetail() {
    new GoToHelper(this.$router).goToSignatureRequestDetail(this.item.id, this.item.partnerId);
  }
}
