




















import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { GoToHelper } from "@/lib/utility/goToHelper";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { IDaExportReportDto } from "@/services/mrfiktiv/services/daService";
import { MrfiktivCreateActivityLogDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { ExportModule } from "@/store/modules/export.store";
import { PartnerModule } from "@/store/modules/partner";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import PartnerReportDetailSendToDaDialogStepper, {
  SendToDaDialogStepperWindowOptions
} from "./PartnerReportDetailSendToDaDialogStepper.vue";
import { OperationModule } from "@/store/modules/operation.store";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({ components: { ConfirmActionDialog, Debug, PartnerReportDetailSendToDaDialogStepper } })
export default class SendToDaDialog extends mixins(PermissionMixin) implements IDialog {
  @Prop()
  thirdPartySystem?: IThirdPartySystem;

  @Ref("daStepper")
  daStepper!: PartnerReportDetailSendToDaDialogStepper;

  isDialogActive = false;

  window: SendToDaDialogStepperWindowOptions = SendToDaDialogStepperWindowOptions.SEARCH;

  get partner() {
    return PartnerModule.partner;
  }

  get partnerId() {
    return this.partner.id;
  }

  get hideLeft() {
    return this.daStepper?.window === SendToDaDialogStepperWindowOptions.SEARCH;
  }

  get hideRight() {
    return this.daStepper?.window === SendToDaDialogStepperWindowOptions.SEARCH;
  }

  open() {
    this.isDialogActive = true;
    this.$nextTick(() => {
      this.daStepper.fetch();
    });
  }

  close(): void {
    this.daStepper?.clearSelection();
    this.isDialogActive = false;
  }

  leftClick() {
    this.window = SendToDaDialogStepperWindowOptions.SEARCH;
  }

  rightClick() {
    this.daStepper?.rightClick();
  }

  private onClickToast(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, this.partnerId, false);
  }

  async createWorkshopTask({
    serviceEventId,
    selectionWorkshopServiceId
  }: {
    serviceEventId: string;
    selectionWorkshopServiceId: string;
  }) {
    if (!serviceEventId) {
      this.$log.error("Missing service event");

      return;
    }

    const daExportReportDto: IDaExportReportDto = {
      daExportReportMetaDto: {
        serviceEventId: serviceEventId,
        workshopTaskId: selectionWorkshopServiceId
      }
    };

    try {
      const operation = await ExportModule.exportReportToDa({
        partnerId: this.partnerId,
        reportId: PartnerModule.report._id,
        daExportReportDto
      });

      await this.addActivity(operation.operationId);

      this.$toast.success(this.$t("components.partner.PartnerReportDetail.SendToDaDialog.success"), {
        onClick: () => {
          new GoToHelper(this.$router).goToOperationDetail(operation.operationId, this.partnerId, false);
        }
      });

      OperationModule.dispatchToast({
        operationId: operation.operationId,
        partnerId: this.partnerId,
        onClick: () => this.onClickToast(operation.operationId)
      });
      this.close();
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToExternOption.fail"));
    }
  }

  async createServiceEvent(daExportReportDto: IDaExportReportDto) {
    if (!daExportReportDto.resourceId || !daExportReportDto.eventTypeId) {
      this.$log.error("Missing resource or event type");

      return;
    }

    try {
      const operation = await ExportModule.exportReportToDa({
        partnerId: this.partnerId,
        reportId: PartnerModule.report._id,
        daExportReportDto
      });

      OperationModule.dispatchToast({
        operationId: operation.operationId,
        partnerId: this.partnerId,
        onClick: () => this.onClickToast(operation.operationId)
      });

      await this.addActivity(operation.operationId);

      this.close();
    } catch (e) {
      this.$log.error(e);

      this.$toast.error(this.$t("components.partner.PartnerReportDetail.SendToExternOption.fail"));
    }
  }

  async addActivity(comment: string) {
    const data: MrfiktivCreateActivityLogDtoGen = {
      source: {
        refType: BackendResourceEnum.REPORT,
        refId: PartnerModule.report.id
      },
      actionType: ActionEnum.CREATE,
      activity: ActivityTypeEnum.EXPORT,
      comment: comment
    };
    await new ActivityLog({ partnerId: this.partnerId, ...data }).create();
  }
}

interface IDateDisplay {
  value: string;
  display: string;
}
