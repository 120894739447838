





















import PartnerMessageDetail from "@/components/partner/PartnerMessageDetail.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IPartnerMessage } from "@/models/partner-message.entity";
import { handleError } from "@/lib/utility/handleError";
import SideCard from "@/components/utility/SideCard.vue";

@Component({
  components: {
    TheLayoutPortal,
    SideCard,
    PartnerMessageDetail
  }
})
export default class PartnerMessageSideCard extends mixins(PartnerFallbackMixin, PermissionMixin) {
  @Prop()
  value!: IPartnerMessage;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  isLoading = false;

  async mounted() {
    try {
      this.isLoading = true;

      await this.value.fetch();
    } catch (error) {
      handleError(error);
      this.close();
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.$emit("close");
  }
}
