
import { ISignDocument } from "@/models/sign-document.entity";
import { Component, Vue } from "vue-property-decorator";
import { DocumentTemplateModule } from "@/store/modules/document-template.store";
import { DocumentDetailSideBarElements } from "./DocumentDetailCardEditor.vue";
import { PDFDocumentProxy, RenderParameters } from "pdfjs-dist/types/src/display/api";
const PDFJSWorker = () => import("pdfjs-dist/legacy/build/pdf.worker.entry");
const pdfJs = () => import("pdfjs-dist/legacy/build/pdf");

export interface IDocumentPageMeta {
  scaleX: number;
  scaleY: number;
  src: string;
  pdfWidth: number;
  pdfHeight: number;
}

@Component({})
export default class DocumentDetailEditorMixin extends Vue {
  pages: IDocumentPageMeta[] = [];

  currentPageNumber = 0;

  get currentPage() {
    return this.pages[this.currentPageNumber];
  }

  async initialize(signDocument: ISignDocument) {
    const pdfjs = await pdfJs();
    const GlobalWorkerOptions = pdfjs.GlobalWorkerOptions;
    GlobalWorkerOptions.workerSrc = await PDFJSWorker();
    const pdf = await pdfjs.getDocument(signDocument.url).promise;

    this.$log.info(pdf);

    const pagesAsync = [];
    const totalPages = pdf.numPages;
    for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
      pagesAsync.push(this.processPage(pageNumber, pdf));
    }
    const pages = await Promise.all(pagesAsync);

    this.$log.debug("Pages", pages.length);

    this.pages.splice(0, this.pages.length, ...pages);

    if (signDocument.tokens?.length) {
      DocumentTemplateModule.initializeDocumentTokens(signDocument.tokens);
    } else {
      DocumentTemplateModule.initializeEmptyDocumentTokens(pages.length);
    }
  }

  async processPage(pageNumber: number, pdf: PDFDocumentProxy) {
    const page = await pdf.getPage(pageNumber);

    this.$log.info(page);

    if (page.rotate > 0) {
      this.$toast.warning(
        "Rotierte PDF erkannt. Bitte die PDF mit gewünschter Rotation 'als PDF drucken' und erneut probieren.",
        {
          timeout: false
        }
      );
    }

    // Set rotation to 0, as we can not process rotated pdfs
    const viewport = page.getViewport({ scale: 3, rotation: 0 });
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d") as CanvasRenderingContext2D;
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    const renderContext: RenderParameters = { canvasContext: context, viewport: viewport };
    const renderTask = page.render(renderContext);

    await renderTask.promise;

    const widthInMM = (viewport.viewBox[2] / 72) * 25.4;
    const heightInMM = (viewport.viewBox[3] / 72) * 25.4;

    return {
      src: canvas.toDataURL("image/png"),
      scaleX: widthInMM / viewport.width,
      scaleY: heightInMM / viewport.height,
      pdfWidth: widthInMM,
      pdfHeight: heightInMM
    };
  }

  get DocumentDetailSideBarElements() {
    return DocumentDetailSideBarElements;
  }
}
