var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.loading || _vm.isMounting
    ? _c("v-skeleton-loader", {
        attrs: {
          type: "article@1"
        }
      })
    : _vm.value
    ? _c(
        "v-card",
        {
          attrs: {
            flat: "",
            loading: _vm.loading || _vm.isMounting
          }
        },
        [
          _c("m-header", {
            attrs: {
              breadCrumbs: _vm.breadCrumbs,
              actions: _vm.actions,
              chips: _vm.chips,
              title: _vm.value.content.subject
            },
            on: {
              actionClicked: function actionClicked(action) {
                return action.exec()
              }
            }
          }),
          _c("v-divider"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    sm: "9"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pb-3 pt-2 ms-4"
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "white--text",
                          attrs: {
                            color: "primary"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.getAvatar(_vm.fromMail)) + " "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-flex flex-column justify-center ms-3",
                          staticStyle: {
                            "vertical-align": "middle"
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text--primary text-truncate font-weight-semibold"
                            },
                            [_vm._v(" " + _vm._s(_vm.fromMail) + " ")]
                          ),
                          _c(
                            "small",
                            {
                              staticClass: "text--disabled"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.PartnerMessageDetail.to",
                                      {
                                        mail: _vm.value.to
                                      }
                                    )
                                  )
                              )
                            ]
                          ),
                          _c(
                            "small",
                            {
                              staticClass: "text--disabled"
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.PartnerMessageDetail.from",
                                      {
                                        mail: _vm.value.from
                                      }
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _vm.isMobile
                            ? _c(
                                "div",
                                {
                                  staticClass: "d-flex align-center"
                                },
                                [
                                  _c(
                                    "small",
                                    {
                                      staticClass: "text--disabled"
                                    },
                                    [_vm._v(" " + _vm._s(_vm.date) + " ")]
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        bottom: ""
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function fn(_ref) {
                                              var on = _ref.on
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g({}, on),
                                                  [
                                                    !_vm.isAttachmentsLoaded
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              icon: "",
                                                              loading:
                                                                _vm.isAttachmentsLoading
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.getAttachments
                                                            }
                                                          },
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-attachment"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    !_vm.isMarkUnreadLoading
                                                      ? _c("viewed-preview", {
                                                          key:
                                                            "viewed-preview-value" +
                                                            _vm.value.viewed
                                                              .length,
                                                          attrs: {
                                                            small: true,
                                                            value:
                                                              _vm.value.viewed
                                                          }
                                                        })
                                                      : _c("v-btn", {
                                                          attrs: {
                                                            icon: "",
                                                            loading: ""
                                                          }
                                                        })
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        783443784
                                      ),
                                      model: {
                                        value: _vm.unMarkMenu,
                                        callback: function callback($$v) {
                                          _vm.unMarkMenu = $$v
                                        },
                                        expression: "unMarkMenu"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              attrs: {
                                                loading: _vm.isMarkUnreadLoading
                                              },
                                              on: {
                                                click: _vm.markUnread
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.assignees.markUnread"
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]),
                                              _c(
                                                "v-list-item-icon",
                                                [
                                                  _vm.isMarkUnreadLoading
                                                    ? _c(
                                                        "v-progress-circular",
                                                        {
                                                          attrs: {
                                                            size: "20",
                                                            color: "primary",
                                                            indeterminate: ""
                                                          }
                                                        }
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              !_vm.isMobile
                ? _c(
                    "v-col",
                    {
                      attrs: {
                        align: "right",
                        cols: "12",
                        sm: "3"
                      }
                    },
                    [
                      _c("v-card-text", [_vm._v(_vm._s(_vm.date))]),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref2) {
                                  var on = _ref2.on
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "mt-n4 mr-4"
                                      },
                                      [
                                        !_vm.isAttachmentsLoaded
                                          ? _c(
                                              "v-btn",
                                              {
                                                attrs: {
                                                  icon: "",
                                                  loading:
                                                    _vm.isAttachmentsLoading
                                                },
                                                on: {
                                                  click: _vm.getAttachments
                                                }
                                              },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-attachment")
                                                ])
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          _vm._g({}, on),
                                          [
                                            !_vm.isMarkUnreadLoading
                                              ? _c("viewed-preview", {
                                                  key:
                                                    "viewed-preview-value" +
                                                    _vm.value.viewed.length,
                                                  attrs: {
                                                    value: _vm.value.viewed
                                                  }
                                                })
                                              : _c("v-btn", {
                                                  attrs: {
                                                    icon: "",
                                                    loading: ""
                                                  }
                                                })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2334030175
                          ),
                          model: {
                            value: _vm.unMarkMenu,
                            callback: function callback($$v) {
                              _vm.unMarkMenu = $$v
                            },
                            expression: "unMarkMenu"
                          }
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-list-item",
                                {
                                  attrs: {
                                    loading: _vm.isMarkUnreadLoading
                                  },
                                  on: {
                                    click: _vm.markUnread
                                  }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.assignees.markUnread"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _vm.isMarkUnreadLoading
                                        ? _c("v-progress-circular", {
                                            attrs: {
                                              size: "20",
                                              color: "primary",
                                              indeterminate: ""
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.attachments &&
              _vm.value.attachments.length &&
              _vm.isAttachmentsLoaded
                ? _c(
                    "v-col",
                    {
                      directives: [
                        {
                          name: "resize",
                          rawName: "v-resize",
                          value: _vm.checkOverflow,
                          expression: "checkOverflow"
                        }
                      ]
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "mt-n7 mx-3",
                          staticStyle: {
                            display: "flex"
                          }
                        },
                        [
                          _vm.isAttachmentReportDialog
                            ? _c(
                                "span",
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        "x-small": "",
                                        elevation: 0
                                      },
                                      on: {
                                        click: _vm.abortReportAttachment
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("abort")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        "x-small": "",
                                        elevation: 0,
                                        color: "info",
                                        disabled: !_vm
                                          .selectedAttachmentIndexesForExport
                                          .length
                                      },
                                      on: {
                                        click: _vm.openReportAttachmentDialog
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "message.attachSelectionToReport"
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-1",
                                      attrs: {
                                        "x-small": "",
                                        elevation: 0,
                                        color: "info",
                                        disabled: !_vm
                                          .selectedAttachmentIndexesForExport
                                          .length
                                      },
                                      on: {
                                        click: _vm.downloadSelected
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("common.verbs.download")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    elevation: 0
                                  },
                                  on: {
                                    click: _vm.attachToReport
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("common.verbs.select")) +
                                      " "
                                  )
                                ]
                              )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "container",
                          staticClass: "attachments-container mx-2",
                          class: _vm.isExpanded ? "expand" : "no-expand"
                        },
                        _vm._l(_vm.value.attachments, function(
                          attachment,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: "attachments" + index,
                              style: _vm.$vuetify.breakpoint.mdAndUp
                                ? ""
                                : "width: 100%"
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "ma-1",
                                  style: _vm.$vuetify.breakpoint.mdAndUp
                                    ? "width: 278px"
                                    : "width: 98%",
                                  attrs: {
                                    outlined: ""
                                  },
                                  on: {
                                    click: function click($event) {
                                      return _vm.selectAttachment(attachment)
                                    }
                                  }
                                },
                                [
                                  _c("v-hover", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function fn(_ref3) {
                                            var hover = _ref3.hover
                                            return [
                                              _c(
                                                "v-list-item",
                                                [
                                                  attachment.type
                                                    ? _c(
                                                        "v-list-item-icon",
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                large: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getIconForImageType(
                                                                      attachment
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              attachment.name
                                                            ) +
                                                            " "
                                                        )
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.fileSizeString(
                                                                      attachment.size
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("v-spacer")
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _vm.isAttachmentReportDialog
                                                    ? _c("v-list-item-action", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "row",
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c("v-checkbox", {
                                                              attrs: {
                                                                value: index,
                                                                disabled: !(
                                                                  _vm.isImage(
                                                                    attachment
                                                                  ) ||
                                                                  _vm.isPdf(
                                                                    attachment
                                                                  )
                                                                )
                                                              },
                                                              on: {
                                                                click: function click(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                }
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.selectedAttachmentIndexesForExport,
                                                                callback: function callback(
                                                                  $$v
                                                                ) {
                                                                  _vm.selectedAttachmentIndexesForExport = $$v
                                                                },
                                                                expression:
                                                                  "selectedAttachmentIndexesForExport"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    : hover || _vm.isTouch
                                                    ? _c("v-list-item-action", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                              "flex-direction":
                                                                "row",
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  icon: ""
                                                                },
                                                                on: {
                                                                  click: function click(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.downloadFile(
                                                                      attachment
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    " mdi-download "
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "mx-3",
                          staticStyle: {
                            display: "flex"
                          }
                        },
                        [
                          _c("v-spacer"),
                          _vm.isOverflowing && !_vm.isAttachmentReportDialog
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    text: ""
                                  },
                                  on: {
                                    click: _vm.toggleExpand
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.isExpanded
                                          ? _vm.$t("showLess")
                                          : _vm.$t("showMore")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "m-detail-view-grid",
            {
              scopedSlots: _vm._u([
                {
                  key: "details",
                  fn: function fn() {
                    return [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-2"
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("select-assignees", {
                                attrs: {
                                  css: "width: 100%; margin-right: 9px",
                                  amount: 10,
                                  outlined: true,
                                  disabled: !_vm.can(
                                    _vm.ActionEnum.UPDATE,
                                    _vm.ResourceEnum.MESSAGE
                                  )
                                },
                                on: {
                                  input: _vm.onAssigneesUpdate,
                                  added: _vm.onAssigneesAdded,
                                  removed: _vm.onAssigneesRemoved
                                },
                                model: {
                                  value: _vm.value.assignees,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.value, "assignees", $$v)
                                  },
                                  expression: "value.assignees"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "12"
                              }
                            },
                            [
                              _c("refs-select", {
                                attrs: {
                                  partnerId: _vm.value.partnerId,
                                  loading: _vm.isLoadingRefs,
                                  suggested: [],
                                  listMode: false,
                                  isConfirmable: false,
                                  hideEditButton: false,
                                  hideSuggestions: true
                                },
                                on: {
                                  confirm: _vm.saveRefs,
                                  change: _vm.saveRefs,
                                  input: _vm.saveRefs
                                },
                                model: {
                                  value: _vm.value.refs,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.value, "refs", $$v)
                                  },
                                  expression: "value.refs"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "v-card-text",
                [
                  _c("template-editor", {
                    staticClass: "mt-2 mx-n2 pb-2",
                    attrs: {
                      value: _vm.value.content.body,
                      readOnly: true,
                      outlined: false
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("confirm-action-dialog", {
            attrs: {
              isDialogActive: _vm.isArchiveDialogOpen,
              absolute: true,
              title: _vm.$t("common.verbs.archive") + "?",
              rightLoading: _vm.isArchiveLoading
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isArchiveDialogOpen = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isArchiveDialogOpen = $event
              },
              leftClick: _vm.abortArchive,
              rightClick: _vm.archive
            }
          }),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isMoveDialogOpen,
                absolute: true,
                title: _vm.$t("common.verbs.move") + "?",
                loading: _vm.isMoveLoading,
                hideRight: true
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isMoveDialogOpen = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isMoveDialogOpen = $event
                },
                leftClick: function leftClick($event) {
                  _vm.isMoveDialogOpen = false
                },
                close: function close($event) {
                  _vm.isMoveDialogOpen = false
                }
              }
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: {
                        value: _vm.value.folder
                      }
                    },
                    _vm._l(_vm.folderOptions, function(f) {
                      return _c(
                        "v-list-item",
                        {
                          key: "folderOptions" + f,
                          attrs: {
                            value: f
                          },
                          on: {
                            click: function click($event) {
                              return _vm.setNewFolder(f)
                            }
                          }
                        },
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c("v-icon", [
                                _vm._v(_vm._s(_vm.messageFolderIconMap.get(f)))
                              ]),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("MessageFolderEnum.".concat(f))
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                isDialogActive: _vm.isAttachDialogOpen,
                absolute: true,
                title: _vm.$t("message.addAttachmentsToReport"),
                loading: _vm.isAttachDialogLoading || _vm.isLoadingRefs,
                rightLoading: _vm.isAttachDialogLoading || _vm.isLoadingRefs,
                rightDisabled: !_vm.selectedReportsForExport.length,
                rightText: _vm.$t("common.verbs.confirm"),
                leftText: _vm.$t("back")
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isAttachDialogOpen = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isAttachDialogOpen = $event
                },
                close: function close($event) {
                  _vm.isAttachDialogOpen = false
                },
                leftClick: function leftClick($event) {
                  _vm.isAttachDialogOpen = false
                },
                rightClick: _vm.confirmReportAttachments
              }
            },
            [
              _vm._l(
                _vm.value.refs.filter(function(r) {
                  return r.refType === _vm.ResourceEnum.REPORT
                }),
                function(ref, index) {
                  return _c(
                    "v-card",
                    {
                      key: "selectedAttachmentsForExport" + index,
                      attrs: {
                        outlined: ""
                      }
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "my-1"
                        },
                        [
                          _c(
                            "v-list-item-action",
                            [
                              _c("v-checkbox", {
                                attrs: {
                                  value: ref.refId
                                },
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                  }
                                },
                                model: {
                                  value: _vm.selectedReportsForExport,
                                  callback: function callback($$v) {
                                    _vm.selectedReportsForExport = $$v
                                  },
                                  expression: "selectedReportsForExport"
                                }
                              })
                            ],
                            1
                          ),
                          ref.refType === _vm.ResourceEnum.REPORT
                            ? _c("refs-selected", {
                                attrs: {
                                  value: [ref],
                                  partnerId: _vm.value.partnerId,
                                  draggable: false,
                                  loading: _vm.isLoadingRefs,
                                  removable: false,
                                  outlined: false,
                                  suggested: [],
                                  listMode: false,
                                  hideEditButton: false
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              ),
              _vm.value.refs &&
              !_vm.value.refs.filter(function(r) {
                return r.refType === _vm.ResourceEnum.REPORT
              }).length
                ? _c(
                    "div",
                    [
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("message.addReportToMailFirst")) +
                            " "
                        )
                      ]),
                      _c("refs-select", {
                        attrs: {
                          partnerId: _vm.value.partnerId,
                          loading: _vm.isLoadingRefs,
                          suggested: [],
                          listMode: true,
                          hideEditButton: false,
                          hideSuggestions: true,
                          categories: [_vm.ResourceEnum.REPORT]
                        },
                        on: {
                          confirm: _vm.saveRefs,
                          change: _vm.saveRefs,
                          input: _vm.saveRefs
                        },
                        model: {
                          value: _vm.value.refs,
                          callback: function callback($$v) {
                            _vm.$set(_vm.value, "refs", $$v)
                          },
                          expression: "value.refs"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          ),
          _vm.canCreateMessage
            ? _c("template-dialog", {
                ref: "templateDialog",
                attrs: {
                  hideButton: true,
                  partnerId: _vm.value.partnerId,
                  to: [_vm.value.from],
                  from: [_vm.partner],
                  refs: [
                    {
                      refType: _vm.ResourceEnum.MESSAGE,
                      refId: _vm.value.id
                    }
                  ]
                }
              })
            : _vm._e(),
          _vm.selectedAttachment
            ? _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    isDialogActive: _vm.isAttachmentDetailOpen,
                    title: _vm.selectedAttachment.name,
                    fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                    subtitle: _vm.fileSizeString(_vm.selectedAttachment.size),
                    hideRight: true
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isAttachmentDetailOpen = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isAttachmentDetailOpen = $event
                    },
                    leftClick: _vm.closeSelectAttachment,
                    close: _vm.closeSelectAttachment
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "actions",
                        fn: function fn() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  icon: ""
                                },
                                on: {
                                  click: function click($event) {
                                    $event.stopPropagation()
                                    return _vm.downloadFile(
                                      _vm.selectedAttachment
                                    )
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v(" mdi-download ")])],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2766574179
                  )
                },
                [
                  _vm.isImage(_vm.selectedAttachment)
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.getObjectUrl(_vm.selectedAttachment),
                          contain: "",
                          height: "100%",
                          width: "100%"
                        }
                      })
                    : _vm.isPdf(_vm.selectedAttachment)
                    ? _c("pdf-viewer", {
                        attrs: {
                          pdf: _vm.getObjectUrl(_vm.selectedAttachment),
                          horizontal: true,
                          vertical: false
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _c("latest-entries-card-empty")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }