





























































































import { getFlagEmojiByLanguage } from "@/lib/CountryCodeHelper";
import { LanguageCodeEnum } from "@/lib/enum/language-code.enum";
import { IDialog } from "@/lib/interfaces/dialog.interface";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import {
  MrfiktivAdminTemplateViewModelGen,
  MrfiktivCreateAdminTemplateDtoGen,
  MrfiktivPartnerTemplateViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import adminTemplateService from "@/services/shared/adminTemplateService";
import partnerTemplateService from "@/services/shared/partnerTemplateService";
import { ThgAdminTemplateViewModelGen, ThgPartnerTemplateViewModelGen } from "@/services/thg/v1/data-contracts";
import { AdminTemplateModule } from "@/store/modules/admin-template.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerTemplateModule } from "@/store/modules/partner-template.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import aiService from "@/services/mrfiktiv/services/aiService";
import TemplateEditor, { TemplateEditConfiguration } from "./TemplateEditor.vue";
import TemplateEditorSubject from "./TemplateEditorSubject.vue";
import { $t } from "@/lib/utility/t";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: {
    ConfirmActionDialog,
    TemplateEditor,
    TemplateEditorSubject
  },
  filters: {
    getFlagEmojiByLanguage
  }
})
export default class TemplateDetailLanguageDialog extends mixins(PermissionMixin, DarkModeHighlightMixin)
  implements IDialog {
  readonly availableLanguages = ConfigModule.availableLanguages;

  @Prop()
  template!: MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen;

  @Prop()
  partnerId?: string;

  loading = false;

  dialog = false;

  loadingCopy = false;

  stepLcl = 0;

  newLanguage: LanguageCodeEnum | "" = "";

  isTranslationLoading = false;

  newBody = "";

  newSubject = "";

  templates: Map<
    LanguageCodeEnum,
    MrfiktivAdminTemplateViewModelGen | MrfiktivPartnerTemplateViewModelGen | null
  > = new Map();

  get title() {
    if (!this.step) return $t("components.template.detail.language.title");
    else return `${$t("common.verbs.translate")} ${this.newLanguageFlag}`;
  }

  get newLanguageFlag() {
    if (!this.newLanguage) return "";
    else return getFlagEmojiByLanguage(this.newLanguage as LanguageCodeEnum);
  }

  get editConfiguration() {
    return new TemplateEditConfiguration(this.partnerId, true);
  }

  get debug() {
    return ConfigModule.debug;
  }

  get step() {
    return this.stepLcl;
  }

  set step(value: number) {
    this.stepLcl = value % 2;
  }

  get templateKey() {
    return this.template.key;
  }

  get language() {
    return this.template.meta.language;
  }

  open(): void {
    this.dialog = true;
    this.getTemplates();
  }

  close(): void {
    this.dialog = false;
  }

  async getTemplates() {
    this.loading = true;
    this.templates.clear();

    const templatesAsync = [];
    for (const language of this.availableLanguages) {
      const templateAsync = this.getTemplateForLanguage(language)
        .then(template => this.templates.set(language, template ?? null))
        .catch(this.$log.error);

      templatesAsync.push(templateAsync);
    }

    await Promise.all(templatesAsync);

    this.loading = false;
  }

  async getTemplateForLanguage(language: LanguageCodeEnum) {
    const key = this.templateKey;

    let template: ThgAdminTemplateViewModelGen | ThgPartnerTemplateViewModelGen | undefined;
    if (this.partnerId) {
      template = PartnerTemplateModule.maps.id.get(`${this.templateKey}.${language}`)[0];
      if (!template) template = await partnerTemplateService.getByKey(this.partnerId, key, language);
    } else {
      template = AdminTemplateModule.maps.id.get(`${this.templateKey}.${language}`)[0];
      if (!template) template = await adminTemplateService.getByKey(key, language);
    }

    if (template?.meta.language !== language) {
      template = undefined;
    }

    return template;
  }

  copyTemplate() {
    this.newBody = this.template.content.body;
    this.newSubject = this.template.content.subject;
    this.step++;
  }

  /**
   * Use ✨AI✨ to propose a translation for the template
   * @param language
   */
  async translate() {
    try {
      this.step++;

      this.isTranslationLoading = true;
      let translated;

      this.newBody = this.template.content.body;
      this.newSubject = this.template.content.subject;

      if (this.partnerId) {
        translated = await aiService.getTranslationAsPartner(this.partnerId, this.newLanguage as LanguageCodeEnum, {
          body: this.template.content.body,
          subject: this.template.content.subject
        });
      } else {
        translated = await aiService.getTranslationAsAdmin(this.newLanguage as LanguageCodeEnum, {
          body: this.template.content.body,
          subject: this.template.content.subject
        });
      }

      this.newBody = translated.body;
      this.newSubject = translated.subject;
    } catch (e) {
      handleError(e);
    } finally {
      this.isTranslationLoading = false;
    }
  }

  /**
   * @param language
   */
  async createNewTemplate() {
    this.loadingCopy = true;
    try {
      let created:
        | MrfiktivAdminTemplateViewModelGen
        | MrfiktivPartnerTemplateViewModelGen
        | ThgAdminTemplateViewModelGen
        | ThgPartnerTemplateViewModelGen
        | undefined;

      if (this.partnerId) {
        const data = {
          partnerId: this.partnerId,
          data: {
            key: this.templateKey,
            content: {
              body: this.newBody,
              subject: this.newSubject
            },
            meta: {
              ...this.template.meta,
              title: this.template.meta.title,
              language: this.newLanguage as LanguageCodeEnum
            }
          }
        };
        created = await PartnerTemplateModule.create(data);
        new GoToHelper(this.$router).setUrl({
          name: "TemplateDetailPartnerView",
          params: { key: this.templateKey, partnerId: this.partnerId },
          query: { language: created?.meta.language }
        });
      } else {
        const data: MrfiktivCreateAdminTemplateDtoGen = {
          key: this.templateKey,
          content: {
            body: this.newBody,
            subject: this.newSubject
          },
          meta: {
            ...this.template.meta,
            title: this.template.meta.title,
            language: this.newLanguage as LanguageCodeEnum
          },
          isPublic: (this.template as MrfiktivAdminTemplateViewModelGen).isPublic
        };
        created = await AdminTemplateModule.create(data);
        new GoToHelper(this.$router).setUrl({
          name: "TemplateDetailView",
          params: { key: this.templateKey },
          query: { language: created?.meta.language }
        });
      }

      this.$toast.success("👍", { onClick: () => this.goToTemplateDetail(created?.meta.language as LanguageCodeEnum) });
      this.templates.set(this.newLanguage as LanguageCodeEnum, created ?? null);
      this.step++;
    } catch (e) {
      handleError(e);
    }
    this.loadingCopy = false;
  }

  goToTemplateDetail(language: LanguageCodeEnum) {
    if (language === this.template.meta.language) {
      this.close();
      return;
    }

    if (this.partnerId && this.template) {
      this.$router.push({
        name: "TemplateDetailPartnerView",
        params: { key: this.templateKey, partnerId: this.partnerId },
        query: { language: language }
      });
    } else if (this.template) {
      this.$router.push({
        name: "TemplateDetailView",
        params: { key: this.templateKey },
        query: { language: language }
      });
    }
  }
}
