








import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivReportViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TimelineCard from "../utility/TimelineItem.vue";
import PartnerReportUpdateReportDialog from "./PartnerReportUpdateReportDialog.vue";
import { CoverageEnum } from "@/lib/enum/liability.enum";

@Component({
  components: {
    TimelineCard,
    Card,
    PartnerReportUpdateReportDialog
  }
})
export default class PartnerReportLiabilityTimeLineElement extends mixins(DarkModeHighlightMixin, PermissionMixin) {
  @Prop()
  report!: MrfiktivReportViewModelGen;

  get CoverageEnum() {
    return CoverageEnum;
  }

  get coverage() {
    return this.report.coverage;
  }

  get text() {
    const textMap = new Map<string, string>([
      [CoverageEnum.INSURANCE, "report.liabilityDamage.cards.insurance.title"],
      [CoverageEnum.INSURANCE_OPPONENT, "report.liabilityDamage.cards.insuranceOpponent.title"],
      [CoverageEnum.SELF_PAYER, "report.liabilityDamage.cards.selfPayer.title"]
    ]);

    if (!this.coverage) {
      return "";
    }

    return textMap.get(this.coverage) || "";
  }
}
