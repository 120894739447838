




















































































import RefsSelect from "@/components/utility/RefsSelect.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { detailedDate } from "@/lib/utility/date-helper";
import { ISignRequest } from "@/models/sign-request.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Ref } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import { handleError } from "@/lib/utility/handleError";
import { debounce } from "debounce";
import { ThirdPartySystemModule } from "@/store/modules/third-party-system.store";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import RefsThirdPartySystem from "@/components/utility/RefsThirdPartySystem.vue";
import { IThirdPartySystem, ThirdPartySystem } from "@/models/third-party-system.entity";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import PartnerReportDetailSendToDaDialogStepper, {
  SendToDaDialogStepperWindowOptions
} from "@/components/partner/PartnerReportDetailSendToDaDialogStepper.vue";
import { MrfiktivDAServiceEventDetailViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { FeatureModule } from "@/store/modules/feature.store";
import ThirdPartySystemSideCard from "@/components/third-party-system/ThirdPartySystemSideCard.vue";
import PartnerReportDetailSendToKsrDialogExisting from "@/components/partner/PartnerReportDetailSendToKsrDialogExisting.vue";
import PartnerReportDetailSendToKsrDialogExportRequests from "@/components/partner/PartnerReportDetailSendToKsrDialogExportRequests.vue";
import { ExportModule } from "@/store/modules/export.store";
import { IKsrExportReportDto } from "@/lib/interfaces/ksr/ksr-export-report.dto";

enum StepEnum {
  SELECT = "select",
  DA = "da",
  KSR = "ksr"
}

@Component({
  components: {
    SideCard,
    RefsSelect,
    RefsThirdPartySystem,
    ConfirmActionDialog,
    PartnerReportDetailSendToDaDialogStepper,
    ThirdPartySystemSideCard,
    PartnerReportDetailSendToKsrDialogExisting,
    PartnerReportDetailSendToKsrDialogExportRequests
  },
  filters: {
    detailedDate
  }
})
export default class DocumentTemplateDetailLink extends mixins(DocumentDetailMixin) {
  readonly StepEnum = StepEnum;

  @Prop()
  loading!: boolean;

  @Prop()
  signatureRequest!: ISignRequest;

  @Ref("daStepper")
  daStepper!: PartnerReportDetailSendToDaDialogStepper;

  externalStepper = StepEnum.SELECT;

  selectedTps: IThirdPartySystem | null = null;

  isExportDialogActive = false;

  isLoadingRefs = false;

  isLoadingThirdPartySystem = false;

  debounceSaveRefs = debounce(this.saveRefs, 500);

  daDialogStep = SendToDaDialogStepperWindowOptions.SEARCH;

  isSelectedKsrJobLoading = false;

  isDaSelectionLoading = false;

  tpss: IThirdPartySystem[] = [];

  get isKsrSetup() {
    return ExportModule.isKsrSetup;
  }

  get isDaSetup() {
    return ExportModule.isDaSetup;
  }

  get isTpsActive() {
    return FeatureModule.isTpsActive;
  }

  select(step: StepEnum) {
    this.externalStepper = step;
  }

  async mounted() {
    this.isLoadingThirdPartySystem = true;
    ThirdPartySystemModule.setFilters([
      new PageFilterElement({
        key: "refs.refId",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.signatureRequest.id
      })
    ]);
    await ThirdPartySystemModule.fetchFirstPage({ partnerId: this.signatureRequest.partnerId }).catch(handleError);

    this.tpss.splice(0);
    this.tpss.push(...ThirdPartySystemModule.filteredAndSorted);
    this.isLoadingThirdPartySystem = false;
  }

  async saveRefs() {
    this.isLoadingRefs = true;
    await this.signatureRequest.updatePartial({ refs: this.signatureRequest.refs }).catch(handleError);
    this.isLoadingRefs = false;
  }

  openTpsSideCard(tps: IThirdPartySystem) {
    this.selectedTps = null;
    this.$nextTick(() => {
      this.selectedTps = tps;
    });
  }

  goToDetail(item: IThirdPartySystem) {
    item.goToDetail(this.$router);
  }

  async exportToExternal() {
    this.isExportDialogActive = true;
  }

  leftClick() {
    if (this.daDialogStep !== SendToDaDialogStepperWindowOptions.SEARCH) {
      this.daDialogStep = SendToDaDialogStepperWindowOptions.SEARCH;
    } else {
      this.isExportDialogActive = false;
    }
  }

  rightClick() {
    this.daStepper?.rightClick();
  }

  async selectDossier(item: MrfiktivDAServiceEventDetailViewModelGen) {
    try {
      this.isDaSelectionLoading = true;
      this.$log.info("Selected dossier", item);

      // look up if a tps for the external id already exists
      ThirdPartySystemModule.setFilters([
        new PageFilterElement({
          key: "externalId",
          operation: PageFilterOperationEnum.EQUAL,
          value: item.serviceEventId
        })
      ]);
      await ThirdPartySystemModule.fetchFirstPage({ partnerId: this.signatureRequest.partnerId });

      const tps = ThirdPartySystemModule.maps.externalId?.get(item.serviceEventId)[0];

      if (tps) {
        this.$log.info("tps already exists");
        if (!tps.refs.find(r => r.refId === this.signatureRequest.id)) {
          tps.refs.push({ refId: this.signatureRequest.id, refType: BackendResourceEnum.SIGN });
          await tps.update();
          this.$log.info("tps refs extended");
        } else {
          this.$log.info("tps refs include this signature request already");
        }

        if (!this.tpss.find(t => t.externalId === item.serviceEventId)) {
          this.tpss.push(tps);
        }
      } else {
        // of tps does not exist create a new one
        this.$log.info("tps does not exist -> create a new one");
        const tps = new ThirdPartySystem({
          system: TpsEnum.DA,
          partnerId: this.signatureRequest.partnerId,
          externalId: item.serviceEventId,
          refs: [{ refId: this.signatureRequest.id, refType: BackendResourceEnum.SIGN }],
          setting: { serviceEventId: item.serviceEventId }
        });
        await tps.create();
        this.$log.info("tps created", tps);

        this.tpss.push(tps);
      }

      this.$toast.success("👍");
    } catch (e) {
      handleError(e);
    } finally {
      this.isDaSelectionLoading = false;
      this.isExportDialogActive = false;
    }
  }

  async updateOrCreateKSRJobTps(jobId: string) {
    this.$log.info("Selected job", jobId);

    // look up if a tps for the external id already exists
    ThirdPartySystemModule.setFilters([
      new PageFilterElement({
        key: "externalId",
        operation: PageFilterOperationEnum.EQUAL,
        value: jobId
      })
    ]);
    await ThirdPartySystemModule.fetchFirstPage({ partnerId: this.signatureRequest.partnerId });

    const tps = ThirdPartySystemModule.maps.externalId?.get(jobId)[0];

    if (!tps) {
      // of tps does not exist create a new one
      this.$log.info("tps does not exist -> create a new one");
      const tps = new ThirdPartySystem({
        system: TpsEnum.KSR,
        partnerId: this.signatureRequest.partnerId,
        externalId: jobId,
        refs: [{ refId: this.signatureRequest.id, refType: BackendResourceEnum.SIGN }],
        setting: { jobId: jobId }
      });
      await tps.create();
      this.$log.info("tps created", tps);
      this.tpss.push(tps);

      return;
    }

    this.$log.info("tps already exists");
    const found = tps.refs.find(r => r.refId === this.signatureRequest.id);
    if (!found) {
      tps.refs.push({ refId: this.signatureRequest.id, refType: BackendResourceEnum.SIGN });
      await tps.update();
      this.$log.info("tps refs extended");
    } else {
      this.$log.info("tps refs include this signature request already");
    }

    if (!this.tpss.find(t => t.externalId === jobId)) {
      this.tpss.push(tps);
    }
  }

  /**
   *
   * @param ksrExportReportDto
   */
  async selectKsrJob(ksrExportReportDto: IKsrExportReportDto) {
    try {
      this.isSelectedKsrJobLoading = true;
      if (!ksrExportReportDto.jobId) {
        throw new Error("No job id selected");
      } else {
        const jobId = ksrExportReportDto.jobId.toString();
        await this.updateOrCreateKSRJobTps(jobId);
        this.$toast.success("👍");
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isExportDialogActive = false;
      this.isSelectedKsrJobLoading = false;
    }
  }
}
