


































































import InsuranceForm, { InsuranceTypeEnum } from "@/components/report/InsuranceForm.vue";
import WitnessForm from "@/components/report/WitnessForm.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { CoverageEnum } from "@/lib/enum/liability.enum";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import ReportStepMixin from "@/mixins/ReportStepMixin.vue";
import {
  MrfiktivCreateInsuranceDtoGen,
  MrfiktivCreateReportWittnesDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import { ReportModule } from "@/store/modules/report.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutReportStep,
    InsuranceForm,
    WitnessForm
  }
})
export default class ReportLiabilityDamage extends ReportStepMixin implements IReportStep {
  header: IReportHeaderData = {
    title: "report.liabilityDamage.title",
    description: "report.liabilityDamage.description"
  };
  screen = ReportScreenEnum.liabilityselector;

  valid = false;
  isInsuranceValid = false;
  isOpponentValid = false;

  InsuranceTypeEnum = InsuranceTypeEnum;
  ReportRoleEnum = ReportRoleEnum;
  CoverageEnum = CoverageEnum;

  witnessTemp: MrfiktivCreateReportWittnesDtoGen = {
    firstName: "",
    lastName: "",
    contact: {
      email: "",
      phone: ""
    },
    imageIds: [],
    role: ReportRoleEnum.RESPONSIBLE,
    insurances: []
  };

  insuranceTemp: MrfiktivCreateInsuranceDtoGen = {
    name: "",
    number: "",
    type: InsuranceTypeEnum.MOTORISTS_THIRD_PARTY_LIABILITY_COVERAGE
  };

  mounted() {
    this.liabilityDamage = ReportModule.coverage ?? CoverageEnum.SELF_PAYER;
  }

  get body() {
    return ReportModule.body;
  }

  get liabilityDamage() {
    return ReportModule.coverage ?? CoverageEnum.SELF_PAYER;
  }

  set liabilityDamage(liability: CoverageEnum) {
    if (liability === CoverageEnum.INSURANCE_OPPONENT) {
      ReportModule.setLiableWitness(this.witnessTemp);
      ReportModule.setInsuranceList([]);
    }
    if (liability === CoverageEnum.INSURANCE) {
      ReportModule.setLiableWitness(undefined);
    }

    if (liability === CoverageEnum.SELF_PAYER) {
      ReportModule.setLiableWitness(undefined);
      ReportModule.setInsuranceList([]);
    }

    ReportModule.setCoverage(liability);
  }

  get isDone() {
    if (this.liabilityDamage === CoverageEnum.INSURANCE_OPPONENT) {
      return this.isOpponentValid;
    }

    if (this.liabilityDamage === CoverageEnum.INSURANCE) {
      return this.isInsuranceValid;
    }

    return true;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get insuranceTypes() {
    return [InsuranceTypeEnum.FULLY_COMPREHENSIVE_COVERAGE, InsuranceTypeEnum.PARTIALLY_COMPREHENSIVE_COVERAGE];
  }

  get witness() {
    return ReportModule.liableWitness;
  }

  set witness(liableWitness: MrfiktivCreateReportWittnesDtoGen | undefined) {
    ReportModule.setLiableWitness(liableWitness);
  }

  get insurance() {
    return ReportModule.insuranceList[0];
  }

  set insurance(insurance: MrfiktivCreateInsuranceDtoGen | undefined) {
    if (insurance) {
      ReportModule.setInsuranceList([insurance]);
    } else {
      ReportModule.setInsuranceList([]);
    }
  }
}
