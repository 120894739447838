






















import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ActivityLog } from "@/models/activity-log.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import {
  MrfiktivBaseCreateSharedContentDtoGen,
  MrfiktivCreateActivityLogDtoGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { SignCreateDocumentDtoGen } from "@/services/sign/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import SignDocumentForm from "./DocumentForm.vue";

@Component({
  components: { ConfirmActionDialog, SignDocumentForm }
})
export default class DocumentFormDialog extends Vue {
  @Prop()
  isDialogActive!: boolean;

  @Prop()
  reference!: MrfiktivBaseCreateSharedContentDtoGen;

  @Prop()
  partnerId!: string;

  @Prop()
  activity!: ActivityTypeEnum;

  @Prop({ default: true })
  createActivityOnUpload!: boolean;

  @Prop({ default: "/" })
  folder?: string;

  isLoading = false;

  rightDisabled = true;

  get requiredRule() {
    return [requiredRule()];
  }

  get dialogActive() {
    return this.isDialogActive;
  }

  set dialogActive(isActive: boolean) {
    this.$emit("update:isDialogActive", isActive);
  }

  updateValid(valid: boolean) {
    this.rightDisabled = !valid;
  }

  async save() {
    const form = this.$refs.form as SignDocumentForm;

    this.isLoading = true;

    this.$log.debug(document);

    let doc: ISignDocument | null = null;

    const refs = [];
    if (this.reference) {
      refs.push(this.reference);
    }
    const createDocumentDto: SignCreateDocumentDtoGen = {
      ...form.createDocumentDto,
      refs: refs
    };

    doc = await new SignDocument({ partnerId: this.partnerId, ...createDocumentDto }).create();
    if (doc.id) {
      this.$emit("created", doc);
      this.$toast.success("👍");
    }

    this.dialogActive = false;
    this.isLoading = false;

    if (doc && this.reference && this.createActivityOnUpload) {
      await this.saveActivity(doc);
    }
  }

  async saveActivity(doc: ISignDocument) {
    try {
      const data: MrfiktivCreateActivityLogDtoGen = {
        source: this.reference,
        target: [{ refType: BackendResourceEnum.DOCUMENT, refId: doc.id }],
        actionType: ActionEnum.CREATE,
        activity: this.activity,
        comment: `${doc.title} (${doc.name})`
      };

      const activity = await new ActivityLog({
        partnerId: this.partnerId,
        ...data
      }).create();

      this.$emit("save", activity);
    } catch (error) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.failedActivityCreation"));
      this.$log.error(error);
    }
  }
}
