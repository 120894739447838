














































































































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ReportRoleEnum } from "@/lib/enum/ReportRole.enum";
import { Component } from "vue-property-decorator";
import { AccidentDetailsDto } from "../fleet/CreateReportForm.vue";
import Card from "../utility/Card.vue";
import DateTimePicker from "../utility/DateTimePicker.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card,
    DateTimePicker
  }
})
export default class PartnerReportCardAccidentDetails extends PartnerReportCardGeneral {
  showRemove = false;

  mounted() {
    this.showRemove = !!this.reportDto.accidentDetails;
  }

  get roleItems() {
    return Object.values(ReportRoleEnum);
  }

  get countryCodeItems() {
    return Object.values(CountryCodeEnum);
  }

  get accidentGeoLat() {
    if (this.reportDto.accidentDetails?.address.geo.lat === undefined) {
      return "";
    }
    return this.reportDto.accidentDetails?.address.geo.lat.toString();
  }
  set accidentGeoLat(lat: string) {
    if (!this.reportDto.accidentDetails?.address.geo.lat) {
      return;
    }

    this.reportDto.accidentDetails.address.geo.lat = Number(lat);
  }
  get accidentGeoLng() {
    if (this.reportDto.accidentDetails?.address.geo.lng === undefined) {
      return "";
    }
    return this.reportDto.accidentDetails?.address.geo.lng.toString();
  }
  set accidentGeoLng(lng: string) {
    if (!this.reportDto.accidentDetails?.address.geo.lng) {
      return;
    }
    this.reportDto.accidentDetails.address.geo.lng = Number(lng);
  }

  get accidentDetailsDate() {
    if (!this.reportDto.accidentDetails) {
      return new Date();
    }
    return new Date(this.reportDto.accidentDetails.date);
  }
  set accidentDetailsDate(date: Date) {
    if (!this.reportDto.accidentDetails) {
      return;
    }
    this.reportDto.accidentDetails.date = date.toISOString();
  }

  add() {
    this.reportDto.accidentDetails = new AccidentDetailsDto();
    this.showRemove = true;
    this.key++;
  }

  remove() {
    this.reportDto.accidentDetails = null;
    this.showRemove = false;
    this.key++;
  }
}
