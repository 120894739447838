































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { IThirdPartySystem } from "@/models/third-party-system.entity";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { ExportModule } from "@/store/modules/export.store";
import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { IDaServiceEventSetting } from "@/models/third-party-system-setting.entity";
import { MrfiktivDAServiceEventDetailViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";

@Component({
  components: {},
  filters: { simpleDoubleDigitDate }
})
export default class RefsThirdPartySystem extends mixins(Vue) implements IRefDetail<IThirdPartySystem> {
  @Prop()
  item!: IThirdPartySystem;

  @Prop()
  small!: boolean;

  @Prop({ default: false })
  hideDate!: boolean;

  @Prop()
  confirmable!: boolean;

  serviceEvent: MrfiktivDAServiceEventDetailViewModelGen | null = null;

  get isDaSetup() {
    return ExportModule.isDaSetup;
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  async mounted() {
    await this.loadFromThirdPartySystem();
  }

  @Watch("isDaSetup")
  async loadFromThirdPartySystem() {
    if (!ExportModule.isDaSetup) {
      this.$log.debug("No DA Setup");
      return;
    }

    if (this.item.system === TpsEnum.DA && (this.item.setting as IDaServiceEventSetting)?.serviceEventId) {
      try {
        const serviceEvent = await ExportModule.getDaServiceEvent({
          serviceEventId: (this.item.setting as IDaServiceEventSetting).serviceEventId,
          partnerId: this.item.partnerId
        });

        this.serviceEvent = serviceEvent;
      } catch (e) {
        this.$log.error(e);
      }
    }
  }

  goToDetail() {
    this.item.goToDetail(this.$router);
  }
}
