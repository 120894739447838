/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MrfiktivCreateThirdPartySystemDtoGen,
  MrfiktivExceptionViewmodelGen,
  MrfiktivPageViewModelGen,
  MrfiktivThirdPartySystemControllerGetParamsGen,
  MrfiktivThirdPartySystemViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ThirdPartySystemIntegrationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Third party system integration service
   * @name ThirdPartySystemControllerCreate
   * @summary (TPS - POST) Creates a tps for a given partner
   * @request POST:/partner/{partnerId}/tps
   * @secure
   * @response `201` `MrfiktivThirdPartySystemViewModelGen` Tps created
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  thirdPartySystemControllerCreate = (
    partnerId: string,
    data: MrfiktivCreateThirdPartySystemDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivThirdPartySystemViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/tps`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Third party system integration service
   * @name ThirdPartySystemControllerGet
   * @summary (TPS - READ) Gets all tps from a given partner
   * @request GET:/partner/{partnerId}/tps
   * @secure
   * @response `200` `(MrfiktivPageViewModelGen & { data?: (MrfiktivThirdPartySystemViewModelGen)[] })`
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  thirdPartySystemControllerGet = (
    { partnerId, ...query }: MrfiktivThirdPartySystemControllerGetParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<
      MrfiktivPageViewModelGen & { data?: MrfiktivThirdPartySystemViewModelGen[] },
      MrfiktivExceptionViewmodelGen
    >({
      path: `/partner/${partnerId}/tps`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Third party system integration service
   * @name ThirdPartySystemControllerUpdate
   * @summary (TPS - UPDATE) Update tps for a given partner
   * @request PUT:/partner/{partnerId}/tps/{id}
   * @secure
   * @response `200` `MrfiktivThirdPartySystemViewModelGen` Updated tps document
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  thirdPartySystemControllerUpdate = (
    partnerId: string,
    id: string,
    data: MrfiktivCreateThirdPartySystemDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<MrfiktivThirdPartySystemViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/tps/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Third party system integration service
   * @name ThirdPartySystemControllerGetOne
   * @summary (TPS - READ) Gets a particular tps from a given partner
   * @request GET:/partner/{partnerId}/tps/{id}
   * @secure
   * @response `200` `MrfiktivThirdPartySystemViewModelGen` (TPS - READ) tps returned
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  thirdPartySystemControllerGetOne = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivThirdPartySystemViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/tps/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Third party system integration service
   * @name ThirdPartySystemControllerRemove
   * @summary (TPS - DELETE) Deletes a tps for a given partner
   * @request DELETE:/partner/{partnerId}/tps/{id}
   * @secure
   * @response `200` `MrfiktivThirdPartySystemViewModelGen` Tps deleted
   * @response `400` `MrfiktivExceptionViewmodelGen` Bad Request
   * @response `401` `MrfiktivExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `MrfiktivExceptionViewmodelGen` Forbidden.
   * @response `404` `MrfiktivExceptionViewmodelGen` Not Found
   * @response `500` `MrfiktivExceptionViewmodelGen` Internal Server Error
   */
  thirdPartySystemControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<MrfiktivThirdPartySystemViewModelGen, MrfiktivExceptionViewmodelGen>({
      path: `/partner/${partnerId}/tps/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
