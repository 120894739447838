import { TpsEnum } from "@/lib/enum/third-party-system-type.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IVSelectItem } from "@/lib/interfaces/v-select-item.interface";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { ThirdPartySystemGoToHelper } from "@/lib/utility/third-party-system.go-to-helper";
import thirdPartySystemIntegrationService from "@/services/mrfiktiv/services/third-party-system-integration.service";
import {
  MrfiktivCreateThirdPartySystemDtoGen,
  MrfiktivThirdPartySystemViewModelGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThirdPartySystemDataAccessLayer } from "@/store/modules/access-layers/third-party-system.access-layer";
import VueRouter from "vue-router";
import { IReference, Reference } from "./reference.entity";
import {
  DaServiceEventSetting,
  IDaServiceEventSetting,
  IKsrJobSetting,
  KsrJobSetting
} from "./third-party-system-setting.entity";
import { ITimestamp, Timestamp } from "./timestamp.entity";
import daService from "@/services/mrfiktiv/services/daService";
import ksrService from "@/services/mrfiktiv/services/ksrService";
import { DaWebhookModule } from "@/store/modules/da-webhook.store";

@IsFilterable
class ThirdPartySystemBase
  implements IEntity<MrfiktivThirdPartySystemViewModelGen, MrfiktivCreateThirdPartySystemDtoGen> {
  loading = false;

  /** identifier */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.id"
  })
  id: string;

  /** identifier of partner */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thirdPartySystem.partnerId"
  })
  partnerId: string;

  /** identifier of user that created the tps */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thirdPartySystem.userId"
  })
  userId?: string;

  /** timestamp of the tps */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  /** The references of the tps */
  @FilterConfig({
    type: Reference
  })
  refs: IReference[];

  /** The third party system */
  @FilterConfig({
    type: FilterTypes.ENUM,
    displayName: "objects.thirdPartySystem.system",
    config: {
      items: Object.values(TpsEnum).map(e => {
        return {
          text: `enums.TpsEnum.${e}`,
          value: e
        } as IVSelectItem;
      }),
      itemValue: "value"
    }
  })
  system: TpsEnum;

  /**
   * External Id
   * @example ABCDEFGHI
   */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.thirdPartySystem.externalId"
  })
  externalId: string;

  /** The setting */
  @FilterConfig({
    type: KsrJobSetting
  })
  @FilterConfig({
    type: DaServiceEventSetting
  })
  setting?: IKsrJobSetting | IDaServiceEventSetting;

  constructor(data: Partial<MrfiktivThirdPartySystemViewModelGen> | IThirdPartySystem) {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.userId = data.userId;
    this.timestamp = new Timestamp(data.timestamp);
    this.refs = (data.refs ?? []).map(ref => new Reference(ref));
    this.system = data.system as TpsEnum;
    this.externalId = data.externalId ?? "";

    if ((data.setting as IDaServiceEventSetting)?.serviceEventId) {
      this.setting = new DaServiceEventSetting(data.setting as IDaServiceEventSetting);
    } else if ((data.setting as IKsrJobSetting)?.jobId) {
      this.setting = new KsrJobSetting(data.setting as IKsrJobSetting);
    }
  }

  private map(data: MrfiktivThirdPartySystemViewModelGen): void {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.userId = data.userId;
    this.timestamp = new Timestamp(data.timestamp);
    this.refs = (data.refs ?? []).map(ref => new Reference(ref));
    this.system = data.system as TpsEnum;
    this.externalId = data.externalId ?? "";
    if ((data.setting as IDaServiceEventSetting)?.serviceEventId) {
      this.setting = new DaServiceEventSetting(data.setting as IDaServiceEventSetting);
    } else if ((data.setting as IKsrJobSetting)?.jobId) {
      this.setting = new KsrJobSetting(data.setting as IKsrJobSetting);
    }
  }

  async create(): Promise<this> {
    const res = await thirdPartySystemIntegrationService.create(this.partnerId, {
      externalId: this.externalId,
      refs: this.refs,
      system: this.system,
      setting: this.setting
    });

    this.map(res);
    ThirdPartySystemDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await thirdPartySystemIntegrationService.getOne(this.partnerId, this.id);
      this.map(res);
      ThirdPartySystemDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      ThirdPartySystemDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async updatePartial(dto: MrfiktivCreateThirdPartySystemDtoGen): Promise<this> {
    const res = await thirdPartySystemIntegrationService.update(this.partnerId, this.id, dto);

    this.map(res);
    ThirdPartySystemDataAccessLayer.set(this);

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await this.updatePartial({
        externalId: this.externalId,
        refs: this.refs,
        system: this.system,
        setting: this.setting
      });
      this.loading = false;
    } catch (e) {
      this.loading = false;
      throw e;
    }

    return this;
  }

  async delete(): Promise<void> {
    const res = await thirdPartySystemIntegrationService.remove(this.partnerId, this.id);

    this.map(res);

    ThirdPartySystemDataAccessLayer.delete(this);
  }

  goToDetail(router: VueRouter, newTab?: boolean) {
    new ThirdPartySystemGoToHelper(router).goToThirdPartySystemDetail({
      partnerId: this.partnerId,
      thirdPartySystemId: this.id,
      newTab
    });
  }

  exportSignRequest(signRequestId: string) {
    if (this.system === TpsEnum.DA) return daService.exportSignToDa(this.partnerId, this.id, signRequestId);
    else if (this.system === TpsEnum.KSR) return ksrService.exportSignToKsr(this.partnerId, this.id, signRequestId);
    else throw new Error(`System ${this.system} not supported`);
  }

  fetchDa() {
    if (this.system === TpsEnum.DA) {
      return DaWebhookModule.findOne({ partnerId: this.partnerId, id: Number(this.externalId) });
    }
  }
}

type IThirdPartySystem = ThirdPartySystemBase;
const ThirdPartySystem = Filter.createForClass(ThirdPartySystemBase);

export { IThirdPartySystem, ThirdPartySystem };
