var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        height: "100%"
      },
      attrs: {
        flat: ""
      }
    },
    [
      _vm.currentStep === _vm.SignRequestStepperPageEnum.CHOICE
        ? _c(
            "div",
            {
              staticStyle: {
                height: "100%"
              },
              on: {
                dragover: function dragover($event) {
                  $event.preventDefault()
                  return _vm.startDrag.apply(null, arguments)
                }
              }
            },
            [
              _vm.isDrag
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "z-index": "1",
                        top: "0",
                        left: "0",
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        "background-color": "rgba(200,200,200,0.5)"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            height: "100%",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center"
                          },
                          on: {
                            dragleave: function dragleave($event) {
                              $event.preventDefault()
                              return _vm.endDrag.apply(null, arguments)
                            },
                            drop: function drop($event) {
                              $event.preventDefault()
                              return _vm.onFileDrop($event)
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: {
                                transform: "scale(300%)"
                              },
                              attrs: {
                                "x-large": ""
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _c("report-document-sign-request-stepper-choice", {
                        ref: _vm.SignRequestStepperPageEnum.CHOICE,
                        attrs: {
                          step: _vm.currentStep,
                          loading: _vm.isLoading,
                          valid: _vm.isValid,
                          report: _vm.reportContext
                        },
                        on: {
                          "update:step": function updateStep($event) {
                            _vm.currentStep = $event
                          },
                          "update:loading": function updateLoading($event) {
                            _vm.isLoading = $event
                          },
                          "update:valid": function updateValid($event) {
                            _vm.isValid = $event
                          },
                          close: _vm.close
                        }
                      }),
                      _c(
                        "v-row",
                        {
                          staticStyle: {
                            visibility: "hidden"
                          }
                        },
                        [
                          _c("cost-card-documents", {
                            ref: "costCardDocuments",
                            attrs: {
                              disabledDragAndDrop: true,
                              partnerId: _vm.$route.params.partnerId,
                              loading: false
                            },
                            on: {
                              created: _vm.setSignDocument
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.DA_IMPORT
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-da", {
                    ref: _vm.SignRequestStepperPageEnum.DA_IMPORT,
                    attrs: {
                      step: _vm.currentStep,
                      loading: _vm.isLoading
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      setReport: _vm.setReport,
                      setDaServiceEvent: _vm.setDaServiceEvent
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.UPLOAD
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-upload", {
                    ref: _vm.SignRequestStepperPageEnum.UPLOAD,
                    attrs: {
                      report: _vm.reportContext,
                      step: _vm.currentStep,
                      loading: _vm.isLoading,
                      valid: _vm.isValid
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      setSignDocument: _vm.setSignDocument
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.FILE_SELECTION
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-file-selection", {
                    ref: _vm.SignRequestStepperPageEnum.FILE_SELECTION,
                    attrs: {
                      step: _vm.currentStep,
                      loading: _vm.isLoading,
                      valid: _vm.isValid
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      setSignDocument: _vm.setSignDocument
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.SIGNATURE_SELECTION
        ? _c(
            "div",
            {
              style: "height: ".concat(
                _vm.fullscreen ? "calc(100vh - 200px)" : "500px",
                "; overflow: hidden"
              )
            },
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _vm.signDocument
                    ? _c("report-document-sign-request-stepper-form", {
                        ref: _vm.SignRequestStepperPageEnum.SIGNATURE_SELECTION,
                        attrs: {
                          height: _vm.fullscreen
                            ? "calc(100vh - 200px)"
                            : "500px",
                          fullscreen: _vm.fullscreen,
                          step: _vm.currentStep,
                          loading: _vm.isLoading,
                          valid: _vm.isValid,
                          signDocument: _vm.signDocument,
                          report: _vm.reportContext
                        },
                        on: {
                          "update:step": function updateStep($event) {
                            _vm.currentStep = $event
                          },
                          "update:loading": function updateLoading($event) {
                            _vm.isLoading = $event
                          },
                          "update:valid": function updateValid($event) {
                            _vm.isValid = $event
                          },
                          setSignDocument: _vm.setSignDocument
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.INPUTS
        ? _c(
            "div",
            {
              style: "height: ".concat(
                _vm.fullscreen ? "calc(100vh - 200px)" : "500px",
                "; overflow: hidden"
              )
            },
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _vm.signDocument
                    ? _c("report-document-sign-request-stepper-inputs", {
                        ref: _vm.SignRequestStepperPageEnum.INPUTS,
                        attrs: {
                          report: _vm.reportContext,
                          step: _vm.currentStep,
                          loading: _vm.isLoading,
                          valid: _vm.isValid,
                          signDocument: _vm.signDocument,
                          externalId: _vm.daServiceEventId
                        },
                        on: {
                          "update:step": function updateStep($event) {
                            _vm.currentStep = $event
                          },
                          "update:loading": function updateLoading($event) {
                            _vm.isLoading = $event
                          },
                          "update:valid": function updateValid($event) {
                            _vm.isValid = $event
                          },
                          save: _vm.save,
                          setSignRequest: _vm.setSignRequest
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.DA_EXPORT
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-da-export", {
                    ref: _vm.SignRequestStepperPageEnum.DA_EXPORT,
                    attrs: {
                      step: _vm.currentStep,
                      loading: _vm.isLoading,
                      report: _vm.reportContext,
                      valid: _vm.isValid,
                      signRequest: _vm.signRequest
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      setUrl: _vm.setUrl,
                      close: _vm.close
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.SUCCESS
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-success", {
                    ref: _vm.SignRequestStepperPageEnum.SUCCESS,
                    attrs: {
                      step: _vm.currentStep,
                      loading: _vm.isLoading,
                      valid: _vm.isValid,
                      signRequest: _vm.signRequest
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      setUrl: _vm.setUrl,
                      close: _vm.close
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.MAIL
        ? _c(
            "div",
            {
              style: "height: ".concat(
                _vm.fullscreen ? "calc(100vh - 200px)" : "500px",
                "; overflow: hidden;"
              )
            },
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-send-mail", {
                    ref: _vm.SignRequestStepperPageEnum.MAIL,
                    attrs: {
                      step: _vm.currentStep,
                      report: _vm.reportContext,
                      url: _vm.url,
                      loading: _vm.isLoading,
                      valid: _vm.isValid,
                      signRequest: _vm.signRequest
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      close: _vm.close,
                      setUrl: _vm.setUrl
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.currentStep === _vm.SignRequestStepperPageEnum.MAIL_SUCCESS
        ? _c(
            "div",
            [
              _c(
                "v-slide-x-transition",
                {
                  attrs: {
                    "leave-absolute": ""
                  }
                },
                [
                  _c("report-document-sign-request-stepper-mail-success", {
                    ref: _vm.SignRequestStepperPageEnum.MAIL_SUCCESS,
                    attrs: {
                      step: _vm.currentStep,
                      loading: _vm.isLoading,
                      valid: _vm.isValid,
                      signRequest: _vm.signRequest
                    },
                    on: {
                      "update:step": function updateStep($event) {
                        _vm.currentStep = $event
                      },
                      "update:loading": function updateLoading($event) {
                        _vm.isLoading = $event
                      },
                      "update:valid": function updateValid($event) {
                        _vm.isValid = $event
                      },
                      close: _vm.close
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }