import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IThirdPartySystem, ThirdPartySystem } from "@/models/third-party-system.entity";
import { MrfiktivThirdPartySystemControllerGetParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ThirdPartySystemDataAccessLayer } from "./access-layers/third-party-system.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";
import { ThirdPartySystemPageDataProvider } from "./page-data-provider/third-party-system.page-data-provider";
import { ActionEnum } from "../enum/authActionEnum";
import { ResourceEnum } from "../enum/authResourceEnum";
import { UserModule } from "./me-user.store";
import { PartnerModule } from "./partner";
import Vue from "vue";

@Module({
  dynamic: true,
  namespaced: true,
  name: "third-party-system",
  store
})
export class ThirdPartySystemPaginationStore extends PaginatedBaseStore<
  IThirdPartySystem,
  MrfiktivThirdPartySystemControllerGetParamsGen
> {
  protected _data = ThirdPartySystemDataAccessLayer;
  protected _pageProvider = ThirdPartySystemPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = ThirdPartySystem.filterables;

  @Action
  async reset(): Promise<void> {
    super.reset();
    if (UserModule.abilities.can(ActionEnum.READ, ResourceEnum.TPS, PartnerModule.partner.id)) {
      this.setFilters([]);
      await this.fetchFirstPage({ partnerId: PartnerModule.partner.id }).catch(e => Vue.$log.error(e));
    }
  }
}

export const ThirdPartySystemModule = getModule(ThirdPartySystemPaginationStore);
