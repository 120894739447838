












































import PartnerDetailAttributeWithToolTip from "@/components/partner/PartnerDetailAttributeWithToolTip.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IReport } from "@/models/report.entity";
import { MrfiktivCreateActivityLogDtoGen, MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { ProgressStatusEnum } from "@/store/enum/partner/progress.status.enum";
import { ActivityTypeEnum } from "@/lib/enum/activity-type.enum";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import TemplateEditor from "../template/TemplateEditor.vue";
import { handleError } from "@/lib/utility/handleError";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ActivityLog } from "@/models/activity-log.entity";

@Component({
  components: {
    PartnerDetailAttributeWithToolTip,
    ConfirmActionDialog,
    TemplateEditor
  }
})
export default class ReportActivityBoxCommentComponent extends DarkModeHighlightMixin {
  @Prop()
  source?: MrfiktivReferenceGen | ThgReferenceGen;

  @Prop()
  partnerId?: string;

  @Prop({})
  report?: IReport;

  @Prop({})
  thg?: IThg;

  @Prop()
  isEditorMobile?: boolean;

  finished = ProgressStatusEnum.FINISHED;

  isIsFixedLoading = false;

  /**
   * Dialog asking if the damage is fixed
   */
  isIsFixedDialog = false;

  comment = "";
  isLoading = false;

  get partner() {
    return PartnerModule.partner;
  }

  get id() {
    return this.partnerId || this.partner.id || this.report?._id || this.thg?.id;
  }

  async closeAndAddComment() {
    this.isLoading = true;
    await this.addComment();
    try {
      if (!this.report?.isFixed) {
        this.isIsFixedDialog = true;
      }

      await PartnerModule.report.updateProgressStatus(ProgressStatusEnum.FINISHED);
    } catch (error) {
      this.$toast.error("Couldn't update progress status.");
      this.$log.error(error);
    }
    this.isLoading = false;
  }

  async addComment() {
    this.isLoading = true;
    try {
      let refType = this.source?.refType;
      if (!refType) {
        refType = this.report ? ResourceEnum.REPORT : ResourceEnum.THG;
      }
      const refId = this.source?.refId ?? this.report?._id ?? this.thg?.id ?? "";
      const partnerId = this.partnerId ?? this.report?.partnerId ?? this.thg?.partnerId ?? "";

      if (!partnerId) {
        throw new Error("PartnerId not set");
      }

      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType,
          refId
        },
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.COMMENT,
        comment: this.comment
      };

      const activity = await new ActivityLog({ partnerId, ...data }).create();

      this.$emit("save", activity);

      (this.$refs.templateEditor as TemplateEditor).clear();
    } catch (error) {
      this.$toast.error("Could not save comment.");
      this.$log.error(error);
    }
    this.isLoading = false;
  }

  async markFixed() {
    this.isIsFixedLoading = true;
    await this.report?.updateIsFixed(true).catch(handleError);
    this.isIsFixedLoading = false;
    this.isIsFixedDialog = false;
  }
}
