import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import credentialService from "@/services/shared/credentialService";
import { MrfiktivCredentialViewModelGen, MrfiktivPermissionDtoGen } from "@/services/mrfiktiv/v1/data-contracts";
import { CredentialDataAccessLayer } from "@/store/modules/access-layers/credential.access-layer";
import { PartnerUserModule } from "@/store/modules/partner-user.store";
import { ITimestamp, Timestamp } from "./timestamp.entity";

@IsFilterable
class CredentialBase implements IEntity<ICredential>, MrfiktivCredentialViewModelGen {
  /** Id  */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.credential.id"
  })
  id: string;

  /** The partnerId */
  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.credential.partnerId"
  })
  partnerId: string;

  @FilterConfig({
    type: FilterTypes.OBJECT_ID,
    displayName: "objects.credential.userId",
    config: {
      itemCallback: () => PartnerUserModule.paginationList,
      mapItemToComponent: item => ({ item }),
      itemValue: "id",
      component: "refs-user"
    }
  })
  userId = "";

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.credential.name"
  })
  name: string;

  @FilterConfig({
    type: FilterTypes.STRING,
    displayName: "objects.credential.key"
  })
  key: string;

  permissions: MrfiktivPermissionDtoGen[];

  @FilterConfig({
    type: FilterTypes.BOOLEAN,
    displayName: "objects.credential.isActive"
  })
  isActive: boolean;

  /** timestamp */
  @FilterConfig({
    type: Timestamp
  })
  timestamp: ITimestamp;

  loading = false;

  constructor(data: Partial<MrfiktivCredentialViewModelGen>) {
    this.id = data.id ?? "";
    this.partnerId = data.partnerId ?? "";
    this.userId = data.userId ?? "";
    this.name = data.name ?? "";
    this.key = data.key ?? "";
    this.permissions = data.permissions ?? [];
    this.isActive = data.isActive ?? false;
    this.timestamp = new Timestamp(data.timestamp);
  }

  map(data: MrfiktivCredentialViewModelGen): void {
    this.id = data.id;
    this.partnerId = data.partnerId;
    this.userId = data.userId;
    this.name = data.name;
    this.key = data.key;
    this.permissions = data.permissions;
    this.isActive = data.isActive;
    this.timestamp = new Timestamp(data.timestamp);
  }

  async create(): Promise<this> {
    const res = await credentialService.create(this.partnerId, {
      name: this.name,
      permissions: this.permissions.map(p => {
        return { type: p.type, action: p.action };
      }),
      isActive: this.isActive
    });

    this.map(res);

    CredentialDataAccessLayer.set(this);

    return this;
  }

  async fetch(): Promise<this> {
    this.loading = true;
    try {
      const res = await credentialService.getOne(this.partnerId, this.id);
      this.map(res);
      CredentialDataAccessLayer.set(this);
    } catch (e) {
      this.loading = false;
      CredentialDataAccessLayer.delete(this);
      throw e;
    }
    this.loading = false;

    return this;
  }

  async update(): Promise<this> {
    this.loading = true;
    try {
      await credentialService.update(this.partnerId, this.id, {
        name: this.name,
        permissions: this.permissions.map(p => {
          return { type: p.type, action: p.action };
        }),
        isActive: this.isActive
      });
    } catch (e) {
      this.loading = false;
      throw e;
    }
    this.loading = false;

    return this;
  }

  async delete(): Promise<void> {
    const res = await credentialService.delete(this.partnerId, this.id);

    this.map(res);
    CredentialDataAccessLayer.delete(this);
  }
}

type ICredential = CredentialBase;
const Credential = Filter.createForClass(CredentialBase);

export { Credential, ICredential };
