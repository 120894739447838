import { render, staticRenderFns } from "./RefsThirdPartySystem.vue?vue&type=template&id=2e53dfa1&scoped=true&"
import script from "./RefsThirdPartySystem.vue?vue&type=script&lang=ts&"
export * from "./RefsThirdPartySystem.vue?vue&type=script&lang=ts&"
import style0 from "./RefsThirdPartySystem.vue?vue&type=style&index=0&id=2e53dfa1&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e53dfa1",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VChip,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2e53dfa1')) {
      api.createRecord('2e53dfa1', component.options)
    } else {
      api.reload('2e53dfa1', component.options)
    }
    module.hot.accept("./RefsThirdPartySystem.vue?vue&type=template&id=2e53dfa1&scoped=true&", function () {
      api.rerender('2e53dfa1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/utility/RefsThirdPartySystem.vue"
export default component.exports