import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Error from "@/views/Error.vue";
import PartnerPermissions from "@/views/portal/PartnerUnauthorized.vue";

import RepareoReportDatePicker from "@/views/report/repareo/RepareoFormDatePicker.vue";
import RepareoHome from "@/views/report/repareo/RepareoHome.vue";
import RepareoSuccess from "@/views/report/repareo/RepareoSuccess.vue";
import RepareoSummary from "@/views/report/repareo/RepareoSummary.vue";
import ReportAccidentDetails from "@/views/report/ReportAccidentDetails.vue";
import ReportAdditionalImage from "@/views/report/ReportAdditionalImage.vue";
import ReportAdditonalInformationHueffermann from "@/views/report/ReportAdditonalInformationHueffermann.vue";
import ReportContact from "@/views/report/ReportContact.vue";
import ReportDamageNotice from "@/views/report/ReportDamageNotice.vue";
import ReportDataResponse from "@/views/report/ReportDataResponse.vue";
import ReportDataResponseConfirm from "@/views/report/ReportDataResponseConfirm.vue";
import ReportErrorPage from "@/views/report/ReportErrorPage.vue";
import ReportDatePicker from "@/views/report/ReportFormDatePicker.vue";
import ReportDescription from "@/views/report/ReportFormDescription.vue";
import ReportDescriptionRequired from "@/views/report/ReportFormDescriptionRequired.vue";
import ReportPersonalData from "@/views/report/ReportFormPersonalData.vue";
import ReportFormPersonalDataAlt from "@/views/report/ReportFormPersonalDataAlt.vue";
import ReportPersonalDataSimple from "@/views/report/ReportFormPersonalDataSimple.vue";
import ReportCockpitImage from "@/views/report/ReportImageCockpit.vue";
import ReportDamageImage from "@/views/report/ReportImageDamage.vue";
import ReportDamageDetailImage from "@/views/report/ReportImageDamageDetail.vue";
import ReportDamageDetailImageRequired from "@/views/report/ReportImageDamageDetailRequired.vue";
import ReportImageDamageOptional from "@/views/report/ReportImageDamageOptional.vue";
import ReportImageDriversLicense from "@/views/report/ReportImageDriversLicense.vue";
import ReportOverviewImage from "@/views/report/ReportImageOverview.vue";
import ReportImageOverviewAlt from "@/views/report/ReportImageOverviewAlt.vue";
import ReportImageOverviewAuth from "@/views/report/ReportImageOverviewAuth.vue";
import ReportRegistrationImage from "@/views/report/ReportImageRegistration.vue";
import ReportImageTire from "@/views/report/ReportImageTire.vue";
import ReportImageTuev from "@/views/report/ReportImageTuev.vue";
import ReportImageVin from "@/views/report/ReportImageVin.vue";
import ReportImageWheel from "@/views/report/ReportImageWheel.vue";
import ReportImageWheelDetail from "@/views/report/ReportImageWheelDetail.vue";
import ReportInsurance from "@/views/report/ReportInsurance.vue";
import ReportLeasing from "@/views/report/ReportLeasing.vue";
import ReportLiabilityDamage from "@/views/report/ReportLiabilityDamage.vue";
import ReportLocation from "@/views/report/ReportLocation.vue";
import ReportLocationCombined from "@/views/report/ReportLocationCombined.vue";
import ReportLocationTruck from "@/views/report/ReportLocationTruck.vue";
import ReportMotoristLegalInsurance from "@/views/report/ReportMotoristLegalInsurance.vue";
import ReportNumberplate from "@/views/report/ReportNumberplate.vue";
import ReportPolice from "@/views/report/ReportPolice.vue";
import ReportPreExistingDamage from "@/views/report/ReportPreExistingDamage.vue";
import ReportSuccess from "@/views/report/ReportSuccess.vue";
import ReportSummary from "@/views/report/ReportSummary.vue";
import ReportSummaryAlt from "@/views/report/ReportSummaryAlt.vue";
import ReportSummaryAltAuth from "@/views/report/ReportSummaryAltAuth.vue";
import ReportSummaryAuth from "@/views/report/ReportSummaryAuth.vue";
import ReportSummarySimple from "@/views/report/ReportSummarySimple.vue";
import ReportTypeSelector from "@/views/report/ReportTypeSelector.vue";
import ReportWheelInsurance from "@/views/report/ReportWheelInsurance.vue";
import ReportWitness from "@/views/report/ReportWitness.vue";
import ReportWitnessWithNumberplate from "@/views/report/ReportWitnessWithNumberplate.vue";
import ReportControlClosing from "@/views/ReportControlClosing.vue";
import ReportHome from "@/views/ReportHome.vue";
import ReportPartnerFinder from "@/views/ReportPartnerFinder.vue";

import NoRoles from "@/views/NoRoles.vue";
import Login from "@/views/report/Login.vue";
import SignUp from "@/views/report/SignUp.vue";
import UserProfile from "@/views/UserProfile.vue";

import MyBookingView from "@/views/report/user/MyBookingView.vue";
import MyDamageView from "@/views/report/user/MyDamageView.vue";
import MySignatureView from "@/views/report/user/MySignatureView.vue";

import FrequentlyAskedQuestions from "@/views/report/FrequentlyAskedQuestions.vue";

import Sign from "@/views/report/Sign.vue";

import { authGuard } from "@/auth/authGuard";
import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";

import { BaseRoutes } from "./base.router";
import { BookingRoutes } from "./bookingApp.router";
import { SettingRoutes } from "./settings.router";

import SharedContentView from "@/views/report/SharedContentView.vue";
import { getLoginRoutes } from "./login.router";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    path: "/" + ReportScreenEnum.welcome,
    component: ReportHome,
    name: "ReportHome"
  },
  {
    path: "/" + ReportScreenEnum.welcomelogin,
    component: ReportHome,
    name: "ReportHomeLogin",
    beforeEnter: authGuard
  },
  {
    path: "/s/:token",
    component: SharedContentView,
    name: "ReportSharedContentView"
  },
  {
    name: "ReportDataResponse",
    path: "/r/:accessToken",
    component: ReportDataResponse
  },
  {
    path: "/request/:attachmentId/:accessToken",
    component: ReportDataResponse,
    name: "ReportDataResponseAttachmentId"
  },
  {
    path: "/attach",
    component: ReportDataResponseConfirm,
    name: "ReportDataResponseConfirm"
  },
  {
    path: "/" + ReportScreenEnum.registration,
    component: ReportRegistrationImage,
    name: "ReportRegistrationImage"
  },
  {
    path: "/" + ReportScreenEnum.damagelocator,
    component: ReportLocation,
    name: "ReportLocation"
  },
  {
    path: "/" + ReportScreenEnum.damagelocatortruck,
    component: ReportLocationTruck,
    name: "ReportLocationTruck"
  },
  {
    path: "/" + ReportScreenEnum.damagelocatorcombined,
    component: ReportLocationCombined,
    name: "ReportLocationCombined"
  },

  {
    path: "/" + ReportScreenEnum.overviewimage,
    component: ReportOverviewImage,
    name: "ReportOverviewImage"
  },
  {
    path: "/" + ReportScreenEnum.overviewimagealt,
    component: ReportImageOverviewAlt,
    name: "ReportImageOverviewAlt"
  },
  {
    path: "/" + ReportScreenEnum.overviewimageauth,
    component: ReportImageOverviewAuth,
    beforeEnter: authGuard,
    name: "ReportImageOverviewAuth"
  },
  {
    path: "/" + ReportScreenEnum.componentimage,
    component: ReportDamageImage,
    name: "ReportDamageImage"
  },
  {
    path: "/" + ReportScreenEnum.damagedetail,
    component: ReportDamageDetailImage,
    name: "ReportDamageDetailImage"
  },

  {
    path: "/" + ReportScreenEnum.damagedetailrequired,
    component: ReportDamageDetailImageRequired,
    name: "ReportDamageDetailImageRequired"
  },
  {
    path: "/" + ReportScreenEnum.damageimageoptional,
    component: ReportImageDamageOptional,
    name: "ReportImageDamageOptional"
  },
  {
    path: "/" + ReportScreenEnum.mileage,
    component: ReportCockpitImage,
    name: "ReportCockpitImage"
  },
  {
    path: "/" + ReportScreenEnum.message,
    component: ReportDescription,
    name: "ReportDescription"
  },
  {
    path: "/" + ReportScreenEnum.messagerequired,
    component: ReportDescriptionRequired,
    name: "ReportDescriptionRequired"
  },
  {
    path: "/" + ReportScreenEnum.contactdetails,
    component: ReportPersonalData,
    name: "ReportPersonalData"
  },
  {
    path: "/" + ReportScreenEnum.contactalt,
    component: ReportFormPersonalDataAlt,
    name: "ReportFormPersonalDataAlt"
  },
  {
    path: "/" + ReportScreenEnum.simplecontact,
    component: ReportPersonalDataSimple,
    name: "ReportPersonalDataSimple"
  },
  {
    path: "/" + ReportScreenEnum.daterequest,
    component: ReportDatePicker,
    name: "ReportDatePicker"
  },
  {
    path: "/" + ReportScreenEnum.closing,
    component: ReportSummary,
    name: "ReportSummary"
  },
  {
    path: "/" + ReportScreenEnum.closingalt,
    component: ReportSummaryAlt,
    name: "ReportSummaryAlt"
  },
  {
    path: "/" + ReportScreenEnum.closingaltauth,
    component: ReportSummaryAltAuth,
    beforeEnter: authGuard,
    name: "ReportSummaryAltAuth"
  },
  {
    path: "/" + ReportScreenEnum.closingauth,
    component: ReportSummaryAuth,
    beforeEnter: authGuard,
    name: "ReportSummaryAuth"
  },
  {
    path: "/" + ReportScreenEnum.closingsimple,
    component: ReportSummarySimple,
    name: "ReportSummarySimple"
  },
  {
    path: "/" + ReportScreenEnum.reportType,
    component: ReportTypeSelector,
    name: "ReportType"
  },
  {
    path: "/" + ReportScreenEnum.vinimage,
    component: ReportImageVin,
    name: "ReportImageVin"
  },
  {
    path: "/" + ReportScreenEnum.tuevimage,
    component: ReportImageTuev,
    name: "ReportImageTuev"
  },
  {
    path: "/" + ReportScreenEnum.tireimage,
    component: ReportImageTire,
    name: "ReportImageTire"
  },
  {
    path: "/" + ReportScreenEnum.wheelImage,
    component: ReportImageWheel,
    name: "ReportImageWheel"
  },
  {
    path: "/" + ReportScreenEnum.wheelDetailImage,
    component: ReportImageWheelDetail,
    name: "ReportImageWheelDetail"
  },
  {
    path: "/" + ReportScreenEnum.wheelInsurance,
    component: ReportWheelInsurance,
    name: "ReportWheelInsurance"
  },
  {
    path: "/" + ReportScreenEnum.witness,
    component: ReportWitness,
    name: "ReportWitness"
  },
  {
    path: "/" + ReportScreenEnum.witnessNumberplate,
    component: ReportWitnessWithNumberplate,
    name: "ReportWitnessWithNumberplate"
  },
  {
    path: "/" + ReportScreenEnum.police,
    component: ReportPolice,
    name: "ReportPolice"
  },
  {
    path: "/" + ReportScreenEnum.driverslicense,
    component: ReportImageDriversLicense,
    name: "ReportImageDriversLicense"
  },
  {
    path: "/" + ReportScreenEnum.damagenotice,
    component: ReportDamageNotice,
    name: "ReportDamageNotice"
  },
  {
    path: "/" + ReportScreenEnum.liabilityselector,
    component: ReportLiabilityDamage,
    name: "ReportLiabilityDamage"
  },
  {
    path: "/" + ReportScreenEnum.accidentdetails,
    component: ReportAccidentDetails,
    name: "ReportAccidentDetails"
  },
  {
    path: "/" + ReportScreenEnum.externalidhueffermann,
    component: ReportAdditonalInformationHueffermann,
    name: "ReportAdditonalInformationHueffermann"
  },
  {
    path: "/" + ReportScreenEnum.additionalimage,
    component: ReportAdditionalImage,
    name: "ReportAdditionalImage"
  },
  {
    path: "/" + ReportScreenEnum.numberplate,
    component: ReportNumberplate,
    name: "ReportNumberplate"
  },
  {
    path: "/" + ReportScreenEnum.leasing,
    component: ReportLeasing,
    name: "ReportLeasing"
  },
  {
    path: "/" + ReportScreenEnum.preexistingdamage,
    component: ReportPreExistingDamage,
    name: "ReportPreExistingDamage"
  },
  {
    path: "/" + ReportScreenEnum.insurance,
    component: ReportInsurance,
    name: "ReportInsurance"
  },
  {
    path: "/" + ReportScreenEnum.motoristLegalInsurance,
    component: ReportMotoristLegalInsurance,
    name: "ReportMotoristLegalInsurance"
  },
  {
    path: "/" + ReportScreenEnum.CONTACT,
    component: ReportContact,
    name: "ReportContact"
  },
  {
    path: "/success",
    component: ReportSuccess,
    name: "ReportSuccess"
  },

  /**
   * Reparo Screens
   */
  {
    path: "/" + ReportScreenEnum.REPAREO_HOME,
    component: RepareoHome
  },
  {
    path: "/" + ReportScreenEnum.REPAREO_DATE_REQUEST,
    component: RepareoReportDatePicker
  },
  {
    path: "/" + ReportScreenEnum.REPAREO_CLOSING,
    component: RepareoSummary
  },
  {
    path: "/repareosuccess",
    component: RepareoSuccess,
    name: "RepareoSuccess"
  },
  /**
   * BVDP
   */
  {
    path: "/" + ReportScreenEnum.PARTNER_FINDER,
    component: ReportPartnerFinder,
    name: "ReportPartnerFinder"
  },
  {
    path: "/" + ReportScreenEnum.REPORT_CONTROL_CLOSING,
    component: ReportControlClosing,
    name: "ReportControlClosing"
  },
  {
    path: "/my/damages",
    name: "MyDamageView",
    component: MyDamageView
  },
  {
    path: "/my/bookings",
    name: "MyBookingView",
    component: MyBookingView
  },
  {
    path: "/my/signatures",
    name: "MySignatureView",
    component: MySignatureView
  }
];

const appRoutes: Array<RouteConfig> = [
  {
    path: "/error",
    name: "PartnerContactError",
    component: ReportErrorPage
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: PartnerPermissions
  },
  {
    path: "/no-roles",
    name: "NoRoles",
    component: NoRoles
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  },
  {
    path: "/sign/:signRequestId",
    name: "DigitalSignature",
    component: Sign
  },
  {
    path: "/faq",
    name: "FrequentlyAskedQuestions",
    component: FrequentlyAskedQuestions
  },
  {
    path: "*",
    component: ReportHome
  }
];

SettingRoutes.forEach((route: RouteConfig) => {
  routes.push(route);
});

getLoginRoutes(Login).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

routes.push(
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp
  },
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    beforeEnter: authGuard
  }
);

const router = new VueRouter({
  routes: [...routes, ...BookingRoutes, ...BaseRoutes, ...appRoutes],
  base: "/report/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(permissionGuard);

export default router;
