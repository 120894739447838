































import CostCreateDialog from "@/components/cost/CostCreateDialog.vue";
import RefsCost from "@/components/utility/RefsCost.vue";
import SideCard from "@/components/utility/SideCard.vue";
import { detailedDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { ISignDocument } from "@/models/sign-document.entity";
import { CostModule } from "@/store/modules/cost.store";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";
import DocumentDetailMixin from "./DocumentDetailMixin.vue";
import { CostGoToHelper } from "@/lib/utility/cost.go-to-helper";
import { ICost } from "@/models/cost.entity";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";

@Component({
  components: {
    SideCard,
    CostCreateDialog,
    RefsCost
  },
  filters: {
    detailedDate
  }
})
export default class DocumentTemplateDetailSignature extends mixins(DocumentDetailMixin) {
  @Prop()
  document!: ISignDocument;

  isCostDialogActive = false;

  isCostsLoading = false;

  get costs() {
    return CostModule.filtered;
  }

  async mounted() {
    this.isCostsLoading = true;
    CostModule.setFilters([
      new PageFilterElement({
        key: "files",
        operation: PageFilterOperationEnum.EQUAL,
        value: this.document.id
      })
    ]);

    await CostModule.fetchAll({
      partnerId: this.document.partnerId
    }).catch(handleError);
    this.isCostsLoading = false;
  }

  openCostDialog() {
    const costDialog = this.$refs.costDialog as CostCreateDialog;
    costDialog.show();
    costDialog.cost.files?.push(this.document.id);
    costDialog.onCreatedDocument(this.document);
  }

  goToCostDetail(cost: ICost) {
    new CostGoToHelper(this.$router).goToCostDetail({
      partnerId: cost.partnerId,
      costId: cost.id,
      newTab: true
    });
  }
}
