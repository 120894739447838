var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        fullscreen: _vm.isMobile,
        height: _vm.sideCardHeight,
        width: _vm.sideCardWidth,
        title: _vm.$t("sign.DocumentDetail.link"),
        loading: _vm.loading
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("v-subheader", [
        _vm._v(_vm._s(_vm.$t("sign.DocumentDetail.internal")))
      ]),
      _c("refs-select", {
        attrs: {
          hideSuggestions: true,
          isConfirmable: false,
          partnerId: _vm.signatureRequest.partnerId,
          loading: _vm.isLoadingRefs
        },
        on: {
          change: _vm.debounceSaveRefs
        },
        model: {
          value: _vm.signatureRequest.refs,
          callback: function callback($$v) {
            _vm.$set(_vm.signatureRequest, "refs", $$v)
          },
          expression: "signatureRequest.refs"
        }
      }),
      _vm.isTpsActive
        ? _c(
            "div",
            [
              _c("v-subheader", [
                _vm._v(_vm._s(_vm.$t("sign.DocumentDetail.external")))
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex"
                  }
                },
                [
                  _c("v-spacer"),
                  _vm.isDaSetup || _vm.isKsrSetup
                    ? _c("v-btn", {
                        attrs: {
                          "x-small": "",
                          text: "",
                          elevation: 0
                        },
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t(
                              "sign.DocumentTemplateDetailEdit.exportToExternal"
                            )
                          )
                        },
                        on: {
                          click: _vm.exportToExternal
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.isLoadingThirdPartySystem
                ? _c("v-progress-linear", {
                    attrs: {
                      indeterminate: "",
                      color: "primary"
                    }
                  })
                : _c(
                    "div",
                    _vm._l(_vm.tpss, function(tps) {
                      return _c(
                        "v-card",
                        {
                          key: "tps" + tps.id,
                          staticClass: "my-1",
                          attrs: {
                            outlined: ""
                          }
                        },
                        [
                          _c(
                            "v-list-item",
                            [
                              _c("refs-third-party-system", {
                                attrs: {
                                  item: tps
                                },
                                on: {
                                  openDetail: function openDetail($event) {
                                    return _vm.openTpsSideCard(tps)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  ),
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    isDialogActive: _vm.isExportDialogActive,
                    title: _vm.$t(
                      "sign.DocumentTemplateDetailEdit.exportToExternal"
                    ),
                    leftDisabled: false,
                    rightDisabled: false,
                    leftText: _vm.$t("back"),
                    loading:
                      _vm.isSelectedKsrJobLoading || _vm.isDaSelectionLoading,
                    hideLeft: _vm.externalStepper === _vm.StepEnum.SELECT,
                    hideRight: true
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isExportDialogActive = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isExportDialogActive = $event
                    },
                    leftClick: function leftClick($event) {
                      _vm.externalStepper = _vm.StepEnum.SELECT
                    },
                    rightClick: _vm.rightClick
                  }
                },
                [
                  _c(
                    "v-window",
                    {
                      attrs: {
                        touchless: ""
                      },
                      model: {
                        value: _vm.externalStepper,
                        callback: function callback($$v) {
                          _vm.externalStepper = $$v
                        },
                        expression: "externalStepper"
                      }
                    },
                    [
                      _c(
                        "v-window-item",
                        {
                          attrs: {
                            value: _vm.StepEnum.SELECT
                          }
                        },
                        [
                          _vm.isDaSetup
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function click($event) {
                                      return _vm.select(_vm.StepEnum.DA)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("DA")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isKsrSetup
                            ? _c(
                                "v-list-item",
                                {
                                  on: {
                                    click: function click($event) {
                                      return _vm.select(_vm.StepEnum.KSR)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [_c("v-list-item-title", [_vm._v("KSR")])],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isDaSetup
                        ? _c(
                            "v-window-item",
                            {
                              attrs: {
                                value: _vm.StepEnum.DA
                              }
                            },
                            [
                              _c(
                                "partner-report-detail-send-to-da-dialog-stepper",
                                {
                                  ref: "daStepper",
                                  attrs: {
                                    disableCreation: true,
                                    onlySelectDossier: true
                                  },
                                  on: {
                                    selectDossier: _vm.selectDossier
                                  },
                                  model: {
                                    value: _vm.daDialogStep,
                                    callback: function callback($$v) {
                                      _vm.daDialogStep = $$v
                                    },
                                    expression: "daDialogStep"
                                  }
                                }
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isKsrSetup
                        ? _c(
                            "v-window-item",
                            {
                              attrs: {
                                value: _vm.StepEnum.KSR
                              }
                            },
                            [
                              _c(
                                "partner-report-detail-send-to-ksr-dialog-existing",
                                {
                                  attrs: {
                                    initialAmount: 3
                                  },
                                  on: {
                                    submit: _vm.selectKsrJob
                                  }
                                }
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.selectedTps
                ? _c("third-party-system-side-card", {
                    on: {
                      close: function close($event) {
                        _vm.selectedTps = null
                      }
                    },
                    model: {
                      value: _vm.selectedTps,
                      callback: function callback($$v) {
                        _vm.selectedTps = $$v
                      },
                      expression: "selectedTps"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }