


























import { EditorContent } from "@tiptap/vue-2";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import TemplateEditorActions from "./TemplateEditorActions.vue";
import TemplateEditorAddPlaceholder from "./TemplateEditorAddPlaceholder.vue";

@Component({
  components: { EditorContent, Card, TemplateEditorAddPlaceholder, TemplateEditorActions }
})
export default class TemplateEditorSubject extends Vue {
  @Prop()
  partnerId?: string;

  @Prop()
  subject!: string;

  @Prop({ default: false })
  addPlaceholderInSubject?: boolean;

  @Prop({ default: false })
  readonly?: boolean;

  get subjectLocal() {
    return this.subject;
  }

  set subjectLocal(s: string) {
    this.$emit("update:subject", s);
  }

  get subjectLength() {
    return this.subjectLocal?.length || 0;
  }

  get subjectCounterStyle() {
    const color = this.subjectLocal?.length || 0 > 60 ? "orange" : "green";

    return `color: ${color}`;
  }

  addPlaceholderToSubject(placeholder: string) {
    this.subjectLocal += placeholder;
  }
}
