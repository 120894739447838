var render = function() {
  var _vm$attachmentRespons,
    _vm$attachmentRespons2,
    _vm$attachmentRespons3,
    _vm$attachmentRespons4,
    _vm$attachmentRespons5,
    _vm$attachmentRespons6,
    _vm$attachmentRespons7,
    _vm$attachmentRespons8,
    _vm$attachmentRespons9,
    _vm$attachmentRespons10,
    _vm$attachmentRespons11,
    _vm$attachmentRespons12,
    _vm$attachmentRespons13

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report-step",
    {
      attrs: {
        title: _vm.header.title,
        description: _vm.header.description,
        image: _vm.header.image,
        debug: _vm.header.debug,
        hasInstructions: false,
        showFooter: false
      }
    },
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass: "mt-6 mb-8"
            },
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              }),
              _c("v-skeleton-loader", {
                staticClass: "mt-3",
                attrs: {
                  type: "list-item-three-line"
                }
              })
            ],
            1
          )
        : _vm.isExpired
        ? _c(
            "div",
            [
              _c("v-img", {
                staticClass: "ma-2",
                attrs: {
                  contain: "",
                  src: require("@/assets/undraw_time_management_re_tk5w.svg"),
                  height: "240",
                  justify: "center"
                }
              }),
              _c(
                "v-row",
                {
                  staticClass: "ma-2"
                },
                [
                  _c("v-spacer"),
                  _c("v-card-title", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "timeLine.ActivityTimeLineItemDocument.expired"
                          )
                        ) +
                        " "
                    )
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        : _vm.isFailed
        ? _c(
            "div",
            [
              _c("v-img", {
                staticClass: "ma-2",
                attrs: {
                  contain: "",
                  src: require("@/assets/undraw/undraw_warning.svg"),
                  height: "240",
                  justify: "center"
                }
              }),
              _c(
                "v-row",
                {
                  staticClass: "ma-2"
                },
                [
                  _c("v-spacer"),
                  _c("v-card-title", [
                    _vm._v(" " + _vm._s(_vm.$t("loadingError")) + " ")
                  ]),
                  _c("v-spacer")
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c("div", [
                _vm._v(" " + _vm._s(_vm.$t("report.request.questions")) + " ")
              ]),
              _c(
                "div",
                {
                  staticClass: "mt-6 mb-8"
                },
                [
                  _c("b", [
                    _vm._v(
                      _vm._s(
                        (_vm$attachmentRespons = _vm.attachmentResponse) ===
                          null || _vm$attachmentRespons === void 0
                          ? void 0
                          : _vm$attachmentRespons.partner.companyName
                      )
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm$attachmentRespons2 = _vm.attachmentResponse) ===
                            null || _vm$attachmentRespons2 === void 0
                            ? void 0
                            : (_vm$attachmentRespons3 =
                                _vm$attachmentRespons2.partner.address) ===
                                null || _vm$attachmentRespons3 === void 0
                            ? void 0
                            : _vm$attachmentRespons3.street
                        ) +
                        ", " +
                        _vm._s(
                          (_vm$attachmentRespons4 = _vm.attachmentResponse) ===
                            null || _vm$attachmentRespons4 === void 0
                            ? void 0
                            : (_vm$attachmentRespons5 =
                                _vm$attachmentRespons4.partner.address) ===
                                null || _vm$attachmentRespons5 === void 0
                            ? void 0
                            : _vm$attachmentRespons5.zip
                        ) +
                        " " +
                        _vm._s(
                          (_vm$attachmentRespons6 = _vm.attachmentResponse) ===
                            null || _vm$attachmentRespons6 === void 0
                            ? void 0
                            : (_vm$attachmentRespons7 =
                                _vm$attachmentRespons6.partner.address) ===
                                null || _vm$attachmentRespons7 === void 0
                            ? void 0
                            : _vm$attachmentRespons7.city
                        ) +
                        ", " +
                        _vm._s(
                          (_vm$attachmentRespons8 = _vm.attachmentResponse) ===
                            null || _vm$attachmentRespons8 === void 0
                            ? void 0
                            : (_vm$attachmentRespons9 =
                                _vm$attachmentRespons8.partner.address) ===
                                null || _vm$attachmentRespons9 === void 0
                            ? void 0
                            : _vm$attachmentRespons9.state
                        ) +
                        " "
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1"
                          },
                          [_vm._v("mdi-email-outline")]
                        ),
                        _vm._v(
                          _vm._s(
                            (_vm$attachmentRespons10 =
                              _vm.attachmentResponse) === null ||
                              _vm$attachmentRespons10 === void 0
                              ? void 0
                              : (_vm$attachmentRespons11 =
                                  _vm$attachmentRespons10.partner.contact) ===
                                  null || _vm$attachmentRespons11 === void 0
                              ? void 0
                              : _vm$attachmentRespons11.email
                          )
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mr-1"
                          },
                          [_vm._v("mdi-phone-outline")]
                        ),
                        _vm._v(
                          _vm._s(
                            (_vm$attachmentRespons12 =
                              _vm.attachmentResponse) === null ||
                              _vm$attachmentRespons12 === void 0
                              ? void 0
                              : (_vm$attachmentRespons13 =
                                  _vm$attachmentRespons12.partner.contact) ===
                                  null || _vm$attachmentRespons13 === void 0
                              ? void 0
                              : _vm$attachmentRespons13.phone
                          )
                        )
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.loading,
                    block: "",
                    "x-large": "",
                    color: "primary"
                  },
                  on: {
                    click: _vm.start
                  }
                },
                [_vm._v("START")]
              )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }