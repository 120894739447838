var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _vm.isLoading
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
        : _c(
            "v-container",
            [
              _c("m-header", {
                attrs: {
                  title: _vm.$t("message.inbox.inbox"),
                  breadCrumbs: _vm.breadCrumbs,
                  chips: _vm.chips
                }
              }),
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function click($event) {
                          return _vm.goToInboxList("all")
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("message.inbox.allMessages")))
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("message.inbox.allMessagesDescription")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c("v-list-item-action", [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex"
                          },
                          [
                            _vm.AllMessagesInbox.filtered.filter(function(f) {
                              return !f.isRead
                            }).length
                              ? _c("span", [
                                  _c("b", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.AllMessagesInbox.filtered.filter(
                                            function(f) {
                                              return !f.isRead
                                            }
                                          ).length
                                        )
                                    )
                                  ]),
                                  _vm._v(" / ")
                                ])
                              : _vm._e(),
                            _vm._v(
                              " " +
                                _vm._s(_vm.AllMessagesInbox.filtered.length) +
                                " "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.inboxes.length ? _c("v-divider") : _vm._e()
                ],
                1
              ),
              _vm._l(_vm.inboxes, function(inbox, index) {
                return _c(
                  "v-list",
                  {
                    key: inbox.identifier
                  },
                  [
                    _c(
                      "v-list-item",
                      {
                        on: {
                          click: function click($event) {
                            return _vm.goToInboxList(inbox.identifier)
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(inbox.name))
                            ]),
                            _c("v-list-item-subtitle", [
                              _vm._v(_vm._s(inbox.identifier))
                            ])
                          ],
                          1
                        ),
                        _c("v-list-item-action", [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex"
                            },
                            [
                              inbox.module.filtered.filter(function(f) {
                                return !f.isRead
                              }).length
                                ? _c("span", [
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            inbox.module.filtered.filter(
                                              function(f) {
                                                return !f.isRead
                                              }
                                            ).length
                                          )
                                      )
                                    ]),
                                    _vm._v(" / ")
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(inbox.module.filtered.length) + " "
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    index !== _vm.inboxes.length - 1
                      ? _c("v-divider")
                      : _vm._e()
                  ],
                  1
                )
              })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }