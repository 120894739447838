






















































import { IForm } from "@/lib/interfaces/form.interface";
import { Component } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import PartnerReportCardGeneral from "./PartnerReportCardGeneral.vue";

@Component({
  components: {
    Card
  }
})
export default class PartnerReportCardLeasing extends PartnerReportCardGeneral {
  showRemove = false;

  add() {
    if (!this.reportDto.leasing) {
      this.reportDto.leasing = { isLeasing: false, claimNumber: "", company: "" };
    }
    this.showRemove = true;
    this.key++;

    this.$nextTick(() => {
      (this.$refs.form as IForm)?.validate();
    });
  }

  remove() {
    this.reportDto.leasing = null;
    this.showRemove = false;
    this.key++;
  }

  mounted() {
    this.showRemove = !!this.reportDto.leasing;
  }
}
